import { Model } from "./../../classes/mvc/Model";
import { Json } from "../../libs/Json";
import { modules } from "../../main";
import jQuery = require( "jquery" );
import { ControllerSearch } from "./ControllerSearch";

export class ModelSearch extends Model{

	/**
	*
	* Get the price sync data via ajax request. This method will return the result, when the response has been returned.
	*/
	public getArticleSyncData ( article_numbers:Array<number> ){

		var result:Object = null; // Result JSON response.
		
		var url = window.location.origin + "/" + modules.getKontaktID() + "/" + modules.getKontaktKey() + "/4DCGI/ezshop?action=JSON_getArtPrices&artnrs=[";
			for (var i = 0; i < article_numbers.length; i++) {
				url += "{" + "%22nr%22:%22"+ encodeURIComponent( String( article_numbers[i] ) ) +"%22}";
				if ( article_numbers[ i + 1 ] != undefined ) {
					url += ",";
				}
			}
		url += "]";

		jQuery.ajax({
			url: url,
			type: "GET",
			async: false,
			dataType: "json",
			success: function ( response:Object ){
				result = response;
			}.bind(this)
		});

		 return result;
	}

	/**
	* 
	* Get Search Url
	*/
	public getSearchURL ( searchURL:string, tab:string, type:string, orderby:number, pagetype:number, loadFilters:boolean, filters:string, page:number, query:string ): string {
		/**
		*
		* Build the first url part
		* Used in all cases
		*/

		if (orderby===0) // Ensure Default Orderby=1
			orderby=1;

		var url = searchURL + "&tab=" + tab + "&type=" + type + "&language=" + modules.getLanguageCode() + "&orderby=" + orderby + "&pagetype=" + pagetype + filters;

		/**
		*
		* Add current page
		*/
		if ( page !== null ) {
			url += "&page=" + page;
		}

		/**
		*
		* Add nofilter action
		*/
		if ( !loadFilters ) {
			url += "&action=nofilter";
		}

		/**
		*
		* Add the all action to display all procuts if the query is empty
		*/
		if ( query == "" ) {
			url += "&query=&all=true";
		} else {
			url += "&query=" + encodeURIComponent( query );
		}

		url+= "&orderby_rank=" + ControllerSearch.CURRENT_ORDERBY_RANK;

		return url;
	}

	/**
	*
	* Get search results via ajax request
	*/
	public getSearchResults ( searchURL:string, tab:string, type:string, orderby:number, pagetype:number, loadFilters:boolean, filters:string, page:number, query:string ){
		var result:Object = null;

		/**
		*
		* Build the first url part
		* Used in all cases
		*/
		var url = searchURL + "&tab=" + tab + "&type=" + type + "&language=" + modules.getLanguageCode() + "&orderby=" + orderby + "&pagetype=" + pagetype + filters;

		/**
		*
		* Add current page
		*/
		if ( page !== null ) {
			url += "&page=" + page;
		}

		/**
		*
		* Add nofilter action
		*/
		if ( !loadFilters ) {
			url += "&action=nofilter";
		}

		/**
		*
		* Add the all action to display all procuts if the query is empty
		*/
		if ( query == "" ) {
			url += "&query=&all=true";
		} else {
			url += "&query=" + encodeURIComponent( query );
		}

		if ( ControllerSearch.CATEGORY_LINK_MODE == 1 ) {
			url += "&cat_op=1";
		} else {
			url += "&cat_op=0";
		}

		url += "&orderby_rank=" + ControllerSearch.CURRENT_ORDERBY_RANK;

		/**
		*
		* Execute the ajax request
		*/
		jQuery.ajax({
			url: url,
			type: "GET",
			async: false,
			dataType: "json",
			success: function ( response:Object ){

				result = response;

			}.bind(this)
		});

		return result;
    }
    
    	/**
	*
	* Get the tabs from the json response
	*/
	public getTabs ( search_result:Object ):Array<Object>{
		return Json.convertObjectToArray( Json.getSubobject( search_result, "searchresult.header.tabs.tab" ) );
	}

	/**
	*
	* Get the recommendations from the json response
	*/
	public getRecommendations ( search_result:Object ):Array<Object>{
		return Json.convertObjectToArray( Json.getSubobject( search_result, "searchresult.header.recommendation.entry" ) );
	}

	/**
	 * 
	 * Get the query word from the json response
	 */
	public getQuery ( search_result:Object ):string{
		return Json.getSubobject( search_result, "searchresult.header.query" );
	}

	/**
	 * 
	 * Get the translateword from the json response
	 */
	public getTranslateWord( search_result:Object ):string {
		return Json.getSubobject( search_result, "searchresult.header.translateword" );
	}

	/**
	* 
	* Get the categorymatch from the json response
	*/
	public getCategoryMatch( search_result:Object ):string {
		return Json.getSubobject( search_result, "searchresult.results.domain.categorymatch");
	}
	/**
	*
	* Get the results from the json response
	*/
	public getResults ( tab:string, search_result:Object ):Array<Object>{
		switch ( tab ){
			case "shop":
				return Json.convertObjectToArray( Json.getSubobject( search_result, "searchresult.results.domain.resultlist.item" ) );
			case "information":
				return Json.convertObjectToArray( Json.getSubobject( search_result, "searchresult.resultlist.item" ) );
		}
	}

	/**
	*
	* Get the filters from the json response
	*/
	public getFilters ( tab:string, search_result:Object ):Array<Object>{
		switch ( tab ){
			case "shop":
				var price_filter = Json.getSubobject( search_result, "searchresult.results.domain.filters.price" );
				var filters = Json.convertObjectToArray( Json.getSubobject( search_result, "searchresult.results.domain.filters.filter" ) );
			break;
			case "information":
				var price_filter =  Json.getSubobject( search_result, "searchresult.header.filters.price" );
				var filters =  Json.convertObjectToArray( Json.getSubobject( search_result, "searchresult.header.filters.filter" ) );
			break;
		}

		/**
		*
		* Add the price filter to the filters array
		*/
		if ( price_filter != null && filters != null ) {
			filters.push( {
				name: "price",
				value: price_filter
			});
		}

		return filters;
	}

	/**
	*
	* Get the page from the json response
	*/
	public getPage ( tab:string, search_result:Object ):number{
		switch ( tab ){
			case "shop":
				return Json.getSubobject( search_result, "searchresult.results.domain.page" );
			case "information":
				return Json.getSubobject( search_result, "searchresult.header.page" );
		}
	}

	/**
	*
	* Get the amount of pages from the json response
	*/
	public getAmountPages ( tab:string, search_result:Object ):number{
		switch ( tab ){
			case "shop":
				return Json.getSubobject( search_result, "searchresult.results.domain.pages" );
			case "information":
				return Json.getSubobject( search_result, "searchresult.header.pages" );
		}
	}

	/**
	*
	* Get the amount of pages from the json response
	*/
	public getDoyoumean ( tab:string, search_result:Object ):Array<Object>{
		switch ( tab ){
			case "shop":
				return Json.getSubobject( search_result, "searchresult.header.doyoumean.domain.0.keyword" );
			case "information":
				return Json.getSubobject( search_result, "searchresult.header.doyoumean.domain.0.keyword" );
		}
	}

	/**
	*
	* Get the tab hits from the json response
	*/
	public getCurrentTabHits ( tab:string, search_result:Object ):number{
		switch ( tab ){
			case "shop":
				return Json.getSubobject( search_result, "searchresult.results.domain.hits" );
			case "information":
				return Json.getSubobject( search_result, "searchresult.header.hits" );
		}
	}

}