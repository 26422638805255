import { Filter } from "./Filter";
import { findIcon } from "../../../libs/Icons";
import { ControllerSearch } from "../ControllerSearch";
import jQuery = require( "jquery" );
import { Globals } from "../../../classes/Globals";
import { CategoryFilter } from "./CategoryFilter";
import { TagFilter } from "./TagFilter";
import { BrandFilter } from "./BrandFilter";
import { NodeCategoryFilter } from "./NodeCategoryFilter";

export class SelectedCategories extends Filter {

	private static HEADLINE:string = "";

	private selected_Categories:Array<string>;

	public constructor ( controller:ControllerSearch ){
		super( controller, "CategoryList" );

		this.setHeadline( SelectedCategories.HEADLINE );
		this.setIcon( findIcon( "filter_icon" ) );

		this.selected_Categories = new Array();
	}

	public initGlobals ():void{
		this.getController().getModule().addView("filter_category_current_category_container", this.getController().getModule().getComponent( "filter.category.current_category_container" ));
		this.getController().getModule().addView("filter_category_current_category_row", this.getController().getModule().getComponent( "filter.category.current_category_row" ));
	}

    public create ( value:Object ):string {
		var current_category_output = "";
		
		/**
		 * Build Current Selected Categories first, if exists
		 */
		if (ControllerSearch.SELECTED_CATEGORIES.length > 0) {
			for (var i = 0; i < ControllerSearch.SELECTED_CATEGORIES.length; i++) {
				var modelID = this.getController().getModel().new();
				var currentCategory = ControllerSearch.SELECTED_CATEGORIES[i];
				var currentHeadline = ControllerSearch.SELECTED_HEADLINES[i];
				var tag = "";

				this.getController().getModel().add( modelID, "category_headline", currentHeadline);
				this.getController().getModel().add( modelID, "category_name", currentCategory );
				this.getController().getModel().add( modelID, "link", this.getController().getCaspardoQuery());
				this.getController().getModel().add( modelID, "selectedPos", i);

				if (ControllerSearch.SELECTED_BRANDS.includes(currentCategory)) {
					tag = "brand";
				} else if (ControllerSearch.SELECTED_TAGS.includes(currentCategory)) {
					tag = "tag";
				} else {
					tag = "category1";
				}

				this.getController().getModel().add( modelID, "tag", tag);
				
				current_category_output += this.getController().process( modelID, "filter_category_current_category_row" );
			}
            
            if ( current_category_output != "" ){
                return this.getController().processOne( "filter_category_current_category_container", "content", current_category_output );
            } else {
                return null;
            }
		}
	}

    public assignEvents ():void {
		var selected_categories_buttons = jQuery( "[data-ez-selected-tags]" );

		if (selected_categories_buttons.length) {
			selected_categories_buttons.on( "click", event => {
				var selected_element = jQuery(event.target);
				if (selected_element) {
					this.setSearchURL(selected_element);
				}
			});
		}
	}

	public setSearchURL( element:JQuery<HTMLElement> ):void {
		if (element) {
			var currentFilterURLEncoded = this.getController().buildFiltersURL();
			var currentTag = element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "selected-tag" );
			var currentTagName = element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "selected-tag-name" );
			var currentTagHeadline = element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "selected-tag-headline");
			var currentPos = element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "selected-pos");

			if ( currentTag == undefined || currentTagName == undefined || currentTagHeadline == undefined ) {
				element = element.parent();
				currentTag = element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "selected-tag" );
				currentTagName = element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "selected-tag-name" );
				currentTagHeadline = element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "selected-tag-headline");
				currentPos = element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "selected-pos");
			}

			var tempURL = "";
				tempURL += "&" + currentTag + "=" + encodeURIComponent(currentTagName);
			
			if (currentFilterURLEncoded.includes(tempURL)) {
				currentFilterURLEncoded = currentFilterURLEncoded.replace( tempURL, "" );

				this.refreshFilters(currentTagName, Number(currentPos));
			}

			this.getController().refreshAll();
			this.getController().onContentChange();
		}
	}

	public refreshFilters(currentTagName: string, currentPos:number):void {
		if (currentTagName) {
			if (ControllerSearch.SELECTED_CATEGORIES) {
				ControllerSearch.SELECTED_CATEGORIES = ControllerSearch.SELECTED_CATEGORIES.filter(function(item:string) {
					return item !== currentTagName;
				});
			}
			
			if (ControllerSearch.SELECTED_BRANDS) {
				ControllerSearch.SELECTED_BRANDS = ControllerSearch.SELECTED_BRANDS.filter(function(item:string) {
					return item !== currentTagName;
				});
			}
			
			if (ControllerSearch.SELECTED_TAGS) {
				ControllerSearch.SELECTED_TAGS = ControllerSearch.SELECTED_TAGS.filter(function(item:string) {
					return item !== currentTagName;
				});
			}
	
			if (ControllerSearch.SELECTED_HEADLINES) {
				// ControllerSearch.SELECTED_HEADLINES = ControllerSearch.SELECTED_HEADLINES.filter(function(item:string) {
				// 	return item !== currentTagHeadline;
				// });
				ControllerSearch.SELECTED_HEADLINES.splice(currentPos, 1);
			}
			
			if (CategoryFilter.blacklistedCategories) {
				CategoryFilter.blacklistedCategories = CategoryFilter.blacklistedCategories.filter(function(item:string) {
					return item !== currentTagName;
				});
			}

			if (NodeCategoryFilter.blacklistedCategories) {
				NodeCategoryFilter.blacklistedCategories = NodeCategoryFilter.blacklistedCategories.filter(function(item:string) {
					return item !== currentTagName;
				});
			}
			
			if (TagFilter.blacklistedTags) {
				TagFilter.blacklistedTags = TagFilter.blacklistedTags.filter(function(item:string) {
					return item !== currentTagName;
				});
			}
			
			if (BrandFilter.blacklistedBrands) {
				BrandFilter.blacklistedBrands = BrandFilter.blacklistedBrands.filter(function(item:string) {
					return item !== currentTagName;
				});
			}
		}
	}

    public reset ( input:JQuery<HTMLElement> = null ):void{
        var category_containers = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-category-container]" );

		if ( category_containers.length > 0 ) {

			category_containers.each( function() {
				jQuery( this ).show();

				var checkbox = jQuery( this ).find( "[" + Globals.ATTRIBUTE_PREFIX + "filter-category]" );
					checkbox.prop( "checked", false );
			});

		}

		if ( input != null && input.length ) {
			input.val( "" );
		}
	}
	
	/**
	 * 
	 * New Execute Category Filter
	 */
	public newExecute( element:JQuery<HTMLElement> ): void {
		if (element.length) {

			for (var i = 0; i < element.length; i++) {
				ControllerSearch.SELECTED_CATEGORIES.push( element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "filter-category" ));
			}

			this.getController().refreshAll();
			this.getController().onContentChange();
		}
	}

    /**
	*
	* Execute the category filter
	*/
	public execute ():void{
		this.selected_Categories = new Array();

		var checkboxes = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-category]" );
		if ( checkboxes.length > 0 ) {

			checkboxes.each( function( i:number ) {
				var checkbox = jQuery( checkboxes[i] );

				if ( checkbox.is( ":checked" ) ) {
					this.selected_Categories.push( checkbox.attr( ""+ Globals.ATTRIBUTE_PREFIX +"filter-category" ) );
				}
			}.bind(this));

		}

		this.getController().refreshAll();
		this.getController().onContentChange();
    }
    
    	/**
	*
	* Change the current selection
	*/
	private change ( value:string ):void{
		var value = value.toLowerCase();
		var category_containers = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-category-container]" );

		if ( category_containers.length > 0 ) {

			if ( value == "" ) {

				/**
				*
				* Reset the displayed options
				*/
				category_containers.each( function() {
					jQuery( this ).show();
				});

			} else {

				/**
				*
				* Hide all not checked options
				* Hide all not matching options
				*/
				category_containers.each( function() {
					var checkbox = jQuery( this ).find( "[" + Globals.ATTRIBUTE_PREFIX + "filter-category]" );
					var checkbox_value = checkbox.attr( ""+ Globals.ATTRIBUTE_PREFIX +"filter-category" ).toLowerCase();

					if ( !checkbox.is( ":checked" ) && checkbox_value.search( value ) == -1 ) {
						jQuery( this ).hide();
					}
				});

			}

		}
	}

	public buildURL (encode:boolean = true):string{
		var url:string = "";
		
		for (var i = 0; i < ControllerSearch.SELECTED_CATEGORIES.length; i++) {
			var currentCategory = ControllerSearch.SELECTED_CATEGORIES[i];

			if (ControllerSearch.SELECTED_BRANDS.includes(currentCategory)) {
				url += "&brand=";
			} else if (ControllerSearch.SELECTED_TAGS.includes(currentCategory)) {
				url += "&tag=";
			} else {
				url += "&category1=";
			}

			if (encode) {
				url += encodeURIComponent( currentCategory );
			} else {
				url += currentCategory
			}
		}

		return url;
	}

}