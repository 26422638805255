import { ControllerPagination } from "./ControllerPagination";
import { ModelPagination } from "./ModelPagination";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";
import { Module } from "../../classes/mvc/Module";

@ModuleConfig({
    moduleName: "Pagination",
    style: "pagination.scss"
})

export class ModulePagination extends Module<ModelPagination> {

    public constructor( configuration:Object ) {
        super("Pagination", configuration);
        this.addController( new ControllerPagination(this.getName(), this.getAccessID()));
    }

    public run() {
        this.runAllControllers();
    }

    public onControllersInitialized():void {
        
    }
}