import { ModelSelectionFilter } from "./ModelSelectionFilter";
import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import { Globals } from "../../classes/Globals";
import { Category } from "./Category";
import { Strings } from "../../libs/Strings";
import { Json } from "../../libs/Json";
import { Option } from "./Option";

export class ControllerSelectionFilter extends HTMLController<ModelSelectionFilter> {

	private static ELEMENT_SELECTOR: string;

	private categories: Array<Category> = new Array();

	public constructor(accessName: string, accessID: number, element: JQuery<HTMLElement>) {
		super(new ModelSelectionFilter(), accessName, accessID, element);
	}

	public initGlobals(): void {
		this.getModule().addView("category_container", this.getModule().getComponent("category_container", false));
		this.getModule().addView("option_container", this.getModule().getComponent("category_content.option_container", false));

		var elementSelector = this.getModule().getConfig("element_selector");
		if (elementSelector != null) {
			ControllerSelectionFilter.ELEMENT_SELECTOR = elementSelector;
		}
	}

	public run(): void {
		if (this.getModule().getData() != null && Array.isArray(this.getModule().getData())) {

			this.loadCategories(Strings.toArray(this.getParam("categories")));
			if (this.categories.length > 0) {

				for (let i = 0; i < this.categories.length; i++) {
					this.categories[i].build();
					this.categories[i].assignEvents();
				}

			} else {
				this.getModule().error(Globals.MODULE_LOADING_ERROR + " keine Daten für die Verarbeitung gefunden wurden.");
			}
		} else {
			this.getModule().error(Globals.MODULE_LOADING_ERROR + " keine Daten für die Verarbeitung gefunden wurden.");
		}
	}

	private loadCategories(categories: Array<string>): void {
		var data = this.getModule().getData();

		for (let i = 0; i < categories.length; i++) {
			var categoryKey: string = categories[i];
			var categoryName: string = this.getModule().getLabel(categoryKey);
			var categoryOptions: Array<Option> = new Array();

			var usedOptionKeys: Array<string> = new Array();

			/**
			 * 
			 * Get all key an the remove the duplicated ones
			 */
			for (let i = 0; i < data.length; i++) {
				var option = Json.getSubobject(data[i], categoryKey);
				if (option != null) {
					var optionKey = Json.getSubobject( option, "key" );
					var optionValue = Json.getSubobject( option, "value" );

					if ( usedOptionKeys.indexOf( optionKey ) == -1 ) {
						usedOptionKeys.push( optionKey );

						categoryOptions.push(new Option(optionKey, optionValue));
					}	
				}
			}

			if ( categoryOptions.length > 1 ){
				this.categories.push(new Category(categoryKey, categoryName, categoryOptions, this));
			}
		}
	}

	private findArticleIDs ( categoryKey:string, categoryOptionKey:string ):Array<string>{
		var data = this.getModule().getData();
		var result:Array<string> = new Array;

		for (let i = 0; i < data.length; i++) {
			if ( Json.getSubobject( data[i], categoryKey + ".key" ) == categoryOptionKey ){
				result.push( Json.getSubobject( data[i], "id" ) );
			}
		}

		return result;
	}

	private showCategory(categoryKey:string, categoryOptionIDs: Array<string>): void {
		var articleIDs:Array<string> = new Array();

		/**
		 * 
		 * Build a array with all css selectors to hide all elements with one command
		 */
		for (let i = 0; i < categoryOptionIDs.length; i++) {
			var acticleIDs:Array<string> = this.findArticleIDs( categoryKey, categoryOptionIDs[i] );

			for (let j = 0; j < acticleIDs.length; j++) {
				articleIDs.push( "[" + ControllerSelectionFilter.ELEMENT_SELECTOR + "='" + acticleIDs[j] + "']" );
			}
		}

		jQuery( articleIDs.join( "," ) ).show();
	}

	public showAll ():void{
		jQuery("[" + ControllerSelectionFilter.ELEMENT_SELECTOR + "]").show();
	}

	public processAllCategories ():void{
		jQuery("[" + ControllerSelectionFilter.ELEMENT_SELECTOR + "]").hide();

		for (let i = 0; i < this.categories.length; i++) {
			var categoryKey:string = this.categories[i].getKey();
			var categorySelectedOptionIDs:Array<string> = this.categories[i].getSelectedIDs();

			this.showCategory( categoryKey, categorySelectedOptionIDs );
		}
	}
}