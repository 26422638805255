import { ModelPasswordChecker } from "./ModelPasswordChecker";
import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import { Globals } from "./../../classes/Globals";
import { Pair } from "../../libs/Pair";
import jQuery = require( "jquery" );

export class ControllerPasswordChecker extends HTMLController<ModelPasswordChecker> {

    private static AVAILABLE_VALIDATIONS:Array<Pair<string, RegExp>>;
    private static CURRENT_VALIDATIONS:Array<string>;

    private static MAX_LENGTH:number;
    private static MIN_LENGTH:number;
    private static MAX_REPEATED_CHARS:number;

    private inputElement:JQuery<HTMLInputElement>;
    private outputElement:JQuery<HTMLElement>;

    private lastCheckedInput:string = "";
    private currentInputValid:boolean = true;

    public constructor ( accessName:string, accessID:number, element:JQuery<HTMLElement>) {
        super( new ModelPasswordChecker(), accessName, accessID, element );
    }

    public initGlobals ():void{
        ControllerPasswordChecker.AVAILABLE_VALIDATIONS = new Array();
        ControllerPasswordChecker.CURRENT_VALIDATIONS = new Array();

        ControllerPasswordChecker.MAX_LENGTH = 30;
        ControllerPasswordChecker.MIN_LENGTH = 8;
        ControllerPasswordChecker.MAX_REPEATED_CHARS = 2;

        ControllerPasswordChecker.AVAILABLE_VALIDATIONS.push( new Pair( "lowercase", /^(?=.*[a-z]).+$/ ) );
        ControllerPasswordChecker.AVAILABLE_VALIDATIONS.push( new Pair( "uppercase", /^(?=.*[A-Z]).+$/ ) );
        ControllerPasswordChecker.AVAILABLE_VALIDATIONS.push( new Pair( "number", /^(?=.*[0-9]).+$/ ) );
        ControllerPasswordChecker.AVAILABLE_VALIDATIONS.push( new Pair( "specialchar", /^(?=.*[_\W]).+$/ ) );

        var outputContainer:string= this.getModule().getComponent( "output.container" );
        var outputStatus:string= this.getModule().getComponent( "output.status" );

        if( outputContainer != null ){
            this.getModule().addView( "output_container", outputContainer );
        } else {
            this.getModule().addView( "output_container", "<div></div>") ;
        }

        if( outputStatus != null ){
            this.getModule().addView( "output_status", outputStatus );
        } else {
            this.getModule().addView( "output_status", "Status: {{{level}}} {{{text}}}" );
        }

        var currentValidations = this.getModule().getConfig( "validations" );
        if ( currentValidations != null ){
            ControllerPasswordChecker.CURRENT_VALIDATIONS = currentValidations;
        }

        var maxLength:string = this.getModule().getConfig( "max_length" );
        if ( maxLength != null && !isNaN( Number( maxLength ) ) ){
            ControllerPasswordChecker.MAX_LENGTH = Number( maxLength );
        }

        var minLength:string = this.getModule().getConfig( "min_length" );
        if ( minLength != null && !isNaN( Number( minLength ) ) ){
            ControllerPasswordChecker.MIN_LENGTH = Number( minLength );
        }

        var maxRepeatedChars:string = this.getModule().getConfig( "max_repeated_chars" );
        if ( maxRepeatedChars != null && !isNaN( Number( maxRepeatedChars ) ) ){
            ControllerPasswordChecker.MAX_REPEATED_CHARS = Number( maxRepeatedChars );
        }
    }

    public run ():void{    
        try {
            var itemElement:JQuery<HTMLElement> = this.getElement();
            if ( itemElement.length ){
                this.inputElement = itemElement.find( "["+ Globals.ATTRIBUTE_PREFIX +"input]" ) as JQuery<HTMLInputElement>;

                var statusModelID:number = this.getModel().new();
                this.getModel().add( statusModelID, "level", "<span class='status_level'></span>" );
                this.getModel().add( statusModelID, "text", "<span class='status_text'></span>" );

                var containerModelID:number = this.getModel().new();
                this.getModel().add( containerModelID, "status_text", "<div class='output_text'>"+ this.process( statusModelID, "output_status" ) +"</div>" );
                this.getModel().add( containerModelID, "status_bar", "<div class='output_bar'><div class='inner'></div></div>" );

                this.outputElement = jQuery( this.process( containerModelID, "output_container" ) );

                if ( this.outputElement.length ){

                    if ( !this.setOutputElement( this.outputElement ) ){
                        this.outputElement.insertAfter( this.inputElement );
                    }
    
                    /**
                     * 
                     * Events
                     */
                    this.inputElement.on( "input", function (){
                        this.validate();
                    }.bind(this));
    
                    // var showPasswordToggle = this.getElement().find( "[" + Globals.ATTRIBUTE_PREFIX + "show-password-toggle]" );
                    // if ( showPasswordToggle.length ){
                    //     showPasswordToggle.change ( function (){
                    //         this.inputElement.attr( "type", showPasswordToggle.is( ":checked" ) ? "text" : "password" );
                    //     }.bind(this));
                    // }
    
                    /**
                     * 
                     * Start
                     */
                    this.validate();
                }
            }
        } catch {
            this.getModule().error( Globals.MODULE_LOADING_ERROR + " ein Fehler aufgetreten ist" );
        }
    }

    public validate ():boolean{
        var inputValue:string = String( this.inputElement.val() );
        if ( this.lastCheckedInput == "" || this.lastCheckedInput != inputValue ){

            var minLength:boolean = inputValue.length >= ControllerPasswordChecker.MIN_LENGTH;
            var maxLength:boolean = inputValue.length <= ControllerPasswordChecker.MAX_LENGTH;
            var repeatedChars:boolean = !new RegExp( "(.)\\1{"+ ControllerPasswordChecker.MAX_REPEATED_CHARS +",}", "g" ).test( inputValue );

            var minRequirements:boolean = minLength && maxLength && repeatedChars;

            var count:number = 0;
            var maxSteps:number = ControllerPasswordChecker.CURRENT_VALIDATIONS.length + 1;

            this.getElement().attr( Globals.ATTRIBUTE_PREFIX + "min-length", minLength ? "true" : "false" );
            this.getElement().attr( Globals.ATTRIBUTE_PREFIX + "max-length", maxLength ? "true" : "false" );
            this.getElement().attr( Globals.ATTRIBUTE_PREFIX + "repeated-chars", repeatedChars ? "true" : "false" );

            for (let i = 0; i < ControllerPasswordChecker.CURRENT_VALIDATIONS.length; i++) {
                var valid:boolean = this.checkValidation( ControllerPasswordChecker.CURRENT_VALIDATIONS[i], inputValue );

                if ( minRequirements && valid ){
                    count++;
                }

                if ( minRequirements ){
                    this.getElement().attr( Globals.ATTRIBUTE_PREFIX + ControllerPasswordChecker.CURRENT_VALIDATIONS[i], valid ? "true" : "false" );
                } else {
                    this.getElement().attr( Globals.ATTRIBUTE_PREFIX + ControllerPasswordChecker.CURRENT_VALIDATIONS[i], "false" );
                }
            }

            var colorValue:number = ( 140 / maxSteps ) * count;
            var colorString:string = "hsl(" + colorValue + ", 80%, 50%)";
            
            this.outputElement.find( ".output_bar .inner" ).css( "background-color", colorString );
            this.outputElement.find( ".output_bar .inner" ).css( "width", ( ( 100 / maxSteps ) * ( count + 1 ) ) + "%" );

            this.outputElement.find( ".output_text .status_level" ).html( String( count ) );
            this.outputElement.find( ".output_text .status_text" ).html( this.getModule().getLabel( "status." + count ) );

            this.getElement().attr( Globals.ATTRIBUTE_PREFIX + "min-requirements", minRequirements ? "true" : "false" );

            this.lastCheckedInput = inputValue;
            this.currentInputValid = minRequirements;
        }

        return this.currentInputValid;
    }

    private checkValidation ( validationName:string, inputValue:string ):boolean{
        var result:boolean = false;

        for (let i = 0; i < ControllerPasswordChecker.AVAILABLE_VALIDATIONS.length; i++) {
            if ( ControllerPasswordChecker.AVAILABLE_VALIDATIONS[i].getKey() == validationName ){
                result = ControllerPasswordChecker.AVAILABLE_VALIDATIONS[i].getValue().test( inputValue );

                break;
            }
        }

        return result;
    }

}