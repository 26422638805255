import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import { ControllerLightbox } from "./ControllerLightbox";
import { ModelLightbox } from "./ModelLightbox";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";

@ModuleConfig({
    moduleName: "Lightbox",
    style: "lightbox.scss"
})
export class ModuleLightbox extends HTMLModule<ModelLightbox> {
    
    public constructor ( configuration:Object ){
        super( "Lightbox", configuration );
        
        this.initAll( ControllerLightbox.prototype );
    }

    public run (){
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

}