import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import { Globals } from "./../../classes/Globals";
import { ModelSearch } from "./ModelSearch";
import { Strings } from "./../../libs/Strings";
import { modules } from "../../main";
import { Json } from "../../libs/Json";
import { OrderByItem } from "./OrderByItem";
import { Filter } from "./Filters/Filter";
import { PriceFilter } from "./Filters/PriceFilter";
import { BrandFilter } from "./Filters/BrandFilter";
import { CategoryFilter } from "./Filters/CategoryFilter";
import { TagFilter } from "./Filters/TagFilter";
import { SelectedCategories } from "./Filters/SelectedCategories";
import jQuery = require("jquery");

import { findIcon } from "../../libs/Icons";

import "jquery-ui";
import { Pair } from "../../libs/Pair";
import { Collapse } from "../../classes/Collapse";
import { ModuleNumberInput } from "../NumberInput/ModuleNumberInput";
import { NodeCategoryFilter } from "./Filters/NodeCategoryFilter";
import { FilterBox} from "./Filters/FilterBox";
import { filter } from "bluebird";
import { Modules } from "../../Modules";

declare var ezentrum_variables: any;
export class ControllerSearch extends HTMLController<ModelSearch>{
	private static CASPARDO_QUERY: string;
	private static CASPARDO_BRAND: string;

	public static SEARCH_URL: string;
	private static TARGET_URL_QUERY: string;

	private static ARTICLE_SYNC: boolean;
	private static AUTO_SCROLL: boolean;
	private static AUTO_SCROLL_FIRST_CLICK: boolean;

	public static PAGE_TYPE: number;
	private static SCROLL_DISTANCE_TO_BOTTOM: number;

	private static TAB_INFORMATION_DISPLAY_KEYWORDS: boolean;
	private static TAB_INFORMATION_DISPLAY_DESCRIPTION: boolean;

	private static TAB_SHOP_DUMMY_ROW_COUNT: number;
	private static TAB_SHOP_DUMMY_ROW: JQuery<HTMLElement>;

	public static ODER_BY_ITEMS: Array<OrderByItem>;
	private static FILTERS: Array<Filter>;
	private static CURRENT_CATEGORY: Filter;

	private static ARTICLE_SYNC_ITEMS: Array<Pair<string, string>>;
	private static DISABLED_FILTERS: Array<string>;

	public static SELECTED_CATEGORIES: Array<string>;
	public static SELECTED_TAGS: Array<string>;
	public static SELECTED_BRANDS: Array<string>;

	public static SELECTED_HEADLINES: Array<string>;

	public static BUILD_TAG_FILTER: boolean;
	public static BUILD_CATEGORY_NODE: boolean;
	public static HIDE_CATEGORIES: boolean;

	public static CATEGORY_LINK_MODE: number;
	public static CURRENT_ORDERBY_RANK: number;

	// Filter-Box
	public static FILTER_BOX_TEMPLATE: string;
	public static FILTER_BOX_LABLE_CATEGORY_HEADLINE: string;

	public static FILTER_BOX_COMPONENT:FilterBox=null;

	public query: string;
	private translateWord: string;
	private realQuery: string;

	private searchQueryOutOfTabs: boolean;

	public type: string;
	public tab: string;

	public page: number;
	public pages: number;

	private hits: number;
	private currentShopHits: number;

	private tabsBuilded: boolean;
	private assignedEvents: boolean;
	public filtersLoaded: boolean;
	private activatedAutoScrolling: boolean;
	private activatedRecommendationTab: boolean;

	public currentOrderByItem: number;
	private initNumberInput: boolean;

	private activateSelectedCategories: boolean;

	/**
	 * 
	 * HTML Elements
	 */
	private tabs: JQuery<HTMLElement>;

	private filtersBox: JQuery<HTMLElement>;
	private currentCategoriesBox: JQuery<HTMLElement>;
	private orderbyBox: JQuery<HTMLElement>;
	private recommendationsBox: JQuery<HTMLElement>;

	private nextPageButton: JQuery<HTMLElement>;

	private tabShopDummyRows: Array<JQuery<HTMLElement>>;


	public constructor(accessName: string, accessID: number, element: JQuery<HTMLElement>) {
		super(new ModelSearch(), accessName, accessID, element);
	}

	public initGlobals(): void {
		var target_url = this.getModule().getConfig("target_url") + "&sKontaktID=" + modules.getKontaktID() + "&sKontaktKEY=" + modules.getKontaktKey() + "&sTICKCOUNT=" + modules.getTickcount();
		// URL Parameters
		if (typeof ezentrum_variables !== 'undefined') {
			ControllerSearch.CASPARDO_QUERY = ezentrum_variables.T_caspardo_query;
			ControllerSearch.CASPARDO_BRAND = ezentrum_variables.T_caspardo_brand;
		} else {
			ControllerSearch.CASPARDO_QUERY = Modules.modulesVariables.T_caspardo_query;
			ControllerSearch.CASPARDO_BRAND = Modules.modulesVariables.T_caspardo_brand;
		}
		// Selected Filters
		ControllerSearch.SELECTED_CATEGORIES = new Array();
		ControllerSearch.SELECTED_TAGS = new Array();
		ControllerSearch.SELECTED_BRANDS = new Array();
		ControllerSearch.SELECTED_HEADLINES = new Array();
		// TAG Filter
		ControllerSearch.BUILD_TAG_FILTER = false;

		var categoryLinkMode = this.getModule().getConfig("category_link_mode");
		if ( categoryLinkMode != null ) {
			ControllerSearch.CATEGORY_LINK_MODE = parseInt( categoryLinkMode );
		}

		var buildCategoryNode = this.getModule().getConfig("build_category_node");
		if (buildCategoryNode != null) {
			ControllerSearch.BUILD_CATEGORY_NODE = buildCategoryNode;
		} else {
			ControllerSearch.BUILD_CATEGORY_NODE = false;
		}

		if (typeof ControllerSearch.CASPARDO_QUERY !== "undefined") {
			ControllerSearch.CASPARDO_QUERY = Strings.stripSpecialChars(ControllerSearch.CASPARDO_QUERY);
		}
		if (typeof ControllerSearch.CASPARDO_BRAND !== "undefined") {
			ControllerSearch.CASPARDO_BRAND = Strings.stripSpecialChars(ControllerSearch.CASPARDO_BRAND);
		}

		ControllerSearch.SEARCH_URL = this.getModule().getConfig("search_url");
		ControllerSearch.TARGET_URL_QUERY = target_url + "&query=";

		ControllerSearch.ARTICLE_SYNC = this.getModule().getConfig("article_sync") || false;
		ControllerSearch.PAGE_TYPE = this.getModule().getConfig("tabs.shop.display_article_per_page_type") || 1;

		ControllerSearch.AUTO_SCROLL = this.getModule().getConfig("autoscroll") == true;
		ControllerSearch.SCROLL_DISTANCE_TO_BOTTOM = parseInt(this.getModule().getConfig("scroll_distance_to_bottom"));

		ControllerSearch.TAB_INFORMATION_DISPLAY_DESCRIPTION = this.getModule().getConfig("tabs.information.display_description") == true;
		ControllerSearch.TAB_INFORMATION_DISPLAY_KEYWORDS = this.getModule().getConfig("tabs.information.display_keywords") == true;

		ControllerSearch.TAB_SHOP_DUMMY_ROW_COUNT = 0;

		ControllerSearch.ODER_BY_ITEMS = new Array();
		ControllerSearch.ODER_BY_ITEMS.push(new OrderByItem(1, this.getModule().getLabel("orderby_option_relevance")));
		ControllerSearch.ODER_BY_ITEMS.push(new OrderByItem(2, this.getModule().getLabel("orderby_option_price_ascending")));
		ControllerSearch.ODER_BY_ITEMS.push(new OrderByItem(3, this.getModule().getLabel("orderby_option_price_descending")));
		// ControllerSearch.ODER_BY_ITEMS.push(new OrderByItem(6, this.getModule().getLabel("orderby_option_top_article")));

		ControllerSearch.FILTERS = new Array();
		ControllerSearch.FILTERS.push(new PriceFilter(this));
		ControllerSearch.FILTERS.push(new BrandFilter(this));

		if (ControllerSearch.BUILD_CATEGORY_NODE) {
			ControllerSearch.FILTERS.push(new NodeCategoryFilter(this));
		} else {
			ControllerSearch.FILTERS.push(new CategoryFilter(this));
		}

		ControllerSearch.FILTERS.push(new TagFilter(this));

		ControllerSearch.CURRENT_CATEGORY = new SelectedCategories(this);

		// Article Synch Field Defintions for live shop data
		ControllerSearch.ARTICLE_SYNC_ITEMS = new Array();
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("price", "price"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("unit", "info1"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("info1", "info1"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("info2", "info2"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("info3", "info3"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("info4", "info4"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("info5", "info5"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("info6", "info6"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("info7", "info7"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("rating", "rating"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("statustype", "statustype"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("status", "status"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("amount", "bestmenge"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("bestmenge", "bestmenge"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("availability", "availability"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("availability_status", "availability_status"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("stock_quantity", "stock_quantity"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("bulkprices", "bulkprices"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("cart_quantity", "cart_quantity"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("cart_link", "cart_link"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("artid", "artid"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("customerid", "customerid"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("showprice", "showprice"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("basisprice", "basisprice"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("basispriceunit", "basispriceunit"));
		ControllerSearch.ARTICLE_SYNC_ITEMS.push(new Pair("base_price", "base_price"));

		ControllerSearch.DISABLED_FILTERS = new Array();

		// Filter-Box
		ControllerSearch.FILTER_BOX_TEMPLATE = this.getModule().getConfig("filter_box.template");
		let selectedFilterTemplate = this.getModule().getConfig("filter_box.template_selected_filter");
		let templateFilterStatus = this.getModule().getConfig("filter_box.template_filter_status");
		ControllerSearch.FILTER_BOX_LABLE_CATEGORY_HEADLINE=this.getModule().getLabel("filter_category_headline");
	
		ControllerSearch.FILTER_BOX_COMPONENT=new FilterBox(this,ControllerSearch.FILTER_BOX_TEMPLATE,selectedFilterTemplate,templateFilterStatus);
		var disabledFilters: Array<string> = this.getModule().getConfig("disabled_filters");
		if (disabledFilters != null && disabledFilters.length) {
			ControllerSearch.DISABLED_FILTERS = disabledFilters;
		}

		var tabShopDummyRowCount: number = Number(this.getModule().getConfig("tabs.shop.dummy_row_count"));
		if (!isNaN(tabShopDummyRowCount)) {
			ControllerSearch.TAB_SHOP_DUMMY_ROW_COUNT = tabShopDummyRowCount;
		}

		var dummyRow = jQuery(this.getModule().getComponent("tabs.shop.dummy_row", false));
		if (dummyRow.length) {
			ControllerSearch.TAB_SHOP_DUMMY_ROW = dummyRow;
		}

		var searchStringOutOfTabs = this.getModule().getConfig("tabs.search_query_out_of_tabs");
		if (searchStringOutOfTabs != null) {
			this.searchQueryOutOfTabs = searchStringOutOfTabs;
		}

		let reInitNumberInputBool = this.getModule().getConfig("reInitNumberInput");
		if (reInitNumberInputBool != null) {
			this.initNumberInput = reInitNumberInputBool;
		}

		let b_activateSelectedCategories = this.getModule().getConfig("activate_selected_categories");
		if (b_activateSelectedCategories != null) {
			this.activateSelectedCategories = b_activateSelectedCategories;
		}

		let currentOrderByRank = this.getModule().getConfig("current_orderby_rank");
		if ( currentOrderByRank != null ) {
			ControllerSearch.CURRENT_ORDERBY_RANK = currentOrderByRank;
		} else {
			ControllerSearch.CURRENT_ORDERBY_RANK = 2;
		}

		let hideCategories = this.getModule().getConfig("hide_categories");
		if ( hideCategories != null ) {
			ControllerSearch.HIDE_CATEGORIES = hideCategories;
		}
	}

	public run(): void {
		if (typeof ControllerSearch.CASPARDO_QUERY !== "undefined" && typeof ControllerSearch.CASPARDO_BRAND !== "undefined") {
			this.query = ControllerSearch.CASPARDO_QUERY;
			this.translateWord = "";
			this.realQuery = "";

			this.type = "shop";
			this.tab = "shop";

			this.page = 1;
			this.pages = 1;

			this.hits = null;
			this.currentShopHits = null;

			this.tabsBuilded = false;
			this.assignedEvents = false;
			this.filtersLoaded = true;
			this.activatedAutoScrolling = false;

			this.activatedRecommendationTab = this.getModule().getConfig("tabs.recommendation.active");

			this.currentOrderByItem = this.getModule().getConfig("current_order_by_id");

			this.tabShopDummyRows = new Array();
			if (ControllerSearch.TAB_SHOP_DUMMY_ROW_COUNT > 0) {
				for (let index = 0; index < ControllerSearch.TAB_SHOP_DUMMY_ROW_COUNT; index++) {
					this.tabShopDummyRows.push(ControllerSearch.TAB_SHOP_DUMMY_ROW.clone());
				}
			}

			this.tabs = this.getElement();

			this.getModule().addView("search_url", ControllerSearch.SEARCH_URL);
			ControllerSearch.SEARCH_URL = this.processOne("search_url", "id", modules.getGlobalConfig("caspardo_language_mapping." + modules.getLanguageCode()));

			this.loadAllViews();

			/**
			*
			* Start the search
			*/
			if (this.tabs.length) {
				this.refreshAll();
			}
		} else {
			this.getModule().error(Globals.MODULE_LOADING_ERROR + " folgende Variablen nicht definiert wurden: ezentrum_variables.T_caspardo_query und ezentrum_variables.T_caspardo_brand");
		}
	}

	/**
	 * 
	 * Load all handlebars views
	 */
	private loadAllViews(): void {
		/**
		 * 
		 * Recommendations Views
		 */
		this.getModule().addView("recommendations_row_container", this.getModule().getComponent("recommendations.row_container", false));
		this.getModule().addView("recommendations_row_content_picture", this.getModule().getComponent("recommendations.row_content_picture", false));
		this.getModule().addView("recommendations_row_content_text", this.getModule().getComponent("recommendations.row_content_text", false));

		/**
		* 
		* Orderby Views
		*/
		this.getModule().addView("orderby_container", this.getModule().getComponent("orderby.container", false));
		this.getModule().addView("orderby_row", this.getModule().getComponent("orderby.row", false));

		/**
		* 
		* Do You Mean View
		*/
		this.getModule().addView("doyoumean_container", this.getModule().getComponent("doyoumean.container", false));

		/**
		* 
		* Filters View
		*/
		this.getModule().addView("filter_container", this.getModule().getComponent("filter.container", false));

		/**
		* 
		* Current Selected Categories View
		*/
		this.getModule().addView("filter_selected_category_container", this.getModule().getComponent("filter.selectedCategoryContainer"));

		/**
		* 
		* Tab Shop View
		*/
		this.getModule().addView("tab_shop_structure", this.getModule().getComponent("tabs.shop.structure", false));
		this.getModule().addView("tab_shop_row", this.getModule().getComponent("tabs.shop.row", false));

		/**
		* 
		* Tab Information
		*/
		this.getModule().addView("tab_information_structure", this.getModule().getComponent("tabs.information.structure", false));

		this.getModule().addView("tab_information_content_structure", this.getModule().getComponent("tabs.information.content.structure", false));
		this.getModule().addView("tab_information_content_container_description", this.getModule().getComponent("tabs.information.content.container_description", false));
		this.getModule().addView("tab_information_content_container_keywords", this.getModule().getComponent("tabs.information.content.container_keywords", false));
		this.getModule().addView("tab_information_content_container_content", this.getModule().getComponent("tabs.information.content.container_content", false));
		this.getModule().addView("tab_information_content_row_keywords", this.getModule().getComponent("tabs.information.content.row_keywords", false));

		this.getModule().addView("tabs_searchQueryString_structure", this.getModule().getComponent("tabs.searchQueryString.structure", false));
	}

	/**
	*
	* Build the tabs if necessary
	* Process the content
	* Display the content
	*/
	public refreshAll(): void {
		this.page = 1;

		//var nav_category=ControllerSearch.FILTER_BOX_COMPONENT.getFilterURLPart();

		let orderByNumber = this.getCurrentOrderBy();
	
		var search_result = this.getModel().getSearchResults(ControllerSearch.SEARCH_URL, this.tab, this.type, orderByNumber, ControllerSearch.PAGE_TYPE, this.filtersLoaded, this.buildFiltersURL(), this.page, this.query);
		if (search_result != null) {

			var tabs: Array<any> = this.getModel().getTabs(search_result);
			var results = this.getModel().getResults(this.tab, search_result);
			var filters = this.getModel().getFilters(this.tab, search_result);
			var recommendations = this.getModel().getRecommendations(search_result);
			var doyoumean = this.getModel().getDoyoumean(this.tab, search_result);
			var categoryMatch = this.getModel().getCategoryMatch(search_result);
			this.translateWord = this.getModel().getTranslateWord(search_result);
			this.realQuery = this.getModel().getQuery(search_result);

			let contentName = this.getModule().getLabel("recommendations_title");

			let recommendationslength;
			if (recommendations) {
				recommendationslength = recommendations.length;
			}

			let informationRank = this.getModule().getConfig("tabs.information.rank");
			let recommendationRank = this.getModule().getConfig("tabs.recommendation.rank");
			let shopTitle = this.getModule().getLabel("shop_title");
			let informationTitle = this.getModule().getLabel("information_title");

			this.hits = this.getModel().getCurrentTabHits(this.tab, search_result);
			this.page = this.getModel().getPage(this.tab, search_result);
			this.pages = this.getModel().getAmountPages(this.tab, search_result);

			// if (this.currentOrderByItem == 1) {
			// 	results = this.sortByKey(results, "price", "asc");
			// } else if (this.currentOrderByItem == 2) {
			// 	results = this.sortByKey(results, "price", "desc");
			// }

			/**
			*
			* Build the tabs if necessary
			*/
			if (!this.tabsBuilded) {

				/**
				 * Wenn der Modi aktiv ist werden die Recommendations als neuer Tab dargestellt
				 */
				if (this.activatedRecommendationTab && recommendationslength > 0) {

					for (var i = 0; i < tabs.length; i++) {
						if ( tabs[i].hasOwnProperty("content") ) {
							if ( tabs[i].content == "Shop" ) {
								tabs[i].rank = 1;
								if ( shopTitle ) {
									tabs[i].content = shopTitle;
								}
								
							} else if ( tabs[i].content == "Information" ) {
								if ( informationRank ) {
									tabs[i].rank = informationRank;
								}
								if ( informationTitle ) {
									tabs[i].content = informationTitle;
								}
							}
						}
					}

					if ( contentName && recommendationslength && recommendationRank ) {
						tabs.push({
							content: contentName,
							id: 10000,
							hits: recommendationslength,
							rank: recommendationRank,
							tab: "recommendation",
							type: "recommandations"
						});
					}
				}

				tabs.sort(this.sortTabs);

				this.buildTabs(tabs);
			}

			/**
			*
			* Build current tab html structure
			*/
			this.buildCurrentTabStructure();
			this.buildCurrentQueryString();

			/**
			*
			* Check the results
			*/
			if (results != null) {

				/**
				*
				* Build or destroy the recommendations
				*/
				if (recommendations != null) {
					this.buildCurrentRecommendations(recommendations);
				} else {
					this.destroyRecommendations();
				}

				/**
				*
				* Display the order by
				*/
				this.buildOrderby();

				/**
				 * Build TagFilter only, when a category is selected
				 */
				if (ControllerSearch.SELECTED_CATEGORIES.length == 0) {
				 	ControllerSearch.BUILD_TAG_FILTER = false;
				} else {
				 	ControllerSearch.BUILD_TAG_FILTER = true;
				}
				ControllerSearch.BUILD_TAG_FILTER = false;

				/**
				 * Adds CategoryMatch as current selected Categorie,
				 * If a match exists
				 */
				// if (categoryMatch > "") {
				// 	if (!ControllerSearch.SELECTED_CATEGORIES.includes(categoryMatch)) {
				// 		ControllerSearch.SELECTED_CATEGORIES.push(categoryMatch);
				// 		var categoryLabel = this.getModule().getLabel('selected_headline');
				// 		ControllerSearch.SELECTED_HEADLINES.push(categoryLabel);
				// 		ControllerSearch.BUILD_TAG_FILTER = true;
				// 	}
				// }

				/**
				 * Process and display the current selected Categories,
				 * If there are some selected
				 */

				// if (ControllerSearch.SELECTED_CATEGORIES.length > 0) {
				//  	var hasCategoryMatch = categoryMatch > "";
				//  	this.buildCurrentCategories(hasCategoryMatch);
				//  } else {
				//  	this.destroyCurrentCategories();
				// }

				/**
				*
				* Process and display the filters
				*/
				if (this.filtersLoaded) {

					let filter_viewtype=this.getModule().getConfig("filter_viewtype");
					// TODO: Neue Filter Methode
					// New Filter Generation!!! Hier muss eine neue Methode zur Filter-Generation eingebaut werden.
					if (filter_viewtype==="1") // Classic-View
						this.buildCurrentTabFilters(filters);
					if (filter_viewtype==="2") // New Nav-Filter-View
						this.buildFiltersV2(filters, this.tab);
				}

			} else {
				this.destroyRecommendations();
			}

			if ( ControllerSearch.HIDE_CATEGORIES ) {
				if (ControllerSearch.SELECTED_CATEGORIES.length >= 2) {
					var element = jQuery("[data-name-id='filter_toggle_nav']");
					if (element) {
						jQuery(element).hide();
					}
				}
			}

			if (results != null || doyoumean != null) {
				var current_tab_content_element = jQuery("#tab-" + this.tab + " .item_listing");
				if (current_tab_content_element.length) {
					/**
					*
					* Clear current result content
					*/
					current_tab_content_element.html("");

					/**
					*
					* Process and display the results
					*/
					this.refreshCurrentTabContent(results, doyoumean);
				}
			}

			/**
			*
			* Set tabs hits
			*/
			this.setTabsHits(tabs);
			this.setCurrentTabHits();

			/**
			*
			* Destroy the next page button if the current page is also the last page
			*/
			this.buildCurrentTabAutoReload();

			/**
			*
			* Bind all static events
			*/
			if (!this.assignedEvents) {
				this.assignStaticEvents();

				/* for (let i = 0; i < ControllerSearch.FILTERS.length; i++) {
					if (ControllerSearch.FILTERS[i].isActive()) {
						ControllerSearch.FILTERS[i].execute();
					}
				} */
			}
		}
	}

	/**
	 * 
	 * Build the URL part with all filters
	 */
	public buildFiltersURL(): string {
		var filtersURL = "";

		if (this.activateSelectedCategories) {
			filtersURL += ControllerSearch.FILTERS[0].buildURL();
			filtersURL += ControllerSearch.CURRENT_CATEGORY.buildURL();
		} else {
			for (let i = 0; i < ControllerSearch.FILTERS.length; i++) {
				if (ControllerSearch.FILTERS[i].isActive()) {
					filtersURL += ControllerSearch.FILTERS[i].buildURL();
				}
			}
		}

		return filtersURL;
	}

	/**
	*
	* Refresh only the content area
	* Used to display the next page
	*/
	private refresh_content(): void {
		if (this.currentOrderByItem == 6){
			this.currentOrderByItem = 3;
		}
		if (!ControllerSearch.AUTO_SCROLL) {
			this.page=1;
		}
		//var orderByElement = (document.getElementById("orderby_select")) as HTMLSelectElement;
		//var currentOrderBy=orderByElement.options[orderByElement.selectedIndex].getAttribute("" + Globals.ATTRIBUTE_PREFIX + "orderby");
		let orderByNumber = this.getCurrentOrderBy();

		var search_result = this.getModel().getSearchResults(ControllerSearch.SEARCH_URL, this.tab, this.type, orderByNumber, ControllerSearch.PAGE_TYPE, !this.filtersLoaded, this.buildFiltersURL(), this.page, this.query);
		if (search_result != null) {
			var results = this.getModel().getResults(this.tab, search_result);

			// TODO: reInit des number input moduls

			this.refreshCurrentTabContent(results, null);
			this.buildCurrentTabAutoReload();
			this.onContentChange();

			if (this.initNumberInput) {
				this.reInitNumberInput();
			}
		}
	}

	public getCurrentOrderBy():number{
		var orderByElement = (document.getElementById("orderby_select")) as HTMLSelectElement;
		if (!orderByElement)
			return 1;
		var currentOrderBy=orderByElement.options[orderByElement.selectedIndex].getAttribute("" + Globals.ATTRIBUTE_PREFIX + "orderby");
		let orderByNumber = parseInt(currentOrderBy);
		if (!orderByNumber)
			orderByNumber=1;
		return orderByNumber;
	}

	/**
	*
	* Build the tab structure
	*/
	private buildTabs(tabs: Array<Object>): boolean {
		/**
		*
		* Check if the tabs structure (got from the json response) exists
		*/
		if (tabs != null) {

			var tabs_content = "";
			var tabs_links = "<ul>";
			var currentCategories_content = "";

			/**
			*
			* Recommendations box
			*/
			tabs_content += "<div id='recommendations_box'></div>";

			if (this.searchQueryOutOfTabs) {
				/**
				* 
				* Search Query String
				*/
				tabs_content += "<div id='search_query_string'></div>";

				/**
				* 
				* Current Categories Box
				*/
				currentCategories_content += "<div id='currentCategories'></div>";

				/**
				*
				* Filters box
				*/
				tabs_content += "<div id='filters_box'></div>" + currentCategories_content;
			} else {
				/**
				*
				* Filters box
				*/
				tabs_content += "<div id='filters_box'></div>";

				/**
				* 
				* Search Query String
				*/
				tabs_content += "<div id='search_query_string'></div>";

				/**
				* 
				* Current Categories Box
				*/
				currentCategories_content += "<div id='currentCategories'></div>";

				tabs_content += currentCategories_content;
			}

			/**
			*
			* All tabs
			*/
			for (var i = 0; i < tabs.length; i++) {
				var tab = Json.getSubobject(tabs[i], "tab");
				var type = Json.getSubobject(tabs[i], "type");
				var content = Json.getSubobject(tabs[i], "content");

				if (tab != "all") {
					tabs_links += "<li " + Globals.ATTRIBUTE_PREFIX + "tab='" + tab + "' " + Globals.ATTRIBUTE_PREFIX + "type='" + type + "'><a href='#tab-" + tab + "'>" + content + " (<span " + Globals.ATTRIBUTE_PREFIX + "tab-hits='" + tab + "'></span>)</a></li>";

					tabs_content += "<div id='tab-" + tab + "'>";
					tabs_content += "</div>";
				}
			}

			/**
			*
			* Next page button
			*/
			tabs_content += this.getModule().getComponent("next_page_button", false);

			tabs_links += "</ul>";

			this.tabs.append(tabs_links + tabs_content);

			if (this.activatedRecommendationTab) {
				this.recommendationsBox = jQuery("#tab-recommendation");
			} else {
				this.recommendationsBox = jQuery("#recommendations_box");
			}
			
			this.filtersBox = jQuery("#filters_box");
			this.currentCategoriesBox = jQuery("#currentCategories");
			this.nextPageButton = jQuery("#next_button");

			this.tabsBuilded = true;

			return true;

		} else {
			return false;
		}
	}

	/**
	*
	* Build recommendations
	*/
	private buildCurrentRecommendations(recommendations: Array<Object>): void {
		if (this.recommendationsBox.length && recommendations.length > 0) {

			var output: string = "";

			for (var i = 0; i < recommendations.length; i++) {
				var link = Json.getSubobject(recommendations[i], "targetlink");
				var pictureurl = Json.getSubobject(recommendations[i], "pictureurl");
				/**
				*
				* Create the html output for all recommendations
				*/
				var modelID = this.getModel().new();

				this.getModel().add(modelID, "url", link);
				this.getModel().add(modelID, "title", Json.getSubobject(recommendations[i], "title"));
				this.getModel().add(modelID, "description", Json.getSubobject(recommendations[i], "description"));
				this.getModel().add(modelID, "subtitle", Json.getSubobject(recommendations[i], "subtitle"));
				this.getModel().add(modelID, "picture", Json.getSubobject(recommendations[i], "pictureurl"));

				if (pictureurl != "") {
					output += this.process(modelID, "recommendations_row_content_picture");
				} else {
					output += this.process(modelID, "recommendations_row_content_text");
				}
			}
			var modelID = this.getModel().new();
			this.getModel().add(modelID, "content", output);

			this.recommendationsBox.html(this.process(modelID, "recommendations_row_container"));
		}
	}

	/**
	*
	* Destroy recommendations
	*/
	private destroyCurrentCategories(): void {
		if (this.currentCategoriesBox.length) {
			this.currentCategoriesBox.html("");
		}
	}

	/**
	*
	* Destroy recommendations
	*/
	private destroyRecommendations(): void {
		if (this.recommendationsBox.length) {
			this.recommendationsBox.html("");
		}
	}

	/**
	*
	* Build orderby
	*/
	private buildOrderby(): void {
		if (this.orderbyBox.length) {

			/**
			*
			* Build the orderby structure for the current tab
			*/
			if (this.tab == "shop") {

				var content = "";
				for (var i = 0; i < ControllerSearch.ODER_BY_ITEMS.length; i++) {
					var modelID = this.getModel().new();

					this.getModel().add(modelID, "id", ControllerSearch.ODER_BY_ITEMS[i].getID());
					this.getModel().add(modelID, "selected", this.currentOrderByItem == ControllerSearch.ODER_BY_ITEMS[i].getID() ? "selected" : "");
					this.getModel().add(modelID, "label", ControllerSearch.ODER_BY_ITEMS[i].getLabel());

					content += this.process(modelID, "orderby_row")
				}

				var modelID = this.getModel().new();
				this.getModel().add(modelID, "content", content);

				this.orderbyBox.html(this.process(modelID, "orderby_container"));
			} else {
				this.destroyOrderby();
			}
		}
	}

	/**
	*
	* Destroy recommendations
	*/
	private destroyOrderby(): void {
		if (this.orderbyBox.length) {
			this.orderbyBox.html("");
		}
	}

	/**
	 * 
	 * Build Current Selected Categories
	 */
	private buildCurrentCategories( hasCategoryMatch:boolean = false ): void {
		if (this.currentCategoriesBox.length) {
			this.currentCategoriesBox.html("");
			var output = "";

			if (ControllerSearch.SELECTED_CATEGORIES.length > 0) {
				var current_selected_content: string = null;

				current_selected_content = ControllerSearch.CURRENT_CATEGORY.create(ControllerSearch.SELECTED_CATEGORIES);

				if (current_selected_content != null) {
					var modelID = this.getModel().new();

					var link = this.getModule().getConfig( "target_url" ) + "&hid=" + modules.getGlobalConfig( "mandant_id" ) + "&sprachnr=" + modules.getLanguageCode() + "&enter=caspardo";

					if (hasCategoryMatch) {
						link = link + "&query=";
					} else {
						link = link + "&query=" + this.query;
					}
					this.getModel().add(modelID, "link", link);
					this.getModel().add(modelID, "content", current_selected_content);
					output += this.process(modelID, "filter_selected_category_container");
				}
			}

			this.currentCategoriesBox.html(output);

			ControllerSearch.CURRENT_CATEGORY.assignEvents();
		}

	}

	private buildFiltersV2(filters: any, tab: string) {

		if (tab === "shop") {
			filters.filter_category_headline = ControllerSearch.FILTER_BOX_LABLE_CATEGORY_HEADLINE;
			filters.filter_detail_headline = this.getModule().getLabel("filter_detail_headline");
			filters.filter_brand_headline = this.getModule().getLabel("filter_brand_headline");
			let nav_index = 0;
			let filter_index = 0;
			for (let item of filters) {
				if (item.type === "nav") {
					if (!(item.node instanceof Array)) {
						var tempArray = [];
						tempArray.push(item.node);
						item.node = tempArray;
					}
					for (let node_entry of item.node) {
						node_entry.item.id = nav_index;
						let rootName=node_entry.item.name;
						let rootID=node_entry.item.id;
						nav_index++;
						if (node_entry.childs.node) {
							if (!(node_entry.childs.node instanceof Array)) {
								var tempArray = [];
								tempArray.push(node_entry.childs.node);
								node_entry.childs.node = tempArray;
							}
							for (let childnode_entry of node_entry.childs.node) {
								childnode_entry.id = nav_index;
								childnode_entry.rootname= rootName;
								childnode_entry.rootid=rootID;
								nav_index++;
							}
						}
					}
				}
				if (item.type === "tag") {
					item.id = filter_index;
					if (!(item.value instanceof Array)) {
						var tempArray = [];
						tempArray.push(item.value);
						item.value = tempArray;
					}
				}
				if (item.type === "brand") {
					item.id = filter_index;
					if (!(item.value instanceof Array)) {
						var tempArray = [];
						tempArray.push(item.value);
						item.value = tempArray;
					}
				}
				filter_index++;
			}

			let log = JSON.stringify(filters);
			// console.log(log);

			if (this.filtersBox.length) {
				ControllerSearch.FILTER_BOX_COMPONENT.renderHTML(filters);
			}
		}

	}


	/**
	*
	* Build Filters
	*/
	private buildCurrentTabFilters(filters: Array<Object>): void {
		if (this.filtersBox.length) {

			this.filtersBox.html("");

			if (filters != null) {
				/**
				*
				* Process all filters
				*/
				var output = "";

				filters = this.processTags( filters );

				for (var i = 0; i < filters.length; i++) {

					var name = Json.getSubobject(filters[i], "name");

					var value;
					if ( name == "nav" ) {
						value = Json.getSubobject(filters[i], "node");
					} else {
						value = Json.getSubobject(filters[i], "value");
					}

					var tagType = Json.getSubobject(filters[i], "type");
					/**
					 * 
					 * Check if the filter is disabled
					 */
					if (ControllerSearch.DISABLED_FILTERS.indexOf(name) == -1) {

						var current_filter: Filter;
						var current_filter_content: string = null;

						for (let j = 0; j < ControllerSearch.FILTERS.length; j++) {
							if (ControllerSearch.FILTERS[j].getName() == name) {
								current_filter = ControllerSearch.FILTERS[j];

								if (tagType == "tag") {
									// if (ControllerSearch.SELECTED_CATEGORIES.length >= 1) {
									// 	current_filter_content = ControllerSearch.FILTERS[j].create(filters[i]);
									// } else {
									// 	break;
									// }
									if (ControllerSearch.BUILD_TAG_FILTER) {
										current_filter_content = ControllerSearch.FILTERS[j].create(filters[i]);
									} else {
										break;
									}
								} else {
									current_filter_content = ControllerSearch.FILTERS[j].create(value);
								}

								ControllerSearch.FILTERS[j].activate();

								break;
							}
						}

						/**
						*
						* Check if the filters content isn´t emtpy
						* and create the container for the filters
						*/
						if (current_filter_content != null) {
							var modelID = this.getModel().new();

							this.getModel().add(modelID, "icon", current_filter.getIcon());
							this.getModel().add(modelID, "name", "filter_toggle_" + current_filter.getName());
							this.getModel().add(modelID, "label", current_filter.getHeadline());
							this.getModel().add(modelID, "tagName", "name");

							this.getModel().add(modelID, "content", current_filter_content);

							output += this.process(modelID, "filter_container");
						}

					}
				}

				this.filtersBox.html(output);
				if (ControllerSearch.BUILD_CATEGORY_NODE) {
					this.filtersLoaded = true;
				} else {
					this.filtersLoaded = false;
				}

				ControllerSearch.BUILD_TAG_FILTER = true;

				this.currentTabFiltersAssignEvents();
			}
		}

	}

	/**
	*
	* Destroy filters
	*/
	private destroyCurrentTabFilters(): void {
		if (this.filtersBox.length) {
			this.filtersBox.html("");
		}
	}

	/**
	*
	* Bind filter events
	*/
	private currentTabFiltersAssignEvents(): void {
		for (let i = 0; i < ControllerSearch.FILTERS.length; i++) {
			if (ControllerSearch.FILTERS[i].isActive()) {
				ControllerSearch.FILTERS[i].assignEvents();
			}
			// ControllerSearch.FILTERS[i].assignEvents();
		}

		if (ControllerSearch.BUILD_TAG_FILTER) {
			Collapse.init();
		}
	}

	/**
	*
	* Process current tab content
	*/
	private refreshCurrentTabContent(results: Array<Object>, doyoumean: Array<Object>): void {
		var current_tab_content_element = jQuery("#tab-" + this.tab + " .item_listing");
		if (current_tab_content_element.length) {

			var doyoumean_output = "";
			if (doyoumean != null && doyoumean.length > 0) {

				doyoumean_output += "<span class='doyoumean_headline'>" + this.getModule().getLabel("doyoumean_headline") + "</span>&nbsp;";
				for (var i = 0; i < 2; i++) {

					if (doyoumean.length >= i && doyoumean[i] !== this.query) {

						doyoumean_output += "<a class='doyoumean_value' " + Globals.ATTRIBUTE_PREFIX + "tag='" + doyoumean[i] + "'><i>" + doyoumean[i] + "</i></a>";
						if (i < 1 && doyoumean[i + 1] !== this.query) {
							doyoumean_output += ",&nbsp;";
						}

					}

				}

				current_tab_content_element.append(this.processOne("doyoumean_container", "content", doyoumean_output));
			}

			var items_output = "";
			var currentCategoriesContainer = jQuery("#currentCategories");
			var searchQueryStringContainer = jQuery("#search_query_string");
			if (results != null) {
				switch (this.tab) {
					case "shop":
						var items_tmp = this.processShop(results);
						if (items_tmp != null) {
							items_output = items_tmp;
						}
						if(currentCategoriesContainer) {
							currentCategoriesContainer.show();
						}
						if (searchQueryStringContainer) {
							searchQueryStringContainer.show();
						}
						break;
					case "information":
						var items_tmp = this.processInformation(results);
						if (items_tmp != null) {
							items_output = items_tmp;
						}
						if(currentCategoriesContainer) {
							currentCategoriesContainer.hide();
						}
						if (searchQueryStringContainer) {
							searchQueryStringContainer.hide();
						}
						break;
				}


				current_tab_content_element.append(items_output);

				if (this.tab == "shop" && this.tabShopDummyRows.length > 0) {
					for (let i = 0; i < this.tabShopDummyRows.length; i++) {
						this.tabShopDummyRows[i].remove();
						current_tab_content_element.append(this.tabShopDummyRows);
					}
				}
			}

		}
	}

	/**
	*
	* Build current tab structure
	*/
	private buildCurrentTabStructure(): void {
		var current_tab_element = jQuery("#tab-" + this.tab);
		if (current_tab_element.length) {

			var result = "";
			var modelID = this.getModel().new();
			let language = modules.getLanguageID();

			switch (this.tab) {
				case "shop":
					if ( this.translateWord !== "" ) {
						this.getModel().add(modelID, "query", this.realQuery);
					} else {
						this.getModel().add(modelID, "query", this.query);
					}
					this.getModel().add(modelID, "translateWord", this.translateWord);
					this.getModel().add(modelID, "hits", this.hits);
					this.getModel().add(modelID, "languageID", language);

					result += this.process(modelID, "tab_shop_structure");
					break;
				case "information":
					result += this.process(modelID, "tab_information_structure");
					break;
			}

			current_tab_element.html(result);

			this.orderbyBox = jQuery("#orderby");

		}
	}

	/**
	 * 
	 * build Search Query String
	 */
	private buildCurrentQueryString() {
		var element = jQuery("#search_query_string");
		if (element) {
			var result = "";
			var modelID = this.getModel().new();
			var language = modules.getLanguageID();

			if (this.translateWord !== "") {
				this.getModel().add(modelID, "query", this.realQuery);
			} else {
				var insertQuery=this.query;
				if (insertQuery==="")
					insertQuery= this.getModule().getLabel("search_results_all");
				this.getModel().add(modelID, "query", insertQuery);
			}
			this.getModel().add(modelID, "translateWord", this.translateWord);
			this.getModel().add(modelID, "hits", this.hits);
			this.getModel().add(modelID, "languageID", language);

			result += this.process(modelID, "tabs_searchQueryString_structure");

			element.html(result);

			this.orderbyBox = jQuery("#orderby");
		}
	}

	/**
	*
	* Process shop tab content
	*/
	public processShop(results: Array<Object>): string {
		if (results != null) {

			var html_content = "";
			let language = modules.getLanguageID();

			// Build structure for all products
			var link_article_details = this.getModule().getLabel("link_article_details");

			// Sync the data with shop data response.
			if (ControllerSearch.ARTICLE_SYNC) {
				results = this.articleUpdate(results);
			}

			for (var i = 0; i < results.length; i++) {

				// Create a new Model (JSON Object structure)
				var modelID = this.getModel().new();

				this.getModel().add(modelID, "url", Json.getSubobject(results[i], "url"));
				this.getModel().add(modelID, "title", Json.getSubobject(results[i], "title"));
				this.getModel().add(modelID, "image", Json.getSubobject(results[i], "picture"));
				this.getModel().add(modelID, "brand", Json.getSubobject(results[i], "brand"));
				this.getModel().add(modelID, "availability", Json.getSubobject(results[i], "availability"));
				this.getModel().add(modelID, "availability_status", Json.getSubobject(results[i], "availability_status"));
				this.getModel().add(modelID, "stock_quantity", Json.getSubobject(results[i], "stock_quantity"));
				this.getModel().add(modelID, "price", Json.getSubobject(results[i], "price"));
				this.getModel().add(modelID, "details_link", link_article_details);
				this.getModel().add(modelID, "amount", Json.getSubobject(results[i], "amount"));
				this.getModel().add(modelID, "bestmenge", Json.getSubobject(results[i], "bestmenge"));
				this.getModel().add(modelID, "unit", Json.getSubobject(results[i], "unit"));
				this.getModel().add(modelID, "article_number", Json.getSubobject(results[i], "articlenumber"));
				this.getModel().add(modelID, "info", Json.getSubobject(results[i], "info"));
				this.getModel().add(modelID, "info1", Json.getSubobject(results[i], "info1"));
				this.getModel().add(modelID, "info2", Json.getSubobject(results[i], "info2"));
				this.getModel().add(modelID, "info3", Json.getSubobject(results[i], "info3"));
				this.getModel().add(modelID, "info4", Json.getSubobject(results[i], "info4"));
				this.getModel().add(modelID, "info5", Json.getSubobject(results[i], "info5"));
				this.getModel().add(modelID, "info6", Json.getSubobject(results[i], "info6"));
				this.getModel().add(modelID, "info7", Json.getSubobject(results[i], "info7"));
				this.getModel().add(modelID, "rating", Json.getSubobject(results[i], "rating"));
				this.getModel().add(modelID, "statustype", Json.getSubobject(results[i], "statustype"));
				this.getModel().add(modelID, "bulkprices", Json.getSubobject(results[i], "bulkprices"));
				this.getModel().add(modelID, "cart_quantity", Json.getSubobject(results[i], "cart_quantity"));
				this.getModel().add(modelID, "cart_link", Json.getSubobject(results[i],"cart_link"));
				this.getModel().add(modelID, "artid", Json.getSubobject(results[i],"artid"));
				this.getModel().add(modelID, "vpe", Json.getSubobject(results[i],"vpe"));
				this.getModel().add(modelID, "customerid", Json.getSubobject(results[i],"customerid"));
				this.getModel().add(modelID, "showprice", Json.getSubobject(results[i],"showprice"));
				this.getModel().add(modelID, "languageID", language);
				this.getModel().add(modelID, "translateWord", this.translateWord);
				this.getModel().add(modelID, "basisprice", Json.getSubobject(results[i],"basisprice"));
				this.getModel().add(modelID, "basispriceunit", Json.getSubobject(results[i],"basispriceunit"));
				this.getModel().add(modelID, "rank", Json.getSubobject(results[i], "rank"));
				this.getModel().add(modelID, "base_price", Json.getSubobject(results[i], "base_price"));

				// If new values have been found, then the old values will be in the old-node.
				if (Json.getSubobject(results[i], "old") != null) {
					this.getModel().add(modelID, "old", Json.getSubobject(results[i], "old"));
				}

				// Process the row
				html_content += this.process(modelID, "tab_shop_row");

			}

			return html_content;
		} else {
			return null;
		}
	}

	private articleUpdate(results: Array<Object>): Array<Object> {
		// Build the article number request
		var article_numbers = []; // Request articlenumbers
		for (var i = 0; i < results.length; i++) {
			article_numbers.push(Json.getSubobject(results[i], "articlenumber"));
		}
		var article_sync_data = Json.getSubobject(this.getModel().getArticleSyncData(article_numbers), "arts"); // Request

		/**
		 * Überprüft, ob die Sync Response ungleich der Such response ist
		 * WENN JA => wird der Artikel der in der Sync Response nicht vorhanden ist aus der Such Response entfernt
		 */
		if (article_sync_data.length !== results.length) {
			for (var i = 0; i < results.length; i++) {
				var artNr = Json.getSubobject(results[i], "articlenumber");
				var indexOfArt = article_sync_data.findIndex((art:any) => art.artnr === artNr);

				/**
				 * //TODO:  schauen warum bei Supplementa artikel entfernt werden,
				 * obwohl die ArtNr nicht gleicht ist!
				 */
				if (indexOfArt == -1) {
					results.splice(i, 1);
					i-=1;
				}
			}
		}

		if (ControllerSearch.SELECTED_CATEGORIES.length >= 2) {
			this.currentShopHits = results.length;
		} else {
			this.currentShopHits = this.hits;
		}

		if (article_sync_data != null) {

			// Loop through the article sync response
			for (var i = 0; i < article_sync_data.length; i++) {

				// Loop through the current results and set the new values or add it
				for (var j = 0; j < results.length; j++) {

					if (Json.getSubobject(article_sync_data[i], "artnr") == Json.getSubobject(results[j], "articlenumber"))  //Check if the current article number matches with the article_sync article number
					{
						for (let k = 0; k < ControllerSearch.ARTICLE_SYNC_ITEMS.length; k++) // Loop through all keys to replace the old value with the new 
						{
							 // Save the current value to use in in the articel list: old.[key]
							let sync_article_key:string=ControllerSearch.ARTICLE_SYNC_ITEMS[k].getKey();
							let sync_article_value:any=ControllerSearch.ARTICLE_SYNC_ITEMS[k].getValue();
							var tempObj:any=results[j];
							// console.log(tempObj);
							let snyc_update:any=tempObj[sync_article_key];
							// console.log(snyc_update);
							if (snyc_update) {
								// Remember the old value
								Json.setSubobject(results[j], "old." + sync_article_key, Json.getSubobject(results[j], sync_article_key));
								// Replace the current value with the new value 
								Json.setSubobject(results[j], sync_article_key, Json.getSubobject(article_sync_data[i], sync_article_value));
							}
							else {
								let insertValue:any=Json.getSubobject(article_sync_data[i], sync_article_key);

								results[j][sync_article_key]=insertValue;
							}

						}
					}
				}
			}
		}

		return results;
	}

	/**
	*
	* Process information tab content
	*/
	private processInformation(results: Array<Object>): string {
		if (results != null) {

			var html_content: string = "";

			/**
			*
			* Build structure for all informations
			*/
			for (var i = 0; i < results.length; i++) {
				var current_row = "";

				/**
				*
				* Build description
				*/
				let description = Json.getSubobject(results[i], "description");
				let description_out = "";

				if (ControllerSearch.TAB_INFORMATION_DISPLAY_DESCRIPTION) {
					if (description !== "") {

						description_out += this.processOne("tab_information_content_container_description", "description", description);
					}
				}

				/**
				*
				* Build keywords
				*/
				var keywords_out = "";
				if (ControllerSearch.TAB_INFORMATION_DISPLAY_KEYWORDS) {

					var keywords = Json.getSubobject(results[i], "keywords.keyword");
					keywords = Json.convertObjectToArray(keywords);

					for (var j = 0; j < 6; j++) {
						if (keywords.length - 1 >= j && keywords[j] != "") {
							var modelID = this.getModel().new();

							this.getModel().add(modelID, "icon", findIcon("keyword"));
							this.getModel().add(modelID, "keyword", keywords[j]);

							keywords_out += this.process(modelID, "tab_information_content_row_keywords");
						}
					}

					if (keywords_out != "") {
						keywords_out = this.processOne("tab_information_content_container_keywords", "content", keywords_out);
					}

				}

				/**
				*
				* Check if both keywords and descriptions are set
				*/
				if (keywords_out == "" && description_out == "") {
					this.processOne("tab_information_content_container_content", "content", Json.getSubobject(results[i], "content"));
				} else {
					current_row = description_out + keywords_out;
				}

				var modelID = this.getModel().new();

				this.getModel().add(modelID, "url", Json.getSubobject(results[i], "url"));
				this.getModel().add(modelID, "title", Json.getSubobject(results[i], "title"));
				this.getModel().add(modelID, "rating", Json.getSubobject(results[i], "score"));
				this.getModel().add(modelID, "content", current_row);

				html_content += this.process(modelID, "tab_information_content_structure");
			}

			return html_content;
		} else {
			return null;
		}
	}

	/**
	*
	* Show or hide next page button
	*/
	public buildCurrentTabAutoReload(): void {
		if (this.nextPageButton.length) {

			if (this.page && this.pages && this.page + 1 > this.pages) {
				this.nextPageButton.hide();
			} else {
				this.nextPageButton.show();
			}

		}
	}

	/**
	*
	* Set the current tab hits
	*/
	private setCurrentTabHits(): void {
		if (this.hits !== null) {
			var current_tab_hits_element = jQuery("[" + Globals.ATTRIBUTE_PREFIX + "tab-hits='" + this.tab + "']");
			if (current_tab_hits_element.length) {

				current_tab_hits_element.html(this.hits.toString());

			}
		}
	}

	/**
	*
	* Set tabs hits
	*/
	private setTabsHits(tabs: Array<Object>): void {
		if (this.tabs != null) {
			var tabs_hits_elements = jQuery("[" + Globals.ATTRIBUTE_PREFIX + "tab-hits]");
			for (var i = 0; i < tabs_hits_elements.length; i++) {

				for (var j = 0; j < tabs.length; j++) {

					var tab = Json.getSubobject(tabs[j], "tab");
					var hits;

					if (this.currentShopHits !== null && tab == "shop") {
						hits = this.currentShopHits;
					} else {
						hits = Json.getSubobject(tabs[j], "hits");
					}

					if (tab == jQuery(tabs_hits_elements[i]).attr("" + Globals.ATTRIBUTE_PREFIX + "tab-hits")) {
						jQuery(tabs_hits_elements[i]).html(hits);
					}

				}
			}
		}
	}

	/**
	*
	* Assign the static events
	*/
	private assignStaticEvents(): void {


		/**
		*
		* Init tab container as jQuery UI tabs
		* Set on tab change event
		*/
		this.tabs.tabs({
			beforeActivate: function (event: Event, ui: JQueryUI.TabsActivationUIParams) { // On change event
				var new_tab_tab = ui.newTab.attr(Globals.ATTRIBUTE_PREFIX + "tab");
				var new_tab_type = ui.newTab.attr(Globals.ATTRIBUTE_PREFIX + "type");

				if (typeof new_tab_tab !== "undefined" && typeof new_tab_type !== "undefined") {
					this.tab = new_tab_tab;
					this.type = new_tab_type;

					this.onTabChange();
				}
			}.bind(this)
		});


		if (ControllerSearch.AUTO_SCROLL) {
			/**
			*
			* Display the new page if the user scrolled to the of the page
			*/
			// TODO: alte Funktion austauschen
			// jQuery(window).on("scroll", function() {
			// 	if (ControllerSearch.AUTO_SCROLL_FIRST_CLICK && this.activatedAutoScrolling) {

			// 	}
			// 	var last_item = jQuery(".item_listing *").last();
			// 	if (last_item.length) {
					
			// 		var page_end = jQuery(window).height();
			// 		var last_item_pos_relative_to_window = last_item.offset().top - jQuery(window).scrollTop();

			// 		/**
			// 		*
			// 		* Fires if the distance between the last_item_pos and the page_height is higher than 400px
			// 		*/
			// 		if (last_item_pos_relative_to_window + ControllerSearch.SCROLL_DISTANCE_TO_BOTTOM <= page_end) {
			// 			if (this.page + 1 <= this.pages) {
			// 				this.page++;
			// 				this.refresh_content();
			// 			}
			// 		}
			// 	}
			// }.bind(this));

			jQuery(window).scroll(function () {
				if (ControllerSearch.AUTO_SCROLL_FIRST_CLICK && this.activatedAutoScrolling) {

				}
				var last_item = jQuery(".item_listing *").last();
				if (last_item.length) {

					var page_end = jQuery(window).height();
					var last_item_pos_relative_to_window = last_item.offset().top - jQuery(window).scrollTop();

					/**
					*
					* Fires if the distance between the last_item_pos and the page_height is highter than 400px
					*/
					if (last_item_pos_relative_to_window + ControllerSearch.SCROLL_DISTANCE_TO_BOTTOM <= page_end) {
						if (this.page + 1 <= this.pages) {
							this.page++;
							this.refresh_content();
						}
					}

				}
			}.bind(this));
		}

		/**
		*
		* Next page button click event
		*/
		if (this.nextPageButton.length) {

			this.nextPageButton.on("click", function () {
				if (this.page + 1 <= this.pages) {
					this.page++;
					let filter_viewtype=this.getModule().getConfig("filter_viewtype");
					if (filter_viewtype === "1") {
						this.refresh_content();
					} else {
						if (this.tab==="shop") {
							ControllerSearch.FILTER_BOX_COMPONENT.loadNextPage();
						} else {
							this.refresh_content();
						}
					}
				}
			}.bind(this));

		}

		this.assignTabChangeEvents();
		this.assignContentChangeEvents();

		this.assignedEvents = true;
	}

	/**
	*
	* Assign the dynamic events
	*/
	private assignContentChangeEvents(): void {
		/**
		*
		* Search by tag
		*/
		var tags = jQuery("[" + Globals.ATTRIBUTE_PREFIX + "tag]");
		for (var i = 0; i < tags.length; i++) {
			tags[i].addEventListener("click", function (event: any) {
				this.query = jQuery(event.currentTarget).attr("" + Globals.ATTRIBUTE_PREFIX + "tag");

				this.refreshAll();
				this.onContentChange();

				modules.callMethod("Autocomplete", "set_keyword", [this.query]);
				jQuery("body, html").animate({ scrollTop: 0 }, 1000);
			}.bind(this));
		}

		/**
		*
		* Change the products order
		*/
		jQuery("#orderby_select").on("change", function (e: React.FormEvent<HTMLSelectElement>) {


			this.currentOrderByItem = e.currentTarget.options[e.currentTarget.selectedIndex].getAttribute("" + Globals.ATTRIBUTE_PREFIX + "orderby");
			this.refreshAll();
			this.onContentChange();
			this.reInitNumberInput();
		}.bind(this));
	}

	/**
	*
	* Assign the dynamic events
	*/
	private assignTabChangeEvents(): void {

	}

	public onContentChange(): void {
		this.assignContentChangeEvents();

		this.getModule().executeEvent("content_change");
	}
	/**
	*
	* Change the tab
	*/
	public onTabChange(): void {
		this.filtersLoaded = true;

		this.destroyCurrentTabFilters();

		this.refreshAll();

		this.assignTabChangeEvents();
		this.onContentChange();

		this.getModule().executeEvent("tab_change");
		Collapse.init();

		if (this.initNumberInput) {
			if ( this.tab == "shop" ) {
				this.reInitNumberInput();
			}
		}
	}

	public getCaspardoQuery(): string {
		return ControllerSearch.CASPARDO_QUERY;
	}

	public getCaspardoBrand(): string {
		return ControllerSearch.CASPARDO_BRAND;
	}

	private sortTabs(paramOne: any, paramTwo: any): number {
		const rankOne = paramOne.rank;
		const rankTwo = paramTwo.rank;

		let comparison = 0;

		if ( rankOne > rankTwo ) {
			comparison = 1;
		} else if ( rankOne < rankTwo ) {
			comparison = -1;
		}

		return comparison;
	}

	private sortByKey(array: Array<Object>, key: string, format: string): Array<Object> {
		if ( format == "desc" ) {
			return array.sort(function(a: any, b: any) {
				let x = a[key]; let y = b[key];
				return ( (x < y) ? -1 : (x > y) ? 1 : 0 );
			});
		} else if(format == "asc") {
			return array.sort(function(a: any, b: any) {
				let x = b[key]; let y = a[key];
				return ( (x < y) ? -1 : (x > y) ? 1 : 0 );
			});
		}
	}

	private reInitNumberInput(): void {
		if (this.initNumberInput) {
			let configuration = Json.getSubobject(modules.modulesConfiguration, "NumberInput");
			if ( configuration ) {
				let moduleNumberInput = new ModuleNumberInput(configuration);
				moduleNumberInput.run();
			}
		}
	}

	private processTags( filters:Array<Object> ): Array<Object> {
		var filterOrder = this.getModule().getConfig('filter_order');
		var tagsObj = {} as any;
			tagsObj.name = "tag";
			tagsObj.type = "tag";
			tagsObj.value = [];

		for ( var i = 0; i < filters.length; i++ ) {

			var name = Json.getSubobject(filters[i], "name");
			var value = Json.getSubobject(filters[i], "value");
			var tagType = Json.getSubobject(filters[i], "type");
			/**
			 * 
			 * Check if the filter is disabled
			 */
			if (ControllerSearch.DISABLED_FILTERS.indexOf(name) == -1) {
				if (tagType == "tag") {
					var currentTagObj = {} as any;
						currentTagObj.name = name;
						currentTagObj.id = i;
						currentTagObj.value = value;
					tagsObj.value.push(currentTagObj);
				}
			}
		}

		filters.push(tagsObj);

		if (filterOrder) {
			let tempArr = [];
			for (var i = 0; i < filterOrder.length; i++) {
				let tempResult: any = filters.filter( (obj:any) => {
						return obj.name === filterOrder[i];
					});
				if (tempResult) {
					tempArr.push(tempResult[0]);
				}
			}
			return tempArr;
		} else {
			return filters;
		}
	}
}