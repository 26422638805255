import { Controller } from "./../../classes/mvc/Controller";
import { ModelUIDChecker } from "./ModelUIDChecker";
import { Checker } from "./Checker";

export class ControllerUIDChecker extends Controller<ModelUIDChecker> {

    private myUID:string;
    private checkUID:string;

    private static URL:string = "/uidChecker/evatrRPC?Firmenname=&Ort=&PLZ=&Strasse=&Druck=";
    private static ErrorCodeMapping:Array<string> = new Array();

    public initGlobals ():void{
        
    }

    public constructor ( accessName:string, accessID:number ){
        super( new ModelUIDChecker(), accessName, accessID );

        ControllerUIDChecker.ErrorCodeMapping[200] = "Die angefragte USt-IdNr. ist gültig.";
        ControllerUIDChecker.ErrorCodeMapping[201] = "Die angefragte USt-IdNr. ist ungültig.";
        ControllerUIDChecker.ErrorCodeMapping[202] = "Die angefragte USt-IdNr. ist ungültig. Sie ist nicht in der Unternehmerdatei des betreffenden EU-Mitgliedstaates registriert. Hinweis: Ihr Geschäftspartner kann seine gültige USt-IdNr. bei der für ihn zuständigen Finanzbehörde in Erfahrung bringen. Möglicherweise muss er einen Antrag stellen, damit seine USt-IdNr. in die Datenbank aufgenommen wird.";
        ControllerUIDChecker.ErrorCodeMapping[203] = "Die angefragte USt-IdNr. ist ungültig. Sie ist erst ab dem ... gültig (siehe Feld 'Gueltig_ab').";
        ControllerUIDChecker.ErrorCodeMapping[204] = "Die angefragte USt-IdNr. ist ungültig. Sie war im Zeitraum von ... bis ... gültig (siehe Feld 'Gueltig_ab' und 'Gueltig_bis').";
        ControllerUIDChecker.ErrorCodeMapping[205] = "Ihre Anfrage kann derzeit durch den angefragten EU-Mitgliedstaat oder aus anderen Gründen nicht beantwortet werden. Bitte versuchen Sie es später noch einmal. Bei wiederholten Problemen wenden Sie sich bitte an das Bundeszentralamt für Steuern - Dienstsitz Saarlouis.";
        ControllerUIDChecker.ErrorCodeMapping[206] = "Ihre deutsche USt-IdNr. ist ungültig. Eine Bestätigungsanfrage ist daher nicht möglich. Den Grund hierfür können Sie beim Bundeszentralamt für Steuern - Dienstsitz Saarlouis - erfragen.";
        ControllerUIDChecker.ErrorCodeMapping[207] = "Ihnen wurde die deutsche USt-IdNr. ausschliesslich zu Zwecken der Besteuerung des innergemeinschaftlichen Erwerbs erteilt. Sie sind somit nicht berechtigt, Bestätigungsanfragen zu stellen.";
        ControllerUIDChecker.ErrorCodeMapping[208] = "Für die von Ihnen angefragte USt-IdNr. läuft gerade eine Anfrage von einem anderen Nutzer. Eine Bearbeitung ist daher nicht möglich. Bitte versuchen Sie es später noch einmal.";
        ControllerUIDChecker.ErrorCodeMapping[209] = "Die angefragte USt-IdNr. ist ungültig. Sie entspricht nicht dem Aufbau der für diesen EU-Mitgliedstaat gilt. ( Aufbau der USt-IdNr. aller EU-Länder)";
        ControllerUIDChecker.ErrorCodeMapping[210] = "Die angefragte USt-IdNr. ist ungültig. Sie entspricht nicht den Prüfziffernregeln die für diesen EU-Mitgliedstaat gelten.";
        ControllerUIDChecker.ErrorCodeMapping[211] = "Die angefragte USt-IdNr. ist ungültig. Sie enthält unzulässige Zeichen (wie z.B. Leerzeichen oder Punkt oder Bindestrich usw.).";
        ControllerUIDChecker.ErrorCodeMapping[212] = "Die angefragte USt-IdNr. ist ungültig. Sie enthält ein unzulässiges Länderkennzeichen.";
        ControllerUIDChecker.ErrorCodeMapping[213] = "Die Abfrage einer deutschen USt-IdNr. ist nicht möglich.";
        ControllerUIDChecker.ErrorCodeMapping[214] = "Ihre deutsche USt-IdNr. ist fehlerhaft. Sie beginnt mit 'DE' gefolgt von 9 Ziffern.";
        ControllerUIDChecker.ErrorCodeMapping[215] = "Ihre Anfrage enthält nicht alle notwendigen Angaben für eine einfache Bestätigungsanfrage (Ihre deutsche USt-IdNr. und die ausl. USt-IdNr.). Ihre Anfrage kann deshalb nicht bearbeitet werden.";
        ControllerUIDChecker.ErrorCodeMapping[216] = "Ihre Anfrage enthält nicht alle notwendigen Angaben für eine qualifizierte Bestätigungsanfrage (Ihre deutsche USt-IdNr., die ausl. USt-IdNr., Firmenname einschl. Rechtsform und Ort). Es wurde eine einfache Bestätigungsanfrage durchgeführt mit folgenden Ergebnis: Die angefragte USt-IdNr. ist gültig.";
        ControllerUIDChecker.ErrorCodeMapping[217] = "Bei der Verarbeitung der Daten aus dem angefragten EU-Mitgliedstaat ist ein Fehler aufgetreten. Ihre Anfrage kann deshalb nicht bearbeitet werden.";
        ControllerUIDChecker.ErrorCodeMapping[218] = "Eine qualifizierte Bestätigung ist zur Zeit nicht möglich. Es wurde eine einfache Bestätigungsanfrage mit folgendem Ergebnis durchgeführt: Die angefragte USt-IdNr. ist gültig.";
        ControllerUIDChecker.ErrorCodeMapping[219] = "Bei der Durchführung der qualifizierten Bestätigungsanfrage ist ein Fehler aufgetreten. Es wurde eine einfache Bestätigungsanfrage mit folgendem Ergebnis durchgeführt: Die angefragte USt-IdNr. ist gültig.";
        ControllerUIDChecker.ErrorCodeMapping[220] = "Bei der Anforderung der amtlichen Bestätigungsmitteilung ist ein Fehler aufgetreten. Sie werden kein Schreiben erhalten.";
        ControllerUIDChecker.ErrorCodeMapping[221] = "Die Anfragedaten enthalten nicht alle notwendigen Parameter oder einen ungültigen Datentyp. Weitere Informationen erhalten Sie bei den Hinweisen zum Schnittstelle - Aufruf.";
        ControllerUIDChecker.ErrorCodeMapping[999] = "Eine Bearbeitung Ihrer Anfrage ist zurzeit nicht möglich. Bitte versuchen Sie es später noch einmal.";
    }

    public run ():void{}

    public create (myUID:string, checkUID:string):Checker {
        if ( myUID != null && checkUID != null ){
            this.myUID = myUID;
            this.checkUID = checkUID;
        }

        var document:Document = this.getModel().getResult( ControllerUIDChecker.URL, this.myUID, this.checkUID );
        var errorCode:number = 201;

        var parameters:any = document.getElementsByTagName( "params" )[0].children;
        for (let i = 0; i < parameters.length; i++) {
            var key:string = parameters[i].children[0].children[0].children[0].children[0].children[0].textContent;
            var value:number = Number( parameters[i].children[0].children[0].children[0].children[1].children[0].textContent );

            if ( key == "ErrorCode" && !isNaN( value ) ){
                errorCode = value;
                break;
            }
        }
        
        return new Checker( errorCode );
    }

}