import { Module } from "./../../classes/mvc/Module";
import { ControllerUIDChecker } from "./ControllerUIDChecker";
import { ModelUIDChecker } from "./ModelUIDChecker";
import { Checker } from "./Checker";

export class ModuleUIDChecker extends Module<ModelUIDChecker> {

    public constructor ( configuration:Object ){
        super( "UIDChecker", configuration );

        this.registerCallableMethod( this, "check", this.check );
    }

    public run (){}

    public onControllersInitialized ():void {
       
    }

    public check( ...args:any[] ):Checker {
        if ( args.length > 1 ){
            var myUID = args[0];
            var checkUID = args[1];

            var controller:ControllerUIDChecker = new ControllerUIDChecker( this.getName(), this.getAccessID() );
            this.addController( controller );
    
            return controller.create( myUID, checkUID );
        } else {
            return new Checker( 201 );
        }
       
    }

}