
export class HTML {

    // Methode adds one html class to the element with the selector
    // html_selector : ".class_selector" or "#id_selector"
    public static addClass(html_selector: string, html_element_class: string): void {
        let element: any = null;
        let arr: any = [];

        let isIDSelector: boolean = (html_selector.indexOf("#") >= 0);
        let isClassSelector: boolean = (html_selector.indexOf(".") >= 0);

        if (isIDSelector) {
            html_selector = html_selector.substring(1, html_selector.length);
            element = document.getElementById(html_selector);
            arr = element.className.split(" ");
            if (arr.indexOf(html_element_class) == -1) {
                element.className += " " + html_element_class;
            }
        }

        if (isClassSelector) {
            html_selector = html_selector.substring(1, html_selector.length);
            var arHTMLElements = document.getElementsByClassName(html_selector);
            for (var i = 0; i < arHTMLElements.length; i++) {
                let ar_classes = arHTMLElements[i].className.split(" ");
                if (ar_classes.indexOf(html_selector) == -1) {
                    arHTMLElements[i].className += " " + html_element_class;
                }
            }
        }
    }

    // Methode adds one html class to the element with the selector
    // html_selector : ".class_selector" or "#id_selector"
    public static addClassToElement(html_element: HTMLElement, html_element_class: string): void {
        let arr = html_element.className.split(" ");
        if (arr.indexOf(html_element_class) == -1) {
            html_element.className += " " + html_element_class;
        }
    }

    // Methode adds one html class to the element with the selector
    // html_selector : ".class_selector" or "#id_selector"
    public static removeClassFromElement(html_element: HTMLElement, html_element_class: string): void {
        let arr = html_element.className.split(" ");
        let res:string="";
        if (arr.indexOf(html_element_class) >= 0) {
            for (var i = 0; i < arr.length; i++) {
                let className:string = arr[i];
                console.log(className);
                if (className != html_element_class) {
                    res += " " + className;
                }
            }
            html_element.className = res;
        }
    }



}
