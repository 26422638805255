import { Module } from "./../../classes/mvc/Module";
import { ControllerMessageBox } from "./ControllerMessageBox";
import { ModelMessageBox } from "./ModelMessageBox";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";

@ModuleConfig({
    moduleName: "MessageBox",
    style: "message_box.scss"
})
export class ModuleMessageBox extends Module<ModelMessageBox> {

    public constructor ( configuration:Object ){
        super( "MessageBox", configuration, true );

        this.addController( new ControllerMessageBox( this.getName(), this.getAccessID() ) );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

}