import { Model } from "./../../classes/mvc/Model";
import { AjaxRequest } from "./../../libs/AjaxRequest";

export class ModelNavigationSync extends Model{

    public constructor (){
        super();
    }

    public getSearchResults ( url:string ):Object{
		return AjaxRequest.getJson( url );
	}
    
}