
import Handlebars = require( "handlebars" );
import {ControllerSearch} from "../ControllerSearch";
import {Collapse} from "../Components/Collapse";
import {HTML} from "../../../libs/HTML";

export class FilterBox {

    private _selectedFilterTemplate:string="";
    private _filterData:any;

    private _controller:ControllerSearch=null;
    private _html_template:string="";
    private _selectedNavFilter:any=[];
    private _selectedNavFilterIDSelektor:any=[];
    private _selectedTagFilter:any=[];
    private _selectedTagFilterIDSelektor:any=[];
    private _selectedBrandFilter:any=[];
    private _selectedBrandFilterIDSelektor:any=[];

    private _counter_navFilter=0;
    private _counter_tagFilter=0;
    private _counter_brandFilter=0;

    private _template_filter_status="";

    constructor(controllerSearch: ControllerSearch, template:string, template_selected_filter:string, template_filter_status:string){
        this._controller=controllerSearch;
        this._html_template=template;
        this._selectedFilterTemplate=template_selected_filter;
        this._template_filter_status=template_filter_status;
    }

    public renderHTML(data:any){
        this._filterData=data;
        let handlebarTemplate=Handlebars.compile(this._html_template);
        let res:string=handlebarTemplate(data);
        let htmlElement_filterBox=document.getElementById("filters_box");
        htmlElement_filterBox.innerHTML=res;

        // Add nav-filters events
        var navFilters=document.getElementsByClassName("ez-nav-filter");
        for (var i=0; i<navFilters.length;i++){
            navFilters[i].addEventListener("click", evt => this.executeNavFilter(evt));
        }
        this._counter_navFilter=navFilters.length;

        // Add tag filter events
        var navTagFilters=document.getElementsByClassName("ez-tag-filter");
        for (var i=0; i<navTagFilters.length;i++){
            navTagFilters[i].addEventListener("click", evt => this.executeTagFilter(evt));
        }
        this._counter_tagFilter=navTagFilters.length;

        // Add brand filter events
        var brandFilters=document.getElementsByClassName("ez-brand-filter");
        for (var i=0; i<brandFilters.length;i++){
            brandFilters[i].addEventListener("click", evt => this.executeBrandFilter(evt));
        }
        this._counter_brandFilter=brandFilters.length;
        // Collapse Elements
        var categoryCollapse:Collapse=new Collapse("filter_category_collapse","filter_category_collapse_area","icon_plus","icon_minus");
        categoryCollapse.init();

        var tagCollapse:Collapse=new Collapse("filter_tag_collapse","filter_tag_area","icon_plus","icon_minus");
        tagCollapse.init();

        var brandCollapse:Collapse=new Collapse("filter_brand_collapse","filter_brand_area","icon_plus","icon_minus");
        brandCollapse.init();

        this.setCategoryFilterInfo();
        this.setTagFilterInfo();
        this.setBrandFilterInfo();
    }


    // execute click on nav-filter
    public executeNavFilter(evt:any): void{
        if (evt.target){
            let node_category=$(evt.target).data("ez-filter-nodecategory");
            let node_rootcategory=$(evt.target).data("ez-filter-noderootcategory");
            let node_rootcategoryid=$(evt.target).data("ez-filter-noderootcategoryid");

            let html_checkbox_idselector=$(evt.target).attr("id");
            if (evt.target.checked)
            {
                this._selectedNavFilter.push(node_category);
                this._selectedNavFilterIDSelektor.push(html_checkbox_idselector);
                if (node_rootcategory){
                    let pos=this._selectedNavFilter.indexOf(node_rootcategory);
                    if (pos<0)
                    {
                        this._selectedNavFilter.push(node_rootcategory);
                        this._selectedNavFilterIDSelektor.push(node_rootcategoryid);
                        let elementHTMLRoot=<HTMLInputElement>document.getElementById(node_rootcategoryid);
                        elementHTMLRoot.checked = true;
                    }
                }

            }else
            {
                if (node_rootcategory){
                    let pos=this._selectedNavFilter.indexOf(node_rootcategory);
                    if (pos>=0)
                    {
                        this._selectedNavFilter.splice(pos,1);
                        this._selectedNavFilterIDSelektor.splice(pos,1);
                        let elementHTMLRoot=<HTMLInputElement>document.getElementById(node_rootcategoryid);
                        elementHTMLRoot.checked = false;
                    }
                }
                let pos=this._selectedNavFilter.indexOf(node_category);
                this._selectedNavFilter.splice(pos,1);
                this._selectedNavFilterIDSelektor.splice(pos,1);
            }

            this.buildSelectedFilterPanel();

            // Process the refresh
            let filterQuery=this.getCategoryQuery();
            var result:any = this.refreshShopResults(filterQuery);

            // Process activate + deactivate Filters

            // Get all active filters:
            let res_filters=result.searchresult.results.domain.filters.filter;
            let ar_filtersActive:any=[];
            let ar_tagFiltersActive:any=[];
            let ar_brandFiltersActive:any=[];

            for (let i=0;i<res_filters.length;i++){
                let filter_element=res_filters[i];
                // process the nav-filter
                if (filter_element.type==="nav"){
                    if (!(res_filters[i].node instanceof Array)){
                        var tempArr=[];
                        tempArr.push(res_filters[i].node);
                        res_filters[i].node=tempArr;
                    }
                    let ar_filter_nodes=res_filters[i].node;
                    for (let j=0;j<ar_filter_nodes.length;j++){
                        ar_filtersActive.push(ar_filter_nodes[j].item.name);
                        // Loop the second level of nodes
                        if (ar_filter_nodes[j].childs.node)
                        {
                            var tEle=ar_filter_nodes[j].childs.node;
                            if (!(ar_filter_nodes[j].childs.node instanceof Array)){
                                var tempArr=[];
                                tempArr.push(ar_filter_nodes[j].childs.node);
                                ar_filter_nodes[j].childs.node=tempArr;
                            }
                            for (let k=0;k<ar_filter_nodes[j].childs.node.length;k++){
                                ar_filtersActive.push(ar_filter_nodes[j].childs.node[k].name);
                            }
                        }
                    }
                }
                // process the tag-filter
                if (filter_element.type==="tag"){
                    filter_element.value=this.ensureArray(filter_element.value);
                    for (let k=0;k<filter_element.value.length;k++){
                        ar_tagFiltersActive.push(filter_element.value[k]);
                    }
                }
                if (filter_element.type==="brand"){
                    filter_element.value=this.ensureArray(filter_element.value);
                    for (let k=0;k<filter_element.value.length;k++){
                        ar_brandFiltersActive.push(filter_element.value[k]);
                    }
                }
            }

            // Process the nav filter
            var navFilters=document.getElementsByClassName("ez-nav-filter");
            for (var i=0; i<navFilters.length;i++){
                var currentElement:HTMLElement=(<any>navFilters[i]);
                var filter_category=currentElement.getAttribute("data-ez-filter-nodecategory");
                let pos=ar_filtersActive.indexOf(filter_category);
                if ((<any>navFilters[i]).checked)
                    (<any>navFilters[i]).disabled=false;
                else{
                    if (pos<0)
                        (<any>navFilters[i]).disabled=true;
                    else
                        (<any>navFilters[i]).disabled=false;
                }
            }

            // Process the tag filter
            var tagFilters=document.getElementsByClassName("ez-tag-filter");
            this.processTagFilterStatus(tagFilters,ar_tagFiltersActive);

            var brandFilters=document.getElementsByClassName("ez-brand-filter");
            this.processBrandFilterStatus(brandFilters,ar_brandFiltersActive);
        }
    }

    // Process tag filter and set deactivate/activate the tag-filters checkboxes
    public processTagFilterStatus(tagFilters:any, ar_tagFiltersActive:any):void{
        for (var i=0; i<tagFilters.length;i++){
            var currentElement:HTMLElement=(<any>tagFilters[i]);
            var filter_tag=currentElement.getAttribute("data-ez-tagfilter-value");
            var tag:any=null;
            var item1 = ar_tagFiltersActive.find(tag => tag.name === filter_tag);
            if ((<any>tagFilters[i]).checked)
                (<any>tagFilters[i]).disabled=false;
            else{
                if (!item1)
                    (<any>tagFilters[i]).disabled=true;
                else
                    (<any>tagFilters[i]).disabled=false;
            }
        }
    }
    

    // execute click on tag-filter
    public executeTagFilter(evt:any): void{
        if (evt.target){
            let tag_filter_value=$(evt.target).data("ez-tagfilter-value");
            let html_checkbox_idselector=$(evt.target).attr("id");
            if (evt.target.checked)
            {
                this._selectedTagFilter.push(tag_filter_value);
                this._selectedTagFilterIDSelektor.push(html_checkbox_idselector);
            }else
            {
                let pos=this._selectedTagFilter.indexOf(tag_filter_value);
                this._selectedTagFilter.splice(pos,1);
                this._selectedTagFilterIDSelektor.splice(pos,1);
            }

            this.buildSelectedFilterPanel();
            var filterQuery=this.getCategoryQuery();
            filterQuery+=this.getTagQuery();
            var result:any = this.refreshShopResults(filterQuery);

            // Get all active filters:
            let res_filters=result.searchresult.results.domain.filters.filter;
            let ar_tagFiltersActive:any=[];
            let ar_brandFiltersActive:any=[];
            for (let i=0;i<res_filters.length;i++){
                let filter_element=res_filters[i];

                // process the tag-filter
                if (filter_element.type==="tag"){
                    filter_element.value=this.ensureArray(filter_element.value);
                    for (let k=0;k<filter_element.value.length;k++){
                        ar_tagFiltersActive.push(filter_element.value[k]);
                    }
                }
                // process the brand-filter
                if (filter_element.type==="brand"){
                    filter_element.value=this.ensureArray(filter_element.value);
                    for (let k=0;k<filter_element.value.length;k++){
                        ar_brandFiltersActive.push(filter_element.value[k]);
                    }
                }
             }

            // Process the tag filter
            var tagFilters=document.getElementsByClassName("ez-tag-filter");
            this.processTagFilterStatus(tagFilters,ar_tagFiltersActive);

            var brandFilters=document.getElementsByClassName("ez-brand-filter");
            this.processBrandFilterStatus(brandFilters,ar_brandFiltersActive);
        }
    }

    // Process tag filter and set deactivate/activate the tag-filters checkboxes
    public processBrandFilterStatus(brandFilters:any, ar_brandFiltersActive:any):void{
        for (var i=0; i<brandFilters.length;i++){
            var currentElement:HTMLElement=(<any>brandFilters[i]);
            var filter_brand=currentElement.getAttribute("data-ez-brandfilter-value");
            var brand:any=null;
            var item1 = ar_brandFiltersActive.find(brand => brand.name === filter_brand);
            if ((<any>brandFilters[i]).checked)
                (<any>brandFilters[i]).disabled=false;
            else{
                if (!item1)
                    (<any>brandFilters[i]).disabled=true;
                else
                    (<any>brandFilters[i]).disabled=false;
            }
        }
    }

    // Process tag filter and set deactivate/activate the tag-filters checkboxes
    public executeBrandFilter(evt:any):void{
        let brand_filter_value=$(evt.target).data("ez-brandfilter-value");
        let html_checkbox_idselector=$(evt.target).attr("id");
        if (evt.target.checked){
            this._selectedBrandFilter.push(brand_filter_value);
            this._selectedBrandFilterIDSelektor.push(html_checkbox_idselector);
        }else{
            let pos=this._selectedBrandFilter.indexOf(brand_filter_value);

            this._selectedBrandFilter.splice(pos,1);
            this._selectedBrandFilterIDSelektor.splice(pos,1);

        }
        this.buildSelectedFilterPanel();
        var filterQuery=this.getCategoryQuery();
        filterQuery+=this.getTagQuery();
        filterQuery+=this.getBrandQuery();
        var result:any = this.refreshShopResults(filterQuery);

    }

    // ensure array
    public ensureArray(arrayJSONElement:any):any{
        if (!(arrayJSONElement instanceof Array)){
            var tempArr=[];
            tempArr.push(arrayJSONElement);
            arrayJSONElement=tempArr;
        }
        return arrayJSONElement;
    }

    // refresh the shop results
    public refreshShopResults(filterQuery:string):any{
        let loadFilters:boolean=true;
        this._controller.page=1;
        let orderByNumber = this._controller.getCurrentOrderBy();
        var result:any = this._controller.getModel().getSearchResults(ControllerSearch.SEARCH_URL, this._controller.tab, this._controller.type, orderByNumber, ControllerSearch.PAGE_TYPE, loadFilters, filterQuery, this._controller.page, this._controller.query);

        if (!(result.searchresult.results.domain.resultlist.item instanceof Array)){
            var tempArr=[];
            tempArr.push(result.searchresult.results.domain.resultlist.item);
            result.searchresult.results.domain.resultlist.item=tempArr;
        }

        this._controller.pages=result.searchresult.results.domain.pages;
        let html_shopresults=this._controller.processShop(result.searchresult.results.domain.resultlist.item);

        let htmlElement_artikel_list=document.getElementById("artikel_list");
        htmlElement_artikel_list.innerHTML=html_shopresults;

        this._controller.buildCurrentTabAutoReload();

        var hitElements=document.getElementsByClassName("ez-search-hits");
        for (var i=0; i<hitElements.length;i++){
            var currentElement:HTMLElement=(<any>hitElements[i]);
            currentElement.innerHTML=result.searchresult.results.domain.hits;
        }

        return result;
    }

        // refresh the shop results
    public loadNextPage():any{
        let loadFilters:boolean=true;
       
        var dummyRows=document.getElementsByClassName("ez-result-dummyrow");

        while (dummyRows.length>0){
            var currentElement:HTMLElement=(<any>dummyRows[0]);
            currentElement.remove();
        }

        var filterQuery=this.getCategoryQuery();
        filterQuery+=this.getTagQuery();
        filterQuery+=this.getBrandQuery();
        let orderByNumber = this._controller.getCurrentOrderBy();
        var result:any = this._controller.getModel().getSearchResults(ControllerSearch.SEARCH_URL, this._controller.tab, this._controller.type, orderByNumber, ControllerSearch.PAGE_TYPE, loadFilters, filterQuery, this._controller.page, this._controller.query);

        if (!(result.searchresult.results.domain.resultlist.item instanceof Array)){
            var tempArr=[];
            tempArr.push(result.searchresult.results.domain.resultlist.item);
            result.searchresult.results.domain.resultlist.item=tempArr;
        }

        let html_shopresults=this._controller.processShop(result.searchresult.results.domain.resultlist.item);

        let htmlElement_artikel_list=document.getElementById("artikel_list");
        htmlElement_artikel_list.innerHTML+=html_shopresults;


        var dummyRowCount: number = Number(this._controller.getModule().getConfig("tabs.shop.dummy_row_count"));
        var dummyRow=this._controller.getModule().getComponent("tabs.shop.dummy_row")
        for (var i=0; i<dummyRowCount;i++){
            htmlElement_artikel_list.innerHTML+=dummyRow;
        }

        this._controller.buildCurrentTabAutoReload();

        return result;
    }

    // Returns the category query
    public getCategoryQuery():string{
        let res:string="";
        for (var i=0; i<this._selectedNavFilter.length;i++){
            res=res+"&category1="+encodeURIComponent(this._selectedNavFilter[i]);
        }
        return res;
    }

    // Returns the tag query
    public getTagQuery():string{
        let res:string="";
        for (var i=0; i<this._selectedTagFilter.length;i++){
            res=res+"&tag="+encodeURIComponent(this._selectedTagFilter[i]);
        }
        return res;
    }

    // Returns the tag query
    public getBrandQuery():string{
        let res:string="";
        for (var i=0; i<this._selectedBrandFilter.length;i++){
            res=res+"&brand="+encodeURIComponent(this._selectedBrandFilter[i]);
        }
        return res;
    }

    public buildSelectedFilterPanel():void{

        var data:any=[];
        for (var i=0; i<this._selectedNavFilter.length;i++){
            let filter={
                "type": "category1",
                "type_name": this._controller.getModule().getLabel("filter_category_headline"),
                "name" :this._selectedNavFilter[i],
                "id_selector": this._selectedNavFilterIDSelektor[i]
            };
            data.push(filter);
        }

        for (var i=0; i<this._selectedTagFilter.length;i++){
            let filter={
                "type": "tag",
                "type_name": this._controller.getModule().getLabel("filter_detail_headline"),
                "name" :this._selectedTagFilter[i],
                "id_selector": this._selectedTagFilterIDSelektor[i]
            };
            data.push(filter);
        }

        for (var i=0; i<this._selectedBrandFilter.length;i++){
            let filter={
                "type": "brand",
                "type_name": this._controller.getModule().getLabel("filter_brand_headline"),
                "name" :this._selectedBrandFilter[i],
                "id_selector":this._selectedBrandFilterIDSelektor[i]
            };
            data.push(filter);
        }

        data.reset_categories=this._controller.getModule().getLabel("reset_categories");

        let handlebarTemplate=Handlebars.compile(this._selectedFilterTemplate);
        let res:string=handlebarTemplate(data);

        let htmlElement_selectedFilterPanel=document.getElementById("ez_selected_filters");
        htmlElement_selectedFilterPanel.innerHTML=res;

        var navSelectedFilters=document.getElementsByClassName("ez_filterbox_selectedfilter");
        for (var i=0; i<navSelectedFilters.length;i++){
            navSelectedFilters[i].removeEventListener("click",evt => this.executeSelectedFilterClick(evt));
            navSelectedFilters[i].addEventListener("click", evt => this.executeSelectedFilterClick(evt));
        }

        if (navSelectedFilters.length==0)
            HTML.addClassToElement(htmlElement_selectedFilterPanel,"hidden");
           // htmlElement_selectedFilterPanel.style.display="none";
        else
            HTML.removeClassFromElement(htmlElement_selectedFilterPanel,"hidden");
           // htmlElement_selectedFilterPanel.style.display="block";

        let htmlElement_btn_reset=document.getElementById("ez_filterbox_reset");
        htmlElement_btn_reset.addEventListener("click", evt => this.resetAllFilters(evt));

        this.setCategoryFilterInfo();
        this.setTagFilterInfo();
        this.setBrandFilterInfo();
    }

    public setCategoryFilterInfo():void{
        let handlebarTemplate=Handlebars.compile(this._template_filter_status);
        let statusData={
            "filter_count": this._counter_navFilter,
            "filter_selected_count": this._selectedNavFilter.length,
            "filter_status_filter": this._controller.getModule().getLabel("filter_status_filter"),
            "filter_status_filter_selected": this._controller.getModule().getLabel("filter_status_filter_selected")
        }
        let res=handlebarTemplate(statusData);
        let filter_category_hits=document.getElementById("filter_category_hits");
        filter_category_hits.innerHTML=res;
    }

    public setTagFilterInfo():void{
        let handlebarTemplate=Handlebars.compile(this._template_filter_status);
        let statusData={
            "filter_count": this._counter_tagFilter,
            "filter_selected_count": this._selectedTagFilter.length,
            "filter_status_filter": this._controller.getModule().getLabel("filter_status_filter"),
            "filter_status_filter_selected": this._controller.getModule().getLabel("filter_status_filter_selected")
        }
        let res=handlebarTemplate(statusData);
        let filter_category_hits=document.getElementById("filter_tag_hits");
        filter_category_hits.innerHTML=res;
    }

    public setBrandFilterInfo():void{
        let handlebarTemplate=Handlebars.compile(this._template_filter_status);
        let statusData={
            "filter_count": this._counter_brandFilter,
            "filter_selected_count": this._selectedBrandFilter.length,
            "filter_status_filter": this._controller.getModule().getLabel("filter_status_filter"),
            "filter_status_filter_selected": this._controller.getModule().getLabel("filter_status_filter_selected")
        }
        let res=handlebarTemplate(statusData);
        let filter_category_hits=document.getElementById("filter_brand_hits");
        filter_category_hits.innerHTML=res;
    }

    public executeSelectedFilterClick(evt:any): void{
        if (evt.currentTarget){
            let selectedFilter_IDSelector=$(evt.currentTarget).data("ez-selected-id");
            let htmlElement_selectedFilter=document.getElementById(selectedFilter_IDSelector);
            htmlElement_selectedFilter.click();
        }
    }

    public resetAllFilters(evt:any):void{
        this._selectedNavFilter=[];
        this._selectedNavFilterIDSelektor=[];
        this._selectedTagFilter=[];
        this._selectedTagFilterIDSelektor=[];
        this._selectedBrandFilter=[];
        this._selectedBrandFilterIDSelektor=[];
        this.buildSelectedFilterPanel();
        let filterQuery="";
        var result:any = this.refreshShopResults(filterQuery);
        this.renderHTML(this._filterData);
    }


}
