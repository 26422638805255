export class Collapse {

    private _htmlElement_collapse:any=null; // HTML Element for collapse event
    private _htmlElement_collapse_element:any=null; // HTML Target Element to collapse
    private _htmlElement_collapse_open:any=null; // HTML Element des Icons zum öffnen
    private _htmlElement_collapse_close:any=null; // HTML Element des Icons zum schließen

    constructor(collapse_element_id:string, collapse_element_targetID:string, collapse_element_iconOpenClass: string, collapse_element_iconCloseClass:string){
        // Toggle Collapse: Category
        this._htmlElement_collapse=document.getElementById(collapse_element_id);
        this._htmlElement_collapse_open=this._htmlElement_collapse.getElementsByClassName(collapse_element_iconOpenClass)[0];
        this._htmlElement_collapse_close=this._htmlElement_collapse.getElementsByClassName(collapse_element_iconCloseClass)[0];
        this._htmlElement_collapse_element=document.getElementById(collapse_element_targetID);
    }
    
    public init():void{
        this._htmlElement_collapse.addEventListener("click", evt => this.executeCollapseClick(evt));
        let category_collapse_status=this._htmlElement_collapse.getAttribute("data-ez-collapse");
        this.setCollapseStatus(category_collapse_status==="true");
    }

    public executeCollapseClick(evt:any):void{
        let category_collapse_status:boolean=(this._htmlElement_collapse.getAttribute("data-ez-collapse")==="true");
        this.setCollapseStatus(!category_collapse_status);
    }

    public setCollapseStatus(status:boolean){
        if (status){
            this._htmlElement_collapse.setAttribute("data-ez-collapse","true");
            this._htmlElement_collapse_open.style.display="none";
            this._htmlElement_collapse_close.style.display="block";
            this._htmlElement_collapse_element.style.display="block";   
        }
        else{
            this._htmlElement_collapse.setAttribute("data-ez-collapse","false");
            this._htmlElement_collapse_open.style.display="block";
            this._htmlElement_collapse_close.style.display="none";
            this._htmlElement_collapse_element.style.display="none";
        }
    }

}