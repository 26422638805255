import { modules } from "../../../main";

declare let currentPageSite: any;
export class Datalist {

    private containerClass: any;
    private inputClass: any;
    private listClass: any;
    private options: any;
    private vpeText: any;

    public constructor(containerClass: any, inputClass: any, listClass: any, options: any, vpeText: any) {
        this.containerClass = containerClass;
        this.inputClass = inputClass;
        this.listClass = listClass;
        this.options = options;
        this.vpeText = vpeText;
    }

    public create(filter = "") {
        const list = this.listClass;
        const filterOptions = this.options.filter(
            ( d:any ) => filter === "" || d.text.toString().includes(filter)
        );

        if ( list ) {
            if ( filterOptions.length === 0 ) {
                list.classList.remove("active");
            } else {
                list.classList.add("active");
            }

            list.innerHTML = filterOptions
            .map( function(o:any) {
                if ( o.text == 1 ) {
                    if (o.vpeType) {
                        return `<li id=${o.value}>${o.text}</li>`;
                    } else {
                        return `<li id=${o.value}>Probeflasche</li>`;
                    }
                } else {
                    return `<li id=${o.value}>${o.text}</li>`;
                }
            } )
            .join("");
        }
    }

    public addListeners(datalist: any) {
        const container = this.containerClass;
        const input = this.inputClass;
        const list = this.listClass;

        if ( container && input && list ) {
            container.addEventListener("click", (element: any) => {
                let target = element.target;
                this.listenerCurrentContaner(target, container, input);
            });

            input.addEventListener("input", function(e:any) {
                this.listenerCurrentInput(e, datalist, container, input);
            }.bind(this));

            list.addEventListener("click", function(element:any) {
                this.listenerCurrentList(element, input, container);
            }.bind(this));
        }
    }

    private listenerCurrentContaner( element: any, currentContainer: any, currentInput: any ) {
        if ( element.classList.contains("datalist-input") ) {
            element.parentNode.classList.toggle("active");
        } else if ( element.classList.contains("datalist-icon") ) {
            currentContainer.classList.toggle("active");
            currentInput.focus();
        }
    }

    private listenerCurrentInput( e:any, datalist: any, currentContainer: any, currentInput: any) {
        if ( !currentContainer.classList.contains("active") ) {
            currentContainer.classList.add("active");
        }

        let vpe: any = currentContainer.getAttribute("data-ez-datalist-vpe");
        let artID: any = currentContainer.getAttribute("data-ez-datalist-id");
        let suchStringButton: string = 'button[value="'+ artID +'"]';
        let suchStringSpan: string = 'span[data-ez-datalist-id="'+ artID +'"]';
        let button = document.querySelector(suchStringButton);
        let span = document.querySelector(suchStringSpan);

        var checkVPE = currentInput.value % vpe;
        if ( checkVPE === 0 && currentInput.value !== "" ) {
            // button.removeAttribute("disabled");
            span.innerHTML = "";
        } else if ( checkVPE > 0 || currentInput.value == "" ) {
            // button.setAttribute("disabled", "true");
            let infoText = this.vpeText;
            span.innerHTML = infoText;
        }

        if (currentInput.value == 0) {
            currentContainer.classList.remove("active");
        } else {
            currentContainer.classList.add("active");
            this.create(currentInput.value);
        }
        
    }

    private listenerCurrentList(element: any, currentInput: any, currentContainer: any) {
        if ( element.target.nodeName.toLocaleLowerCase() === "li" ) {
            if ( element.target.innerText == "Probeflasche" ) {
                element.target.parentNode.parentNode.children[0].value = 1;
            } else {
                element.target.parentNode.parentNode.children[0].value = element.target.innerText;
            }
            currentContainer.classList.remove("active");

            let artID = currentContainer.getAttribute('data-ez-datalist-id');
            let val = element.target.parentNode.parentNode.children[0].value;

            if ( val == 0 && currentPageSite == "warenkorb.htm" ) {
                this.deleteArtFromCorb(artID);
            } else {
                if (currentPageSite == "warenkorb.htm") {
                    let form: any;
    
                    let input: any = document.createElement('input');
                    input.type = "hidden";
                    input.name = "CurrentArtID";
                    input.value = currentContainer.getAttribute('data-ez-datalist-id');
    
                    form = $(element.target.parentNode).closest('form');
    
                    form[0].appendChild(input);
                    form[0].submit();
                }
                // Hier kann im <else> Teil dann die Funktion zum Hinzufügen des Warenkorbs
            }
        }
    }

    private setArtToCorb(art_id: any, quantity: any, currentContainer: any):void {
        let url = '/4DCGI/ezshop?action=JSON_addToKorb' + "&sKontaktID=" + modules.getKontaktID() + "&sKontaktKEY=" + modules.getKontaktKey() + "&sTICKCOUNT=" + modules.getTickcount() + `&artid=${art_id}&menge=${quantity}`;
            var result: any = {};
            /**
		    *
		    * Execute the ajax request
		    */
            jQuery.ajax({
                url: url,
                type: "GET",
                async: false,
                dataType: "json",
                success: function (response: Object) {
                    result = response;
                    // this.showDialog();
                    this.refreshCartPreview(quantity);
                    let suchString: string = 'button[value="'+ art_id +'"]';
                    let button = document.querySelector(suchString);
                    if (quantity==0) {
                        button.innerHTML = "in den Warenkorb";
                        $(button).removeClass("secondary");
                    } else {
                        button.innerHTML = "Menge ändern";
                        $(button).addClass("secondary");
                    }
                }.bind(this)
            });
    }

    private deleteArtFromCorb(art_id: any):void {
        let url = '/4DCGI/ezshop?action=trashArticle' + "&sKontaktID=" + modules.getKontaktID() + "&sKontaktKEY=" + modules.getKontaktKey() + `&artikelid=${art_id}`;
        jQuery.ajax({
            url: url,
            type: "POST",
            async: false,
            success: function(response: any) {
                window.location.reload();
            }.bind(this)
        });
    }

    private refreshCartPreview(quant: any):void {
        let url = `/filesync/warenkorb_preview`;
        let result: any;
        let quantity = quant;

        jQuery.ajax({
            url: url,
            type: "GET",
            async: false,
            dataType: "html",
            success: function (response: Object) {
                result = response;
            }.bind(this)
        });

        $("#refresh").html(result);
    }

    public showDialog(): void {
        var dialog = $("#dialog_warenkorb");
        dialog.dialog({
            modal: true,
            open: function (event, ui) {
                setTimeout("$('#dialog_warenkorb').dialog('close')", 3000);
            }
        });
    }
}