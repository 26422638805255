import { Model } from "./../../classes/mvc/Model";
import { AjaxRequest } from "./../../libs/AjaxRequest";

export class ModelSlider extends Model {

     /**
     * 
     * Load conf file
     */
    public loadConfigFile ( confFilePath:string ):Array<Object>{
        let result:Object = AjaxRequest.getJson( confFilePath );
        
        return result instanceof Array ? result : null;
    }

}