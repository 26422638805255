import { LogEntry } from "./LogEntry";
export class Logging {

    public static ERROR_LOG:Logging = new Logging( "Error Log", "red" );
    public static MODULES:Logging = new Logging( "Modul-Info", "green" );
    public static GENERAL_LOG:Logging = new Logging( "Allgemeiner Log", "blue" );

    private name:string;
    private color:string;

    private log:Array<LogEntry>;

    private constructor ( name:string, color:string ){
        this.name = name;
        this.color = color;
        this.log = new Array();
    }

    public getName ():string{
        return this.name;
    }

    public geColor ():string{
        return this.color;
    }

    public print ():void{
        console.log( "" );
        console.log( "%c********************" + "*".repeat( this.name.length ), "color: " + this.color + ";" );
        console.log( "%c*******   "+ this.name +"   *******", "color: " + this.color + ";" );
        console.log( "%c********************" + "*".repeat( this.name.length ), "color: " + this.color + ";" );
        for (let i = 0; i < this.log.length; i++) {
            if ( this.log[i].getOutputStyle() == "" ){
                console.log( this.log[i].getMessage() );
            } else {
                if ( this.log[i].getOutputStyle() instanceof Array ){
                    var args = new Array( this.log[i].getMessage() ).concat( this.log[i].getOutputStyle() );

                    console.log.apply( console, args );
                } else {
                    console.log( "%c" + this.log[i].getMessage(), this.log[i].getOutputStyle() );
                }
                
            }
        }
    }

    public add( message:string, outputStyle:string = "", useStrackTrace:boolean = true ):void{
        if ( useStrackTrace ){
            var error:Error = new Error( message );

            if ( typeof Error.captureStackTrace === "function"){
                Error.captureStackTrace( error, this.add );
                this.log.push( new LogEntry( error, outputStyle ) );
            } else {
                this.log.push( new LogEntry( error.stack, outputStyle ) );
            }
            
        } else {
            this.log.push( new LogEntry( message, outputStyle ) );
        }   
    }

    public addWithMultipleStyles ( message:string, styles:Array<string> ):void {
        this.log.push( new LogEntry( message, styles ) );
    }

    public clear (){
        this.log = new Array();
    }
}