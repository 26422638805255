import { Model } from "./../../classes/mvc/Model";
import { AjaxRequest } from "./../../libs/AjaxRequest";

export class ModelUIDChecker extends Model {

    public getResult ( url:string, myUID:string, checkUID:string ):Document {
        return AjaxRequest.getXML( url, {
            UstId_1: myUID,
            UstId_2: checkUID
        });
    }

}