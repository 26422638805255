import { Filter } from "./Filter";
import { Json } from "../../../libs/Json";
import { findIcon } from "../../../libs/Icons";
import { ControllerSearch } from "../ControllerSearch";
import jQuery = require( "jquery" );
import { Globals } from "../../../classes/Globals";

export class TagFilter extends Filter {

	private static HEADLINE:string = "";

	private tags:Array<string>;
	private tagValues:Array<any>;
	private selectedTags:Array<string>;
	private tagId:Array<any>;
	public static blacklistedTags:Array<string>;

	public constructor ( controller:ControllerSearch ){
		super( controller, "tag" );

		this.setHeadline( TagFilter.HEADLINE );
		this.setIcon( findIcon( "filter_tag" ) );
		
		this.tags = new Array();
		this.selectedTags = new Array();
	}

	public initGlobals ():void{
		this.getController().getModule().addView("filter_detailfilter_container", this.getController().getModule().getComponent( "filter.detailfilter.container" ) );
		this.getController().getModule().addView("filter_detailfilter_row", this.getController().getModule().getComponent( "filter.detailfilter.row" ) );
		this.getController().getModule().addView("filter_detailfilter_row_tag_head_container", this.getController().getModule().getComponent("filter.detailfilter.row_tag_head_container"));
		this.getController().getModule().addView("filter_detailfilter_row_tag_head_row", this.getController().getModule().getComponent("filter.detailfilter.row_tag_head_row"));

		TagFilter.HEADLINE = this.getController().getModule().getLabel( "filter_detailfilter_headline" );
		this.tagId = new Array();
		this.tagValues = new Array();
		TagFilter.blacklistedTags = this.getController().getModule().getConfig("tags_blacklist");
	}

    public create ( value:Object ):string {
		var filter_output = "";
		var filter_tag_content = "";
		var modelID:number;
		if ( value != null ) {
			this.tags = Json.getSubobject(value, "value");

			/**
			*
			* Convert the associative array into a list array
			*/
			for (var i = 0; i < this.tags.length; i++) {
				var currentID = Json.getSubobject( this.tags[i], "id" );
				var currentValue = Json.getSubobject( this.tags[i], "value" );
				this.tagId.push(currentID);
				this.tagValues.push(currentValue);
				this.tags[i] = Json.getSubobject( this.tags[i], "name" );
			}

			if (TagFilter.blacklistedTags == null) {
				TagFilter.blacklistedTags = new Array();
			}

			if (ControllerSearch.SELECTED_TAGS.length > 0) {
				for (var i = 0; i < ControllerSearch.SELECTED_TAGS.length;i++) {
					TagFilter.blacklistedTags.push(ControllerSearch.SELECTED_TAGS[i]);
				}
			}

			if (TagFilter.blacklistedTags) {
				for (var i = 0; i < TagFilter.blacklistedTags.length; i++) {
					var currentBlacklist = TagFilter.blacklistedTags[i];
					var tagCounterSingle = 0;
					var tagCounterMulti = 0;
					this.tagValues.filter(function(obj:any) {
						if (obj.length){
							for (var j = 0; j < obj.length; j++) {
								if (obj[j].name == currentBlacklist) {
									obj.splice(j, 1);
									/**
									* Remove from tagValues and Tags
									* If current Object is empty
									*/
									if (!obj.length) {
										this.tagValues.splice(tagCounterMulti, 1);
										this.tags.splice(tagCounterMulti, 1);
									}
								}
								tagCounterMulti++;
							}
						} else {
							if (obj.name == currentBlacklist) {
								this.tagValues.splice(tagCounterSingle, 1);
								this.tags.splice(tagCounterSingle, 1);
							}
						}
						tagCounterSingle++;
					}.bind(this));
				}
			}
			/**
			*
			* Process the rows
			*/
			for (var i = 0; i < this.tags.length; i++) {
				modelID = this.getController().getModel().new();
				var tagHeadline = this.tags[i];

				if (this.tagValues[i].length) {
					filter_tag_content = "";
					for (var j = 0; j < this.tagValues[i].length; j++) {
						var tagName = this.tagValues[i][j]['name'];
						var tag = this.tagValues[i][j]['name'];
						tag = encodeURIComponent(tag);
						this.getController().getModel().add(modelID, "tagHeadline", tagHeadline);
						this.getController().getModel().add(modelID, "tagName", tagName);
						this.getController().getModel().add(modelID, "tag", tag);

						filter_tag_content += this.getController().process( modelID, "filter_detailfilter_row_tag_head_row" );

						modelID = this.getController().getModel().new();
					}
				} else {
					var tagName = this.tagValues[i]['name'];
					var tag = this.tagValues[i]['name'];
					tag = encodeURIComponent(tag);
					this.getController().getModel().add(modelID, "tagHeadline", tagHeadline);
					this.getController().getModel().add(modelID, "tagName", tagName);
					this.getController().getModel().add(modelID, "tag", tag);

					filter_tag_content = this.getController().process( modelID, "filter_detailfilter_row_tag_head_row" );

					modelID = this.getController().getModel().new();
				}

				

				this.getController().getModel().add( modelID, "name", this.tags[i] );
				this.getController().getModel().add( modelID, "id", this.tagId[i] );

				/**
				*
				* Check if the current query is equal with one brand
				*/
				if ( typeof this.getController().getCaspardoQuery() !== "undefined" && this.tags[i] == this.getController().getCaspardoBrand() ) {
					this.getController().getModel().add( modelID, "checked", "checked" );
				} else {
					this.getController().getModel().add( modelID, "checked", "" );
				}

				this.getController().getModel().add( modelID, "content", filter_tag_content );
				
				filter_output += this.getController().process( modelID, "filter_detailfilter_row_tag_head_container" );

				// filter_output += this.getController().processOne( "filter_detailfilter_row_tag_head_container", "content", filter_tag_content );
			}
		}

		this.tagValues = [];

		if ( filter_output != "" ){
			return this.getController().processOne( "filter_detailfilter_container", "content", filter_output );
		} else {
			return null;
		}
		
	}

    public assignEvents ():void {
		var detailfilter_filter_reset = jQuery( "#filter_tag_reset" );
		var detailfilter_filter_refresh = jQuery( "#filter_tag_refresh_selection" );
		var detailfilter_filter_checkbox = jQuery( "[data-ez-filter-detailfilter]" );

		/**
		*
		* Click event on the reset button
		*/
		if ( detailfilter_filter_reset.length ) {
			detailfilter_filter_reset.on( "click", function (){
				this.reset();
				this.execute();
			}.bind(this));
		}

		/**
		*
		* Click event on the apply button
		* Get all checked checkboxes and push them into the array use to build the url
		*/
		if (detailfilter_filter_checkbox.length) {
			detailfilter_filter_checkbox.on( "click", event => {
				var clickedElement = jQuery(event.target);
				if (clickedElement) {
					this.newExecute(clickedElement);
				}
			});
		} else if ( detailfilter_filter_refresh.length ) {
			detailfilter_filter_refresh.on( "click", function() {
				this.execute();
			}.bind(this));
		}
		// if ( detailfilter_filter_refresh.length ) {
		// 	detailfilter_filter_refresh.on( "click", function (){
		// 		this.execute();
		// 	}.bind(this));
		// }
    }

    public reset ( input:JQuery<HTMLElement> = null ):void{
        var detailfilter_containers = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-detailfilter-container]" );

		if ( detailfilter_containers.length > 0 ) {

			detailfilter_containers.each( function() {
				jQuery( this ).show();

				var checkbox = jQuery( this ).find( "[" + Globals.ATTRIBUTE_PREFIX + "filter-detailfilter]" );
					checkbox.prop( "checked", false );
			});

		}

		if ( input != null && input.length ) {
			input.val( "" );
		}
	}
	
	/**
	 * 
	 * New Execute Category Filter
	 */
	public newExecute( element:JQuery<HTMLElement> ): void {
		if (element.length) {
			this.selectedTags = new Array();

			for (var i = 0; i < element.length; i++) {
				this.selectedTags.push( element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "filter-detailfilter" ) );

				ControllerSearch.SELECTED_CATEGORIES.push( decodeURIComponent(element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "filter-detailfilter" )));
				ControllerSearch.SELECTED_TAGS.push( decodeURIComponent(element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "filter-detailfilter")) );
				ControllerSearch.SELECTED_HEADLINES.push( element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "tag-headline") );
			}

			this.getController().refreshAll();
			this.getController().onContentChange();
		}
	}

    /**
	*
	* Execute the detailfilter filter
	*/
	public execute ():void{
		this.selectedTags = new Array();

		var checkboxes = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-detailfilter]" );
		if ( checkboxes.length > 0 ) {

			checkboxes.each( function( i:number ) {
				var checkbox = jQuery( checkboxes[i] );

				if ( checkbox.is( ":checked" ) ) {
					this.selectedTags.push( checkbox.attr( ""+ Globals.ATTRIBUTE_PREFIX +"filter-detailfilter" ) );
				}
			}.bind(this));

		}

		this.getController().refreshAll();
		this.getController().onContentChange();
    }
    
    	/**
	*
	* Change the current selection
	*/
	private change ( value:string ):void{
		var value = value.toLowerCase();
		var detailfilter_containers = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-detailfilter-container]" );

		if ( detailfilter_containers.length > 0 ) {

			if ( value == "" ) {

				/**
				*
				* Reset the displayed options
				*/
				detailfilter_containers.each( function() {
					jQuery( this ).show();
				});

			} else {

				/**
				*
				* Hide all not checked options
				* Hide all not matching options
				*/
				detailfilter_containers.each( function() {
					var checkbox = jQuery( this ).find( "[" + Globals.ATTRIBUTE_PREFIX + "filter-detailfilter]" );
					var checkbox_value = checkbox.attr( ""+ Globals.ATTRIBUTE_PREFIX +"filter-detailfilter" ).toLowerCase();

					if ( !checkbox.is( ":checked" ) && checkbox_value.search( value ) == -1 ) {
						jQuery( this ).hide();
					}
				});

			}

		}
	}

	public buildURL ():string{
		var url:string = "";

		for (var i = 0; i < this.selectedTags.length; i++) {
			// url += "&tag=" + encodeURIComponent( this.selectedTags[i] );
			url += "&tag=" + this.selectedTags[i];
		}

		return url;
	}

}