import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import { ControllerPasswordChecker } from "./ControllerPasswordChecker";
import { ModelPasswordChecker } from "./ModelPasswordChecker";
import { IChecker } from "./../../classes/IChecker";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";

@ModuleConfig({
    moduleName: "PasswordChecker",
    style: "password-checker.scss"
})
export class ModulePasswordChecker extends HTMLModule<ModelPasswordChecker> implements IChecker{

    public constructor ( configuration:Object ){
        super( "PasswordChecker", configuration );

        this.initAll( ControllerPasswordChecker.prototype );
        this.registerCallableMethod( this, "isInputValid", this.isInputValid );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

    public isInputValid ( id:string ):boolean{
        var controller:ControllerPasswordChecker = this.findController( id ) as ControllerPasswordChecker;
        if ( controller != null ){
            return controller.validate();
        } else {
            return false;
        }
    }

}