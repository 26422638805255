import { IComparator } from "./IComparator";

export class Arrays {

    public static sort<T> ( array:Array<T>, comparator:IComparator<T> ):Array<T>{
        for (let i = 0; i < array.length; i++) {
          for (let j = 0; j < array.length; j++) {
            if ( i != j ){

                if ( comparator.compare( array[i], array[j] ) == 1 ) {
                    var tmp:T = array[i];

                    array[i] = array[j];
                    array[j] = tmp; 
                }

            }
          }  
        }

        return array
    }

    public static remove<T> ( array:Array<T>, element:any ):Array<T>{
        return this.removeByIndex( array, array.indexOf( element ) );
    }

    public static removeByIndex<T> ( array:Array<T>, index:number ):Array<T>{
        if ( index > -1 && index < array.length ){
            array.splice( index, 1 );
        }

        return array;
    }

    public static removeDuplicatedEntrys ( array:Array<any> ){
        var result:Array<any> = new Array();

        for (let i = 0; i < array.length; i++) {
            if ( result.indexOf( array[i] ) == -1 ){
                result.push( array[i] );
            }
        }

        return result;
    }
}