import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import { Globals } from "./../../classes/Globals";
import { ModelSlider } from "./ModelSlider";
import { SliderItem } from "./SliderItem";
import { Json } from "../../libs/Json";
import { Windows } from "./../../libs/Windows";
import { UserAgents } from "../../libs/UserAgents";
import { Times } from "./../../libs/Times";
import jQuery = require("jquery");
import "jquery-touchswipe";

export class ControllerSlider extends HTMLController<ModelSlider> {

    private slides: Array<SliderItem>;

    private currentSlides: Array<number>;

    private interval: number;

    private showThumbs: boolean;
    private showThumbsButtons: boolean;
    private buildSliderItems: boolean;
    private itemCountIsEven: boolean;

    private animates: boolean;
    private timer: any;

    private stopped: boolean;

    /**
     * 
     * Items per slide
     */
    private itemsPerSlide: number;
    private slideWidth: number;
    private smallViewMaxWidth: number;

    /**
     * 
     * HTML Elements
     */
    private container: JQuery<HTMLElement>;

    private thumbsContainer: JQuery<HTMLElement>;
    private buttonsContainer: JQuery<HTMLElement>;

    private previousButton: JQuery<HTMLElement>;
    private nextButton: JQuery<HTMLElement>;

    public constructor(accessName: string, accessID: number, element: JQuery<HTMLElement>) {
        super(new ModelSlider(), accessName, accessID, element);
    }

    public initGlobals(): void {

    }

    public run(): void {
        var confFilePath = this.getParam("conf-file-path");

        this.slides = new Array();
        this.buildSliderItems = confFilePath != null;

        if (!this.buildSliderItems) {

            var slides = this.getElement().find(".item");
            for (let i = 0; i < slides.length; i++) {

                var item: JQuery<HTMLElement> = jQuery(slides[i]);
                var link: string = item.attr(Globals.PARAMETER_ATTRIBUTE_KEY + "link");
                var title: string = item.attr(Globals.PARAMETER_ATTRIBUTE_KEY + "title");
                var keyname: string = item.attr(Globals.PARAMETER_ATTRIBUTE_KEY + "keyname");

                if (typeof link !== "undefined" && link != "") {
                    var content: JQuery<HTMLElement> = jQuery(item.html());
                    item.html("<a target='_blank' href='" + link + "'></a>").append(content);
                }

                var sliderItem: SliderItem = new SliderItem(item, title, link, keyname);
                if (sliderItem.isItemValid()) {
                    this.slides.push(sliderItem);
                }
            }

        } else {
            var conf: Array<Object> = this.getModel().loadConfigFile(confFilePath);
            if (conf != null) {

                for (let i = 0; i < conf.length; i++) {

                    var content: JQuery<HTMLElement> = jQuery(Json.getSubobject(conf[i], "content"));
                    if (content != null) {

                        var title: string = Json.getSubobject(conf[i], "title");
                        var link: string = Json.getSubobject(conf[i], "link");
                        var keyname: string = Json.getSubobject(conf[i], "keyname");

                        if ( keyname == null ){ keyname = ""; }

                        var item = jQuery("<div class='item'></div>");

                        if (link != null && link != "") {
                            item.append(jQuery("<a target='_blank' href='" + link + "'></a>").append(content))
                        } else {
                            item.append(content);
                        }

                        var sliderItem: SliderItem = new SliderItem(item, title, link, keyname);
                        if (sliderItem.isItemValid()) {
                            this.slides.push(sliderItem);
                        }
                    }

                }

            } else {
                this.getModule().error( Globals.MODULE_LOADING_ERROR + " Die Konfigurationsdatei konnte nicht geladen werden", false);
            }
        }

        var run:boolean = false;

        if ( this.slides.length >= 2 ){
            if ( this.slides.length == 2 ){
                this.slides.push( SliderItem.duplicate( this.slides[ this.slides.length - 1 ] ) );
            }

            run = true;
        } else if (this.slides.length == 1) {
            this.slides.push( this.slides[ this.slides.length - 1 ] );
            run = true;
        } else {
            this.getModule().error( Globals.MODULE_LOADING_ERROR + " Mindestanzahl an Items = 2", false);
        }

        if ( run ){
            this.load();
            this.build();
        }
    }

    private load ():void{
        this.interval = 5000;
        this.smallViewMaxWidth = 700;

        var smallViewMaxWidth: number = Number(this.getParam("max-width"));
        if (!isNaN(smallViewMaxWidth)) {
            this.smallViewMaxWidth = smallViewMaxWidth;
        }

        var interval: number = Number(Times.getMillis(this.getParam("interval")));
        if (!isNaN(interval) && interval != 0) {
            this.interval = interval;
        }

        this.setItemsPerSlide(Number(this.getParam("items-per-slide")));
        this.setStartSlides();

        if (this.slides.length == 2) {
            this.animates = true;
            this.stopped = true;
        } else {
            this.animates = false;
            this.stopped = false;
        }
        

        this.container = jQuery(this.getModule().getComponent("container"));

        this.thumbsContainer = jQuery(this.getModule().getComponent("thumbs.container"));
        this.buttonsContainer = jQuery(this.getModule().getComponent("buttons.container"));

        this.previousButton = jQuery(this.getModule().getComponent("buttons.previous"));
        this.nextButton = jQuery(this.getModule().getComponent("buttons.next"));

        /**
         * 
         * Configurations
         */
        this.showThumbs = this.getModule().getConfig('show_active');
        this.showThumbsButtons = this.getModule().getConfig('show_buttons');
    }

    private build ():void{
        if (this.buildStructure()) {
            if (this.showThumbs) {
                this.setThumbs();
            }

            this.buildSlider();
        }
    }

    private previousSlide(): void {
        this.newSlide(this.getPrevioustSlides(), false);
    }

    private nextSlide(): void {
        this.newSlide(this.getNextSlides(), true);
    }

    private newSlide(newSlides: Array<number>, slideLeft: boolean): void {
        if (!this.animates) {
            this.animates = true;

            /**
            * 
            * Position slides
            */
            this.positionPossibleNextSlides(slideLeft);

            /**
             * 
             * Get elements
             */
            var currentElements: Array<JQuery<HTMLElement>> = new Array()
            var newElements: Array<JQuery<HTMLElement>> = Array();

            for (let i = 0; i < this.currentSlides.length; i++) {
                currentElements.push(this.slides[this.currentSlides[i]].getContent());
            }

            for (let i = 0; i < newSlides.length; i++) {
                newElements.push(this.slides[newSlides[i]].getContent());
            }

            /**
             * 
             * Animation
             */
            for (let i = 0; i < currentElements.length; i++) {
                var left: number;

                if (slideLeft) {
                    left = this.slideWidth * (currentElements.length - i);
                } else {
                    left = 100 + (this.slideWidth * i);
                }

                currentElements[i].animate({
                    left: (slideLeft ? "-" : "") + left + "%",
                    right: "0px"
                }, 1000, function () {
                    this.animates = false;
                }.bind(this));
            }

            for (let i = 0; i < newElements.length; i++) {
                var left: number;

                newElements[i].animate({
                    left: this.slideWidth * i + "%",
                    right: "0px"
                }, 1000, function () {
                    this.animates = false;
                }.bind(this));
            }

            /**
             * 
             * Set new slide index and next possible slides
             */
            this.currentSlides = newSlides;

            /**
             * 
             * Set active thumb
             */
            if (this.showThumbs) {
                this.setActiveThumb();
            }
        }
    }

    private getPrevioustSlides(): Array<number> {
        var positions: Array<number> = new Array();

        for (let i = 0; i < this.itemsPerSlide; i++) {
            var previoustID: number = this.currentSlides[0] - i - 1;

            if (previoustID > -1) {
                positions.push(previoustID);
            } else if (this.itemCountIsEven || this.slides.length % this.itemsPerSlide == i + 1) {
                positions.push(this.slides.length - i - 1);
            }
        }

        return positions.sort();
    }

    private getNextSlides(): Array<number> {
        var positions: Array<number> = new Array();
        var starts: boolean = false;

        for (let i = 0; i < this.itemsPerSlide; i++) {
            var nextID: number = this.currentSlides[this.itemsPerSlide - 1] + i + 1;

            if (nextID < this.slides.length) {
                positions.push(nextID);
                starts = true;
            } else if (!starts) {
                positions.push(i);
            }
        }

        return positions.sort();
    }

    private positionPossibleNextSlides(slideLeft: boolean): void {
        if (slideLeft) {
            var nextSlides: Array<number> = this.getNextSlides();

            for (let i = 0; i < nextSlides.length; i++) {
                this.positionRight(this.slides[nextSlides[i]].getContent(), i + 1);
            }
        } else {
            var previoustSlides: Array<number> = this.getPrevioustSlides();

            for (let i = 0; i < previoustSlides.length; i++) {
                this.positionLeft(this.slides[previoustSlides[i]].getContent(), previoustSlides.length - i);
            }
        }
    }

    private positionRight(element: JQuery<HTMLElement>, count: number): void {
        var pos = count * this.slideWidth;

        element.css("right", "-" + pos + "%");
        element.css("left", "initial");
    }

    private positionLeft(element: JQuery<HTMLElement>, count: number): void {
        var pos = count * this.slideWidth;

        element.css("right", "initial");
        element.css("left", "-" + pos + "%");
    }

    private setStartSlides(): void {
        this.currentSlides = new Array();
        for (let i = 0; i < this.itemsPerSlide; i++) {
            this.currentSlides.push(i);
        }
    }

    private setItemsPerSlide(itemsPerSlide: number): void {
        this.itemsPerSlide = 1;

        if (!isNaN(itemsPerSlide) && itemsPerSlide > 1 && itemsPerSlide < 5 && this.slides.length >= itemsPerSlide * 2 && !UserAgents.isMobile() && window.innerWidth > this.smallViewMaxWidth) {
            this.itemsPerSlide = itemsPerSlide;
        }

        this.slideWidth = 100 / this.itemsPerSlide;
        this.itemCountIsEven = this.slides.length % this.itemsPerSlide == 0;
    }

    private buildSlider() {
        for (let i = 0; i < this.slides.length; i++) {
            this.container.append(this.slides[i].getContent());
        }

        /**
         * 
         * Start
         */
        jQuery(window).bind("load", function () {
            this.setSliderHeight();
            this.start();
        }.bind(this));
    }

    private setSliderHeight(): void {
        var height: number = 0;

        for (let i = 0; i < this.slides.length; i++) {

            if (this.slides[i].getContent().height() > height) {
                height = this.slides[i].getContent().height();
            }

        }

        this.container.css("height", height);
    }

    private start(): void {
        /**
          * 
          * Bind events
          */
        this.bindEvents();

        this.play();
    }

    private play(): void {
        /**
         * 
         * Position all slides to the left
         */
        for (let i = 0; i < this.slides.length; i++) {
            this.slides[i].getContent().css("width", this.slideWidth + "%");
            this.slides[i].getContent().css("left", "-" + this.slideWidth + "%");
        }

        /**
         * 
         * Position the current slide
         */
        for (let i = 0; i < this.currentSlides.length; i++) {
            this.slides[this.currentSlides[i]].getContent().css("left", i * this.slideWidth + "%");
            this.slides[this.currentSlides[i]].getContent().css("right", (i + 1) * this.slideWidth + "%");
        }

        /**
         * 
         * Start playing
         */
        this.resetInterval();
    }

    private bindEvents(): void {
        this.previousButton.on("click", function () {
            this.actionPreviousSlide();
        }.bind(this));
        this.nextButton.on("click", function () {
            this.actionNextSlide();
        }.bind(this));

        Windows.instance.onResize(function () {
            this.setSliderHeight();

            if (window.innerWidth < this.smallViewMaxWidth) {
                this.setItemsPerSlide(1);
            } else {
                this.setItemsPerSlide(Number(this.getParam("items-per-slide")));
            }

            this.setStartSlides();
            if (this.show_thumbs) {
                this.setThumbs();
            }
            this.play();

        }.bind(this));
        
        jQuery(this.container).swipe({
            swipe: function (event: any, direction: string, distance: number, duration: number, fingerCount: number, fingerData: any) {
                if (fingerCount == 1){
                    if (direction == "left") {
                        this.actionNextSlide();
                    } else if (direction == "right") {
                        this.actionPreviousSlide();
                    }
                }
            }.bind(this),
            threshold: 100,
            allowPageScroll: "auto"
        });
    }

    private actionNextSlide(): void {
        if (this.stopped) {
            this.startSlider();
        }
        this.resetInterval();
        this.nextSlide();
    }

    private actionPreviousSlide(): void {
        if (this.stopped) {
            this.startSlider();
        }
        this.resetInterval();
        this.previousSlide();
    }

    /**
     * 
     * Thumbs
     */
    private setThumbs(): void {
        this.buildThumbs();
        this.setActiveThumb();
    }

    private buildThumbs(): void {

        if (!this.container.find(this.thumbsContainer).length && this.thumbsContainer.length) {
            this.container.append(this.thumbsContainer);
        }

        if (this.thumbsContainer.length) {
            this.thumbsContainer.html("");
        }

        var count = 1;

        for (let i = 0; i < this.slides.length; i++) {
            if (count == this.itemsPerSlide && this.itemsPerSlide != 1) {
                count = 1;
            } else {
                var thumb = jQuery("<span class='thumb icon' " + Globals.PARAMETER_ATTRIBUTE_KEY + "id='" + i + "'></span>");

                if (this.slides[i].getTitle() != null && this.slides[i].getTitle() != "") {
                    thumb.append("<label class='title'>" + this.slides[i].getTitle() + "</label>");
                }

                this.thumbsContainer.append(thumb);
                thumb.on("click", function (element: JQuery<HTMLElement>) {

                    var id = Number(element.attr(Globals.PARAMETER_ATTRIBUTE_KEY + "id"));

                    if (!isNaN(id) && id >= 0 && id < this.slides.length) {
                        this.jumpTo(id);
                    }

                }.bind(this, jQuery(thumb)));

                count++;
            }

        }
    }

    private jumpTo(id: number): void {
        if (this.currentSlides.indexOf(id) == -1) {

            var nextIds: Array<number> = new Array();
            var nextElements: Array<SliderItem> = new Array();

            nextIds.push(id);
            for (let i = 1; i < this.itemsPerSlide; i++) {
                if (id + i < this.slides.length) {
                    nextIds.push(id + i);
                }
            }

            for (let j = 0; j < nextIds.length; j++) {
                nextElements.push(this.slides[nextIds[j]]);
            }

            var posLeft = nextIds[0] > this.currentSlides[this.currentSlides.length - 1];
            if (posLeft) {
                for (let k = 0; k < nextElements.length; k++) {
                    this.positionRight(nextElements[k].getContent(), k + 1);
                }
            } else {
                for (let k = 0; k < nextElements.length; k++) {
                    this.positionLeft(nextElements[k].getContent(), k + 1);
                }
            }

            this.newSlide(nextIds, posLeft);
            this.resetInterval();
        }
    }

    public jumpToByKeyname(keyname: string): void {
        for (let i = 0; i < this.slides.length; i++) {
            if (this.slides[i].getKeyName() == keyname) {
                this.jumpTo(i);
                break;
            }
        }
    }
    public stopSlider(): void {
        this.stopped = true;
    }

    public startSlider(): void {
        this.stopped = false;
    }

    private resetInterval(): void {
        clearInterval(this.timer);
        this.timer = setInterval(function () {
            if (!this.stopped) {
                this.nextSlide();
            }
        }.bind(this), this.interval);
    }

    private setActiveThumb(): void {
        /**
         * 
         * Remove all
        */
        var allThumbs: JQuery<HTMLElement> = this.thumbsContainer.find(".thumb");
        for (let i = 0; i < allThumbs.length; i++) {
            jQuery(allThumbs[i]).removeClass("active");
        }

        /**
         * 
         * Add the current slides
        */
        for (let i = 0; i < this.currentSlides.length; i++) {
            var currentThumb: JQuery<HTMLElement> = this.thumbsContainer.find(".thumb[" + Globals.PARAMETER_ATTRIBUTE_KEY + "id='" + this.currentSlides[i] + "']");
            currentThumb.addClass("active");
        }
    }

    /**
    * 
    * Structure
    */
    private buildStructure(): boolean {
        var result: boolean = false;

        if (this.container.length) {

            var entry = this.getElement();
            if (entry.length) {
                entry.append(this.container);

                if (this.showThumbsButtons && this.slides.length > 2) {
                    if (this.buttonsContainer.length) {

                        this.container.append(this.buttonsContainer);
    
                        if (this.previousButton.length && this.nextButton.length) {
    
                            this.buttonsContainer.append(this.previousButton);
                            this.buttonsContainer.append(this.nextButton);
    
                            result = true;
                        }
    
                    }
                } else {
                    result = true;
                }

            }
        }

        return result;
    }

}