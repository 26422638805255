import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import { ControllerExpandBox } from "./ControllerExpandBox";
import { ModelExpandBox } from "./ModelExpandBox";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";

@ModuleConfig({
    moduleName: "ExpandBox",
    style: "expand-box.scss"
})
export class ModuleExpandBox extends HTMLModule<ModelExpandBox> {
    
    public constructor ( configuration:Object ){
        super( "ExpandBox", configuration );
        
        this.initAll( ControllerExpandBox.prototype );
    }

    public run (){
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

}