import { Model } from "./Model";
import { View } from "./View";
import { Module } from "./Module";
import { modules } from "./../../main";

// Controller Class : Central Handlebars handler. It processes all handlebar actions

export abstract class Controller<ModelType extends Model>{
    // Class Attributes 
    private id:string;
    private model:ModelType;
    private accessName:string;
    private accessID:number;
    private flagInitialized:boolean;

    // Constructor
    public constructor ( model:ModelType, accessName:string, accessID:number ) {
        this.id = Math.random().toString(32).substr(2);
        this.model = model;
        this.accessName = accessName;
        this.accessID = accessID;
        this.flagInitialized = false; // Default not initalized
    }

    // Processes the handlebar model. (Model + View)
    public process ( modelId:number, viewName:string, debug:boolean = false ):string {
        var result:string = "";
        var view:View = this.getModule().getView( viewName );
        if ( view != null ){
            result = view.process( this.model.handlebarsExport( modelId ), debug );
            this.model.clear( modelId );
        } else {
            this.getModule().error( "Der folgende View wurde nicht gefunden: " + viewName);
        }
        return result;
    }

    // Process one special view
    public processOne ( viewName:string, key:string, value:string, debug:boolean = false ){
        var modelId = this.model.new();
        this.model.add( modelId, key, value );
        return this.process( modelId, viewName, debug );
    }

    public isInitialized ():boolean{
        return this.flagInitialized;
    }

    public getModel ():ModelType{
        return this.model;
    }

    public getModule ():Module<ModelType>{
        try {
            return modules.getModule( this.accessName, this.accessID ) as Module<ModelType>;
        } catch {
            return null;
        }
    }

    public getID ():string{
		return this.id;
	}

    public runWithPreCheck ():void {
        if (!this.getModule().areGlobalsInitialized()){
            this.initGlobals();
            this.getModule().setGlobalsInitialized( true );
        }

        if ( !this.flagInitialized ){
            this.run();
            this.flagInitialized = true;
        }
    }

    public runWithGlobalsPrecheck ():void{
        if (!this.getModule().areGlobalsInitialized()){
            this.initGlobals();
            this.getModule().setGlobalsInitialized( true );
        }

        this.run();
    }

    abstract run ():void;
    abstract initGlobals():void;
}