import { Filter } from "./Filter";
import { Json } from "../../../libs/Json";
import { findIcon } from "../../../libs/Icons";
import { ControllerSearch } from "../ControllerSearch";
import jQuery = require( "jquery" );
import { Globals } from "../../../classes/Globals";

export class BrandFilter extends Filter {

	private static HEADLINE:string = "";

	private brands:Array<string>;
	private selectedBrands:Array<string>;
	public static blacklistedBrands:Array<string>;

	public constructor ( controller:ControllerSearch ){
		super( controller, "brand" );

		this.setHeadline( BrandFilter.HEADLINE );
		this.setIcon( findIcon( "filter_brand" ) );
		
		this.brands = new Array();
		this.selectedBrands = new Array();
	}

	public initGlobals ():void{
		this.getController().getModule().addView("filter_brand_container", this.getController().getModule().getComponent( "filter.brand.container" ) );
		this.getController().getModule().addView("filter_brand_row", this.getController().getModule().getComponent( "filter.brand.row" ) );

		BrandFilter.HEADLINE = this.getController().getModule().getLabel( "filter_brand_headline" );
		BrandFilter.blacklistedBrands = this.getController().getModule().getConfig("brand_blacklist");
	}

    public create ( value:Object ):string {
        var filter_output = "";

		if ( value != null ) {
            this.brands = Json.convertObjectToArray( value );

			/**
			*
			* Convert the associative array into a list array
			*/
			for (var i = 0; i < this.brands.length; i++) {
				this.brands[i] = Json.getSubobject( this.brands[i], "name" );
			}

			/**
			 * 
			 * Checks If Blacklisted Brands exists
			 * If true it will be removed
			 */
			if ( BrandFilter.blacklistedBrands == null ) {
				BrandFilter.blacklistedBrands = new Array();
			}

			if (ControllerSearch.SELECTED_BRANDS.length > 0) {
				for (var i = 0; i < ControllerSearch.SELECTED_BRANDS.length; i++) {
					BrandFilter.blacklistedBrands.push(ControllerSearch.SELECTED_BRANDS[i]);
				}
			}

			if (BrandFilter.blacklistedBrands) {
				for (var i = 0; i < BrandFilter.blacklistedBrands.length; i++) {
					var currentBlacklist = BrandFilter.blacklistedBrands[i];
					if (this.brands.includes(currentBlacklist)) {
						this.brands = this.brands.filter(function(item:string) {
							return item !== currentBlacklist;
						});
					}
				}
			}

			/**
			*
			* Sort the array
			*/
			this.brands.sort();

			/**
			*
			* Process the rows
			*/
			for (var i = 0; i < this.brands.length; i++) {
				var modelID:number = this.getController().getModel().new();

				this.getController().getModel().add( modelID, "name", this.brands[i] );

				/**
				*
				* Check if the current query is equal with one brand
				*/
				if ( typeof this.getController().getCaspardoQuery() !== "undefined" && this.brands[i] == this.getController().getCaspardoBrand() ) {
					this.getController().getModel().add( modelID, "checked", "checked" );
				} else {
					this.getController().getModel().add( modelID, "checked", "" );
				}
				
				filter_output += this.getController().process( modelID, "filter_brand_row" );
			}
		}

		if ( filter_output != "" ){
			return this.getController().processOne( "filter_brand_container", "content", filter_output );
		} else {
			return null;
		}
		
	}

    public assignEvents ():void {
        var brand_filter_input = jQuery( "#filter_brand_input" );
		var brand_filter_reset = jQuery( "#filter_brand_reset" );
		var brand_filter_refresh = jQuery( "#filter_brand_refresh_selection" );
		var brand_filter_checkbox = jQuery( "[data-ez-filter-brand]" );
		/**
		*
		* On input change event
		*/
		if ( brand_filter_input.length ) {
			brand_filter_input.on( "input", function (){
				this.change( brand_filter_input.val() );
			}.bind(this));
		}

		/**
		*
		* Click event on the reset button
		*/
		if ( brand_filter_reset.length ) {
			brand_filter_reset.on( "click", function (){
				this.reset( brand_filter_input );
				this.execute();
			}.bind(this));
		}

		/**
		* 
		* Click even on the checkboxes
		* push the current checked Checkbox into the array and builds the url
		*/
		if ( brand_filter_checkbox.length ) {
			brand_filter_checkbox.on( "click", event => {
				var clickedElement = jQuery(event.target);

				if ( clickedElement ) {
					this.newExecute( clickedElement );
				}
			});
		}

		/**
		*
		* Click event on the apply button
		* Get all checked checkboxes and push them into the array use to build the url
		*/
		// if ( brand_filter_refresh.length ) {
		// 	brand_filter_refresh.on( "click", function (){
		// 		this.execute();
		// 	}.bind(this));
		// }
    }

    public reset ( input:JQuery<HTMLElement> = null ):void{
        var brand_containers = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-brand-container]" );

		if ( brand_containers.length > 0 ) {

			brand_containers.each( function() {
				jQuery( this ).show();

				var checkbox = jQuery( this ).find( "[" + Globals.ATTRIBUTE_PREFIX + "filter-brand]" );
					checkbox.prop( "checked", false );
			});

		}

		if ( input != null && input.length ) {
			input.val( "" );
		}
	}
	
	/**
	* New Execute Brand Filter
	*/
	public newExecute( element:JQuery<HTMLElement> ):void {
		if (element.length) {
			this.selectedBrands = new Array();

			for (var i = 0; i < element.length; i++) {
				this.selectedBrands.push( element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "filter-brand") );
				ControllerSearch.SELECTED_CATEGORIES.push( element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "filter-brand") );
				ControllerSearch.SELECTED_BRANDS.push( element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "filter-brand") );
				ControllerSearch.SELECTED_HEADLINES.push( "Marke" );
			}

			this.getController().refreshAll();
			this.getController().onContentChange();
		}
	}

    /**
	*
	* Execute the brand filter
	*/
	public execute ():void{
		this.selectedBrands = new Array();

		var checkboxes = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-brand]" );
		if ( checkboxes.length > 0 ) {

			checkboxes.each( function( i:number ) {
				var checkbox = jQuery( checkboxes[i] );

				if ( checkbox.is( ":checked" ) ) {
					this.selectedBrands.push( checkbox.attr( ""+ Globals.ATTRIBUTE_PREFIX +"filter-brand" ) );
				}
			}.bind(this));

		}

		this.getController().refreshAll();
		this.getController().onContentChange();
    }
    
    	/**
	*
	* Change the current selection
	*/
	private change ( value:string ):void{
		var value = value.toLowerCase();
		var brand_containers = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-brand-container]" );

		if ( brand_containers.length > 0 ) {

			if ( value == "" ) {

				/**
				*
				* Reset the displayed options
				*/
				brand_containers.each( function() {
					jQuery( this ).show();
				});

			} else {

				/**
				*
				* Hide all not checked options
				* Hide all not matching options
				*/
				brand_containers.each( function() {
					var checkbox = jQuery( this ).find( "[" + Globals.ATTRIBUTE_PREFIX + "filter-brand]" );
					var checkbox_value = checkbox.attr( ""+ Globals.ATTRIBUTE_PREFIX +"filter-brand" ).toLowerCase();

					if ( !checkbox.is( ":checked" ) && checkbox_value.search( value ) == -1 ) {
						jQuery( this ).hide();
					}
				});

			}

		}
	}

	public buildURL ():string{
		var url:string = "";

		for (var i = 0; i < this.selectedBrands.length; i++) {
			url += "&brand=" + encodeURIComponent( this.selectedBrands[i] );
		}

		return url;
	}

}