import { Module } from "./../../classes/mvc/Module";
import { ControllerToTop } from "./ControllerToTop";
import { ModelToTop } from "./ModelToTop";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";

@ModuleConfig({
    moduleName: "ToTop",
    style: "to-top.scss"
})
export class ModuleToTop extends Module<ModelToTop> {

    public constructor ( configuration:Object ){
        super( "ToTop", configuration );
        
        this.addController( new ControllerToTop( this.getName(), this.getAccessID() ) );
    }

    public run (){
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

}