import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import { ModelExpandBox } from "./ModelExpandBox";
import jQuery = require( "jquery" );
import { Globals } from "../../classes/Globals";

export class ControllerExpandBox extends HTMLController<ModelExpandBox>{

    private static BUTTON_SHOW:JQuery<HTMLElement> = jQuery( "<span></span>" );
    private static BUTTON_HIDE:JQuery<HTMLElement> = jQuery( "<span></span>" );

    /**
     * 
     * Parameter
     */
    private maxHeight:number;
    private customHeight:string;

    /**
     * 
     * Statuses
     */
    private expanded: boolean;
    private height: number;

    /**
     * 
     * Elements
     */
   
    private buttonShow: JQuery<HTMLElement>;
    private buttonHide: JQuery<HTMLElement>;

    public constructor ( accessName:string, accessID:number, element:JQuery<HTMLElement> ){
        super( new ModelExpandBox(), accessName, accessID, element );

        this.maxHeight = 100;
        this.customHeight = "80px";

        this.expanded = false;

        this.height = 0;

        var maxHeight = this.getParam( "max-height" );
        if ( maxHeight != null && !isNaN( Number( maxHeight ) ) ){
            this.maxHeight = Number( maxHeight );
        }

        var customHeight = this.getParam( "custom-height" );
        if ( customHeight != null ) {
            this.customHeight = customHeight;
        }
    }

    public initGlobals ():void{
        var button_show = jQuery( this.getModule().getComponent( "button_show" ) );
        if ( button_show.length ){
            ControllerExpandBox.BUTTON_SHOW = button_show;
        }

        var button_hide = jQuery( this.getModule().getComponent( "button_hide" ) );
        if ( button_hide.length ){
            ControllerExpandBox.BUTTON_HIDE = button_hide;
        }
    }

    public run ():void{
        // TODO: austauschen der Funktion
        // this.getElement().on("ready", function() {
        //     this.height = this.getElement().height();

        //     if ( this.getElement().height() > this.maxHeight ) {

        //         var run = true;

        //         var buttonHide = this.getItem( "hide" );
        //         var buttonShow = this.getItem( "show" );

        //         if ( buttonHide.length && buttonShow.length ) {
        //             this.buttonHide = buttonHide;
        //             this.buttonShow = buttonShow;
        //         } else {
        //             this.buttonHide = ControllerExpandBox.BUTTON_HIDE.clone();
        //             this.buttonShow = ControllerExpandBox.BUTTON_SHOW.clone();

        //             run = this.setOutputElement( this.buttonHide, "hide" ) && this.setOutputElement( this.buttonShow, "show" );
        //         }

        //         if ( run ) {
        //             this.height = this.getElement().height();

        //             this.setHeight();
        //             this.displayButtons();

        //             this.buttonHide.bind( "click", function() {
        //                 this.expanded = false;
        //                 this.setHeight();
        //                 this.displayButtons();
        //             }.bind(this));

        //             this.buttonShow.bind( "click", function() {
        //                 this.expanded = true;
        //                 this.setHeight();
        //                 this.displayButtons();
        //             }.bind(this));
        //         } else {
        //             this.getModule().error( Globals.MODULE_LOADING_ERROR + " eines der beiden Output Elemente ( hide oder show ) nicht gefunden wurde" );
        //         }
        //     }
        // }.bind(this));

        this.getElement().ready( function (){
            this.height = this.getElement().height();

            if ( this.getElement().height() > this.maxHeight ){

                var run = true;

                var buttonHide = this.getItem( "hide" );
                var buttonShow = this.getItem( "show" );

                if ( buttonHide.length && buttonShow.length ){
                    this.buttonHide = buttonHide;
                    this.buttonShow = buttonShow;
                } else {
                    this.buttonHide = ControllerExpandBox.BUTTON_HIDE.clone();
                    this.buttonShow = ControllerExpandBox.BUTTON_SHOW.clone();

                    run = this.setOutputElement( this.buttonHide, "hide" ) && this.setOutputElement( this.buttonShow, "show" );
                }
                
                if ( run ) {
                    this.height = this.getElement().height();

                    this.setHeight();
                    this.displayButtons();
                
                    this.buttonHide.bind( "click", function (){
                        this.expanded = false;
                        this.setHeight();
                        this.displayButtons();
                    }.bind(this));
        
                    this.buttonShow.bind( "click", function (){
                        this.expanded = true;
                        this.setHeight();
                        this.displayButtons();
                    }.bind(this));
                } else {
                    this.getModule().error( Globals.MODULE_LOADING_ERROR + " eines der beien Output Elemente ( hide oder show ) nicht gefunden wurde" );
                }
            }
        }.bind(this));
    }

    private setHeight ():void{
        if ( this.expanded ){
            this.getElement().css( "max-height", this.height + "px" );
        } else {
            if ( isNaN( Number( this.customHeight ) ) ){
                this.getElement().css( "max-height", this.customHeight );
            } else {
                this.getElement().css( "max-height", this.customHeight + "px" );
            } 
        }
    }

    private displayButtons ():void{
        if ( this.expanded ){
            this.buttonHide.css( "display", "block" );
            this.buttonShow.css( "display", "none" );
        } else {
            this.buttonHide.css( "display", "none" );
            this.buttonShow.css( "display", "block" );
        }
    }

}