import { Module } from "./../../classes/mvc/Module";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";
import { ModelRatings } from "./ModelRatings";
import { ControllerRatings } from "./ControllerRatings";

@ModuleConfig({
    moduleName: "Ratings",
    style: "ratings.scss"
})
export class ModuleRatings extends Module<ModelRatings> {

    public constructor ( configuration:Object ){
        super( "Ratings", configuration );
        
        this.addController( new ControllerRatings( this.getName(), this.getAccessID() ) );
    }

    public run (){
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

}