import { Module } from "./classes/mvc/Module";
import { Pair } from "./libs/Pair";
import { Json } from "./libs/Json";
import { Strings } from "./libs/Strings";
import { Model } from "./classes/mvc/Model";
import { Windows } from "./libs/Windows";
import { KeyEvents } from "./libs/KeyEvents";
import { Helpers } from "./classes/handlebars/Helpers";
import { AjaxRequest } from "./libs/AjaxRequest";
import { Logging } from "./classes/logging/Logging";
import { Cookies } from "./libs/Cookies";
import { Globals, KeyCodes, ModuleExecutionStatus } from "./classes/Globals";
import { Times } from "./libs/Times";
import { Interfaces } from "./libs/Interfaces";
import { IOverlay } from "./classes/IOverlay";
import { Elements } from "./libs/Elements";
import { Collapse } from "./classes/Collapse";
import { Tools } from "./libs/Tools";

//laden der module-setup.json
var modulesSetup = require("./modules-setup.json");

// alle module laden


require("./resources/styles/all.scss");
require("./resources/js/jquery-ui/jquery-ui.css");

declare let ezentrum_variables: any;
declare let conf_file_path: any;
/**
 * @class The Main / Start class
 * @author Finn Reißmann & Pedro Fernandes de Sousa
 * @mermaid Ablauf der Initialisierung
 * sequenceDiagram
 *  participant main.ts
 *  participant Modules.ts
 *  main.ts ->> Modules.ts: checkrequirements()
 *  %Note over main.ts,Modules.ts: Überprüfung der Variablen:<br>*ezentrum_variables.sKontaktID<br>*ezentrum_variables.sKontaktKEY<br>*ezentrum_variables.sTICKCOUNT<br>+Instanziert die Klassen Variablen<br>+startet events: window resize, key, show print log<br>+setzt das aktuelle template<br>+läden + parsern die Konfigurations Datei<br>+setzt die aktuelle Sprache
 *  main.ts ->> Modules.ts: Falls True -> modules.init()
 *  Modules.ts -->> Modules.ts: includeModels()
 *  loop start der Module
 *      Modules.ts -->> Modules.ts: initModules()
 *  end
 *  Modules.ts -->> Modules.ts: initModule()
 *  Modules.ts -->> Modules.ts: afterInit()
 */
export class Modules {
    // ***Globale Variablen für alle Module***
    public static VERSION = modulesSetup.version;

    // ***Private Variablen für alle Module***
    public modulesConfiguration: Object;   // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> modules
    private globalConfigurations: Object;   // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> global
    private template: string; // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> z.B.: foundation
    private language: Pair<number, string>; // stellt die Sprache ein per ID
    private startTime: number; // Startzeit, wird beim Logging verwendet
    private endTime: number;    // Endzeit, wird beim Logging verwendet
    private flagAllowCookies: boolean;  // Zeigt an, ob Cookies erlaubt sind oder nicht
    public static modulesVariables: any;

    // ***Ezentrum Variablen***
    private sKontaktID: string; // Session Kontakt ID
    private sKontaktKEY: string;    // Session Kontakt Key
    private sTICKCOUNT: string; // Session Tick Count

    // ***Module***
    private modules: Array<Module<Model>>; // zentrales Array, welches die Module enthält

    public constructor() { } // Constructor (macht bisher noch nix)
    /**
     * @description <br><br>
     * * Überprüft, ob die folgenden JS Variablen gesetzt sind: ezentrum_variables.sKontaktID, ezentrum_variables.sKontaktKEY und ezentrum_variables.sTICKCOUNT
     * * Instanziert die Klassen Variablen <br>
     * * startet die folgenden events: window resize, key and show print log<br>
     * * setzt das aktuelle template <br>
     * * lädt und parsert die Konfigurations Datei<br>
     * * setzt die aktuelle Sprache
     * @return das Ergebnis von dem requirement check
     */
    public checkRequirements(): boolean {

        var configuration: any = null; // Das Object, welche die Daten der Konfigurationsdatei beinhaltet
        var confFilePath: string = ""; // Pfad zu der Konfigurationsdatei
        /**
         * Holen des Pfades der Konfigurationsdatei
         * hierbei wird überprüft, ob es sich noch um eine JSON-Konfiguration oder um eine YAML-Konfiguration handelt
         */
        if ( typeof conf_file_path !== 'undefined') // Wenn conf_file_patch gesetzt ist, wird es genutzt
        {
            confFilePath = conf_file_path;
            configuration = AjaxRequest.getJsObject(conf_file_path);
        } else  // Bei nein wird überprüft, ob eine Konfigurationsdatei vorhanden ist
        {
            // Lädt die Konfigurationsdatei automatisch
            var confFilePathDefault = modulesSetup.defaultConfPath; // Standard Pfad der Konfigurationsdatei

            confFilePath = confFilePathDefault + ".yaml";
            configuration = AjaxRequest.getYaml(confFilePath);

            if (configuration == null) // Andernfalls wird überprüft, ob eine .json datei im Ordner existiert
            {
                confFilePath = confFilePathDefault + ".json";
                configuration = AjaxRequest.getJson(confFilePath);
            }
        }

        // *** eZentrum Variablen ***
        if (typeof ezentrum_variables !== 'undefined') {
            this.sKontaktID = ezentrum_variables.sKontaktID; // setzen der sKontaktID
            this.sKontaktKEY = ezentrum_variables.sKontaktKEY; // setzen des KontaktKEY
            this.sTICKCOUNT = ezentrum_variables.sTICKCOUNT; // setzen des TICKCOUNT
        } else { // laden von den ezentrum_variables aus einem externen Link
            let url:string;
            if ( configuration.global.sessionVars || configuration.global.sessionVars !== '' ) {
                url = configuration.global.sessionVars;
            } else {
                url = '/filesync/ezmodule';
            }
            
            Modules.modulesVariables = AjaxRequest.getPlainText(url);

            if ( typeof Modules.modulesVariables === "string" ) {
                Modules.modulesVariables = this.parseTextToJson( Modules.modulesVariables );
            }

            this.sKontaktID = Modules.modulesVariables.sKontaktID;
            this.sKontaktKEY = Modules.modulesVariables.sKontaktKEY;
            this.sTICKCOUNT = Modules.modulesVariables.sTICKCOUNT;
        }
        if (Tools.isDefinedVar(this.sKontaktID, this.sKontaktKEY, this.sTICKCOUNT)) // Überprüft, ob die Variablen gefühlt sind
        { 
            
            this.template = "foundation";   // "foundation" ist das Template, welches in der Konfigurationsdatei die HTML Elemente beinhaltet
            this.language = new Pair(1, "de");  // setzt für die dementsprechende Zahl die Sprache ein
            this.startTime = new Date().getTime();  // holt sich die aktuelle Zeit in Millisekunden
            this.endTime = new Date().getTime();    // holt sich die aktuelle Zeit in Millisekunden
            
            this.flagAllowCookies = true;   // Dieser Flag zeigt an, ob Cookies erlaubt sind
            this.globalConfigurations = new Array(); // Array, welches die Konfigurationen beinhaltet
            this.modules = new Array(); // Array, welches die Module beinhaltet

            if (typeof ezentrum_variables !== 'undefined') //Überprüft, das aktuelle Template
            {
                if ( ezentrum_variables.template ) {
                    // TODO: Alternativ beiden optionen checken componentType und template
                    this.template = ezentrum_variables.template;
                }
            } else {
                // this.template = "foundation";
                this.template = Modules.modulesVariables.template;
            }

            KeyEvents.registerEvent([KeyCodes.CTRL, KeyCodes.CMD_LEFT, KeyCodes.L], this.printLogEntrys.bind(this)); // Beim drücken der Tasten(CTRL + CMD + L) wird ein komplettes Log im Entwicklungsfenster anezeigt

            // Laden der Konfigurationen über das configuration object
            if (configuration) {
                this.modulesConfiguration = Json.getSubobject(configuration, "modules"); // Laden aller Module, dessen Konfigurationen
                this.globalConfigurations = Json.getSubobject(configuration, "global"); // Laden aller globalen Konfigurationen
                if (this.modulesConfiguration == null) {
                    return false;
                } else {
                   // Falls die Konfiguration nicht geladen wurde oder leer ist, dann wird die Sprache manuel angelegt
                    var languageID: number;

                    if ( typeof ezentrum_variables !== 'undefined' ) {
                        if (ezentrum_variables.current_language) {
                            languageID = ezentrum_variables.current_language;
                        }
                    } else {
                        languageID = Modules.modulesVariables.current_language;
                    }
                    
                    var languageCode: string = this.getGlobalConfig("language_mapping." + languageID);
                    if (languageID && languageCode) // Überprüft, ob die languageID & languageCode gefüllt sind, falls ja wird es angelegt
                    {
                        this.language = new Pair(languageID, languageCode);
                    }
                    return true;
                }
            } else {
                this.error("Die Konfigurationsdatei konnte nicht geladen werden oder ist Fehlerhaft.", false);
                this.error("Pfad: " + confFilePath, false);
                return false;
            }
        } else {
            this.error("Die benötigten Variablen wurden nicht gefunden: sKontaktID, sKontaktKEY und sTICKCOUNT", false);
            return false;
        }
    }

    public preInit(): void {
        Windows.start(); // -> Beinhaltet alle Globale Window-Funktionen
        KeyEvents.start(); // -> Beinhaltet die Key-Events für z.B. das Logging im Entwicklerfenster
        Cookies.start(); // -> setzen & auslesen der Cookies
    }

    public init(): void {
        Helpers.init(); // -> start die Helper für Handlebars
        Globals.init(); // -> setzt alle Globale Variablen
        Elements.setFocusOnClick();
        if (this.getGlobalConfig("prevent_special_chars_in_password_fields") == true) {
            Elements.blockSpecialChars();
        }
        this.includeModules(); // -> Import und Initialisierung der Module
    }

    public afterInit(): void {
        Collapse.init(); // -> Startet das Event, welches für das Zusammenklappen der Elemente zuständig ist
    }

    // ***Modules***
    private async includeModules(): Promise<void> {
        var moduleNames: Array<string> = Object.keys(this.modulesConfiguration);
        for (let i = 0; i < moduleNames.length; i++) {
            var moduleName: string = moduleNames[i];
            var moduleConfiguration: Object = Json.getSubobject(this.modulesConfiguration, moduleName);
            if (moduleConfiguration != null) {
                try {
                    var widget = await import("./modules/" + moduleName + "/Module" + moduleName); // Import aller Module
                    var widgetExports: Array<string> = Object.keys(widget);
                    for (let j = 0; j < widgetExports.length; j++) {
                        var widgetExport: ObjectConstructor = widget[widgetExports[j]];
                        if (widgetExport.prototype instanceof Module) {
                            var module: Module<Model> = new widget[widgetExports[j]](moduleConfiguration);
                            if (module.getName() == "CookieDirective" && module.isActive()) // Überprüft, ob das CookieDirective Moduel aktiv ist
                            {
                                this.flagAllowCookies = false;
                            }
                            this.modules.push(module);
                        }
                    }
                } catch (e) {
                    this.error("Das folgende Modul existiert nicht oder konnte nicht aufgerufen werden: " + moduleName, false);
                    this.error("Pfad: src/modules/" + moduleName + "/Module" + moduleName + ".ts", false);
                }
            }
        }
        this.initModules(); // -> Initialisierung der Module
        for (let i = 0; i < this.modules.length; i++) {
            if (this.modules[i].isActive()) {
                for (let j = 0; j < this.modules[i].getControllers().length; j++) {
                    if (Interfaces.implements(this.modules[i].getControllers()[j], ["openPopup", "closePopup"])) {
                        try {
                            var overlayController: IOverlay = this.modules[i].getControllers()[j] as any;
                            Globals.OVERLAY.getElement().bind("click", overlayController.closePopup.bind(overlayController));
                        } catch (e) { }
                    }
                }
            }
        }
        this.endTime = new Date().getTime(); // -> setzt die Zeit, wo die Initialisierung beendet wurde
    }

    public initModules(): void {
        for (let i = 0; i < this.modules.length; i++) {
            this.initModule(this.modules[i]);
        }
        this.afterInit();
    }

    public initModule(module: Module<Model>, reInit: boolean = false): void {
        if (module.readyToRun() || reInit) {
            if (module.isActive()) {
                if (!(!this.flagAllowCookies && module.useCookies())) {
                    try {
                        if (reInit) {
                            module.runAllInitializedControllers();
                        } else {
                            module.runWithPreCheck();
                        }
                        if (!module.foundError()) {
                            module.callGlobalModuleEventFunction("loaded");
                            module.setExecutionStatus(ModuleExecutionStatus.SUCCESSFULL);
                        } else {
                            module.setExecutionStatus(ModuleExecutionStatus.CATCHED_ERROR);
                        }
                    } catch (error) {
                        module.setInitializationCompleted();
                        module.setExecutionStatus(ModuleExecutionStatus.NOT_CATCHED_ERROR);

                        this.error("Fehler im Modul: " + module.getName(), false);
                        this.error(error, false);
                    }
                } else {
                    module.setExecutionStatus(ModuleExecutionStatus.COOKIES);
                }
            }
        }
    }

    /**
     * @description Show the logging by pressing the following keys: Ctrl + Cmd + L
     */
    private printLogEntrys(): void {
        this.buildModulesLog();
        console.clear();
        console.log("%c*************************************", "color: gray;");
        console.log("%c*******   eZentrum Modules   ********", "color: gray;");
        console.log("%c*******   Version: " + Modules.VERSION + "    ********", "color: gray;");
        console.log("%c*************************************", "color: gray;");
        Logging.MODULES.print();
        this.log("Alle Module wurden in " + Times.getTimeString(this.endTime - this.startTime, "s") + " ausgeführt");
        Logging.GENERAL_LOG.print();
        Logging.ERROR_LOG.print();
    }

    private buildModulesLog() {
        Logging.MODULES.clear();
        for (let i = 0; i < this.modules.length; i++) {
            var text: string = "%c" + this.modules[i].getName() + ": %c" + (this.modules[i].isActive() ? "" : "in") + "aktiv";
            var style: Array<string> = new Array(
                "color: grey;",
                "color:" + (this.modules[i].isActive() ? "green" : "red") + ";"
            );
            Logging.MODULES.addWithMultipleStyles(text, style);
        }
        Logging.MODULES.add("", "", false);
        for (let i = 0; i < this.modules.length; i++) {
            var module: Module<Model> = this.modules[i];
            if (module.isInitialized() && module.isActive() && !(!this.flagAllowCookies && module.useCookies())) { //Überprüft, ob das Modul activ ist + cookies benutzt
                var moduleExecutionStatus: any = module.getExecutionStatus();
                if (moduleExecutionStatus != null) {
                    var color = new Array(
                        "color:grey;"
                    );
                    switch (moduleExecutionStatus) {
                        case ModuleExecutionStatus.SUCCESSFULL:
                            color.push("color:green;");
                            break;
                        case ModuleExecutionStatus.NOT_CATCHED_ERROR:
                            color.push("color:red;");
                            break;
                        default:
                            color.push("color:orange;");
                    }
                    moduleExecutionStatus = moduleExecutionStatus.replace("[module_name]", module.getName());
                    Logging.MODULES.addWithMultipleStyles(moduleExecutionStatus, color);
                }
            }
        }
    }

    // Globale Funktionen
    public findModule(name: string): Module<Model> // -> Zeigt ein Objekt mit allen Infos des Modules an
    {
        var module: Module<Model> = null;
        for (let i = 0; i < this.modules.length; i++) {
            if (this.modules[i].getName() == name) {
                module = this.modules[i];
                break;
            }
        }
        return module;
    }

    public callGlobalEventFunction(eventName: string): void {
        Windows.callGlobalFunction("on" + Strings.beginWithUppercase(eventName), false);
    }

    public callGlobalEventFunctionWithArgs(eventName: string, ...args: any[]): void {
        Windows.callGlobalFunction("on" + Strings.beginWithUppercase(eventName), false, args);
    }

    public getModule(name: string, id: number): Module<Model> // -> Zeigt ein Objekt mit den Infos des Modules per Name, ID
    {
        if (Module.checkControllerAccess(name, id)) {
            return this.findModule(name);
        } else {
            return null;
        }
    }

    public checkModuleImplementation(name: string, properties: Array<string>): boolean {
        var module: Module<Model> = this.findModule(name);
        if (module != null) {
            return Interfaces.implements(module, properties);
        } else {
            return false
        }
    }

    public moduleInfo(moduleName: string) // -> Zeigt die Anzahl der Instanzen des Moduls
    {
        var mod: Module<Model> = this.findModule(moduleName);
        if (mod != null) {
            var modExecutionStatus: any = mod.getExecutionStatus();
            //Überprüft, ob das Modul erfolgreich gestartet wurde oder ob es ein Error hat und gibt ihn aus
            var showExecutionInformation = modExecutionStatus == ModuleExecutionStatus.SUCCESSFULL || modExecutionStatus == ModuleExecutionStatus.CATCHED_ERROR;
            if (showExecutionInformation) {
                mod.printInfo();
            }
        } else {
            console.log("Dieses Modul wurde nicht gefunden oder nicht geladen");
        }
    }

    public moduleInit(moduleName: string, reInit: boolean = false) // -> Initialisiert das Modul per Funktion
    {
        var mod: Module<Model> = this.findModule(moduleName);
        if (mod != null) {
            this.initModule(mod, reInit);
            console.log("OK");
        } else {
            console.log("Dieses Modul wurde nicht gefunden oder nicht geladen");
        }
    }

    /**
     * @param message the log entry
     * @param outputStyle the outputStyle (css) displayed in the console
     */
    public log(message: string, outputStyle: string = ""): void {
        Logging.GENERAL_LOG.add(message, outputStyle, false);
    }

    /**
     * @param message the log entry
     * @param useStrackTrace if true print the whole stack trace
     * @param outputStyle the outputStyle (css) displayed in the console
     */
    public error(message: string, useStrackTrace: boolean = true, outputStyle: string = ""): void {
        Logging.ERROR_LOG.add(message, outputStyle, useStrackTrace);
    }

    // Binding der Events
    public bindEvent(modulename: string, eventname: string, callback: (...args: any[]) => void): void {
        this.modules.forEach(module => {
            if (module.getName() == modulename) {
                module.bindEvent(eventname, callback);
            }
        });
    }

    // Ruft eine Methode des Modules auf
    public callMethod(modulename: string, methodname: string, ...args: any[]): any {
        var result: any = null;
        for (let i = 0; i < this.modules.length; i++) {
            if (this.modules[i].getName() == modulename) {
                result = this.modules[i].callMethod(methodname, args);

                break;
            }
        }
        return result;
    }

    public getGlobalConfig(key: string): any {
        return Json.getSubobject(this.globalConfigurations, key);
    }

    public importModuleStyle(moduleName: string, fileName: string) {
        try {
            require("./modules/" + moduleName + "/" + fileName);
        } catch (e) {
            this.error("Die styles für das folgende Modul konnten nicht geladen werden.");
        }
    }

    // SETTER
    public allowCookies(): void {
        this.flagAllowCookies = true;
        this.initModules();
    }

    // GETTER
    public getLanguageCode(): string {
        return this.language.getValue();
    }

    public getLanguageID(): number {
        return this.language.getKey();
    }

    public getTemplate(): string {
        return this.template;
    }

    public getKontaktKey(): string {
        return this.sKontaktKEY;
    }

    public getKontaktID(): string {
        return this.sKontaktID;
    }

    public getTickcount(): string {
        return this.sTICKCOUNT;
    }

    public parseTextToJson( text:string ): Object {
        let result: Object = null;

        try {
            result = JSON.parse(text);
        } catch (error) {
            console.error(error);
        }

        return result;
    }
}