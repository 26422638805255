import { ControllerSelectionFilter } from "./ControllerSelectionFilter";
import { ModelSelectionFilter } from "./ModelSelectionFilter";
import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";

export class ModuleSelectionFilter extends HTMLModule<ModelSelectionFilter>{
        
    public constructor ( configuration:Object ){
        super( "SelectionFilter", configuration );

        this.initAll( ControllerSelectionFilter.prototype );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
    }

}