import { Filter } from "./Filter";
import { Json } from "../../../libs/Json";
import { findIcon } from "../../../libs/Icons";
import { ControllerSearch } from "../ControllerSearch";
import jQuery = require( "jquery" );
import { Globals } from "../../../classes/Globals";

export class NodeCategoryFilter extends Filter {

	private static HEADLINE:string = "";

	private nodeItems:Array<string>;
	private selectedCategories:Array<string>;
	private categoryLabel: string;
	public static blacklistedCategories:Array<string>;

	public constructor ( controller:ControllerSearch ){
		super( controller, "nav" );

		this.setHeadline( NodeCategoryFilter.HEADLINE );
		this.setIcon( findIcon( "filter_icon" ) );
		
		this.nodeItems = new Array();
		this.selectedCategories = new Array();
	}

	public initGlobals ():void{
		this.getController().getModule().addView("filter_category_node_head_container", this.getController().getModule().getComponent( "filter.category.node_head_container" ) );
		this.getController().getModule().addView("filter_category_node_head_row", this.getController().getModule().getComponent( "filter.category.node_head_row" ) );

		NodeCategoryFilter.HEADLINE = this.getController().getModule().getLabel( "filter_category_headline" );
		NodeCategoryFilter.blacklistedCategories = this.getController().getModule().getConfig("category_blacklist");
		this.categoryLabel = this.getController().getModule().getLabel( "selected_headline" );
	}

    public create ( value:Object ):string {
		var filter_output = "";
		var filter_nodes_content = "";
		var modelID: number;
		if ( value != null ) {
            this.nodeItems = Json.convertObjectToArray( value );

			
			/**
			 * 
			 * Initialisert das Array der Blacklist, falls es nicht vorher in der Konfiguration getan wurde.
			 */
			if (NodeCategoryFilter.blacklistedCategories == null) {
				NodeCategoryFilter.blacklistedCategories = new Array();
			}

			/**
			* 
			* Fügt die vorhandenen Selektierten Kategorien  in die Blacklist ein
			*/
			if (ControllerSearch.SELECTED_CATEGORIES.length > 0) {
				for (var i = 0; i < ControllerSearch.SELECTED_CATEGORIES.length; i++) {
					NodeCategoryFilter.blacklistedCategories.push(ControllerSearch.SELECTED_CATEGORIES[i]);
				}
			}

			/**
			* 
			* Überprüft, ob die Nodes eines der Kategorien beinhaltet, welches in der Blacklist ist
			* Wenn Ja => wird es in den Nodes entfernt
			*/
			if ( NodeCategoryFilter.blacklistedCategories ) { // Überprüfung, ob das Array nicht Null oder nicht leer ist
				for (var j = 0; j < NodeCategoryFilter.blacklistedCategories.length; j++) {
					var currentBlacklist = NodeCategoryFilter.blacklistedCategories[j]; // der aktuelle String aus der Blacklist

					for (var x = 0; x < this.nodeItems.length; x++) {
						var currentNodeName = Json.getSubobject( this.nodeItems[x], "item.name" ); // aktuelle "Überschrift" der Kategorie
						var nodeChilds = Json.getSubobject( this.nodeItems[x], "childs.node" ); // Array ODER Object, welches die "Untergruppen" der Kategorie beinhalten
						var nodeChildName; // aktueller Name der "Untergruppe", welches später gefüllt wird.

						/**
						* 
						* Überprüfung ob "Überschrift" oder "Untergruppe" in der Blocklist vorhanden sind
						* 1. Falls die "Überschrift" mit der Blacklist übereinstimmt, dann wird die ganze "Gruppe" entfernt
						* Ansonsten wird weiter in den "Untergruppen" geschaut und dementsprechend entfernt
						* 
						* Wenn eine "Überschrift" oder "Untergruppe" entfernt wird,
						* dann wird auch der Index um 1 verringert.
						*/
						// if ( currentNodeName == currentBlacklist ) {
						// 	this.nodeItems.splice(x, 1);
						// 	x-=1;
						// }

						if ( nodeChilds !== null ) {
							if ( nodeChilds.length ) { // Überprüfung der "Untergruppen"-Objekts, ob es ein Array oder ein Objekt(nur bei Obergruppen, die nur 1 Child haben)
								for (var y = 0; y < nodeChilds.length; y++) {
									nodeChildName = nodeChilds[y].name;
	
									if ( nodeChildName == currentBlacklist ) {
										nodeChilds.splice(y, 1);
										y-=1;
									}
								}
							} else {
								nodeChildName = nodeChilds.name;

								if ( nodeChildName == currentBlacklist ) {
									nodeChilds = [];
								}
							}

							if ( nodeChilds.length == 0 ) {
								this.nodeItems.splice(x, 1);
								x-=1;
							}
						}
					}
				}
			}

			/**
			*
			* Process the rows
			*/
			for (var i = 0; i < this.nodeItems.length; i++) {
				modelID = this.getController().getModel().new();

				var currentNodeHead = Json.getSubobject( this.nodeItems[i], "item.name" );
				var currentNodeChilds = Json.getSubobject( this.nodeItems[i], "childs.node" );
				var currentChildName;

				if ( currentNodeChilds !== null ) {
					if ( currentNodeChilds.length > 0 ) {
						for (var j = 0; j < currentNodeChilds.length; j++) {
							currentChildName = Json.getSubobject( currentNodeChilds[j], "name" );
	
							if (!ControllerSearch.SELECTED_CATEGORIES.includes(currentChildName)) {
								filter_nodes_content += this.processChilds( currentChildName, currentNodeHead, modelID );
							}
	
							modelID = this.getController().getModel().new();
						}
					} else {
						currentChildName = currentNodeChilds.name;
	
						if (!ControllerSearch.SELECTED_CATEGORIES.includes(currentChildName)) {
							filter_nodes_content += this.processChilds( currentChildName, currentNodeHead, modelID );
						}
	
						modelID = this.getController().getModel().new();
					}
				}

				if ( filter_nodes_content != "" ) {
					var id = "node"+i;

					this.getController().getModel().add( modelID, "name", currentNodeHead );
					this.getController().getModel().add( modelID, "id", id );
					this.getController().getModel().add( modelID, "content", filter_nodes_content );

					filter_nodes_content = "";
					filter_output += this.getController().process( modelID, "filter_category_node_head_container" );
				}
			}
		}

		if ( filter_output != "" ){
			return filter_output;
		} else {
			return null;
		}
		
	}

	private processChilds( currentChildName:string, currentNodeHead:string, modelID:number ): string {
		var output = "";

		if ( currentChildName !== "" ) {
			this.getController().getModel().add( modelID, "node", currentChildName);
			this.getController().getModel().add( modelID, "nodeHeadline", currentNodeHead );
			this.getController().getModel().add( modelID, "extraID", modelID );

			output += this.getController().process( modelID, "filter_category_node_head_row" );
		}

		return output;
	}

    public assignEvents ():void {
		var category_node_checkbox = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-nodecategory]" );
		var category_node_container = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-nodecategory-container]" );
		var category_node_headlines = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-nodeHeadline]" );

		if ( category_node_checkbox.length ) {
			category_node_checkbox.on( "click", event => {
				var clickedElement = jQuery(event.target);
				if (clickedElement) {
					this.newExecute( clickedElement );
				}
			});
		}

		if ( category_node_container.length ) {
			category_node_container.each(function () {
				if ( this.children.length <= 0 ) {
					var currentNodeID = this.id;
					var currentNodeContainer = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "node-id='"+ currentNodeID +"']" );

					if ( currentNodeContainer ) {
						currentNodeContainer.remove();
					}
				}
			});
		}

		if ( category_node_headlines ) {
			category_node_headlines.on( "click", event => {
				var clickedElement = jQuery( event.target );
				if ( clickedElement ) {
					this.nodeHeadline( clickedElement );
				}
			});
		}
	}

    public reset ( input:JQuery<HTMLElement> = null ):void{
        var category_containers = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-category-container]" );

		if ( category_containers.length > 0 ) {

			category_containers.each( function() {
				jQuery( this ).show();

				var checkbox = jQuery( this ).find( "[" + Globals.ATTRIBUTE_PREFIX + "filter-category]" );
					checkbox.prop( "checked", false );
			});

		}

		if ( input != null && input.length ) {
			input.val( "" );
		}
	}

	/**
	* Execute For Category Headlines
	*/
	public nodeHeadline( element:JQuery<HTMLElement> ): void {
		if (element.length) {
			this.selectedCategories = new Array();
			ControllerSearch.SELECTED_CATEGORIES = new Array();

			for (var i = 0; i < element.length; i++) {
				var headline = element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "filter-nodeHeadline" );

				if ( !this.selectedCategories.includes( headline ) ) {
					this.selectedCategories.push( headline );
					ControllerSearch.SELECTED_HEADLINES.push( this.categoryLabel );
				}

				if ( !ControllerSearch.SELECTED_CATEGORIES.includes( headline ) ) {
					ControllerSearch.SELECTED_CATEGORIES.push( headline );
					ControllerSearch.SELECTED_HEADLINES.push( this.categoryLabel );
				}
			}

			this.getController().refreshAll();
			this.getController().onContentChange();
		}
	}

	/**
	* 
	* New Execute Category Filter
	*/
	public newExecute( element:JQuery<HTMLElement> ): void {
		if (element.length) {
			this.selectedCategories = new Array();
			ControllerSearch.SELECTED_CATEGORIES = new Array();

			for (var i = 0; i < element.length; i++) {
				var headline = element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "node-headline" );

				if ( !this.selectedCategories.includes( headline ) ) {
					this.selectedCategories.push( headline );
					ControllerSearch.SELECTED_HEADLINES.push( this.categoryLabel );
				}

				if ( !ControllerSearch.SELECTED_CATEGORIES.includes( headline ) ) {
					ControllerSearch.SELECTED_CATEGORIES.push( headline );
					ControllerSearch.SELECTED_HEADLINES.push( this.categoryLabel );
				}
				
				this.selectedCategories.push( element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "filter-nodeCategory" ) );
				
				ControllerSearch.SELECTED_CATEGORIES.push( element.attr( ""+ Globals.ATTRIBUTE_PREFIX + "filter-nodeCategory" ) );
			}

			this.getController().refreshAll();
			this.getController().onContentChange();
		}
	}

    /**
	*
	* Execute the category filter
	*/
	public execute ():void{
		this.selectedCategories = new Array();

		var checkboxes = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-category]" );
		if ( checkboxes.length > 0 ) {

			checkboxes.each( function( i:number ) {
				var checkbox = jQuery( checkboxes[i] );

				if ( checkbox.is( ":checked" ) ) {
					this.selectedCategories.push( checkbox.attr( ""+ Globals.ATTRIBUTE_PREFIX +"filter-category" ) );
				}
			}.bind(this));

		}

		this.getController().refreshAll();
		this.getController().onContentChange();
    }
    
	/**
	*
	* Change the current selection
	*/
	private change ( value:string ):void{
		var value = value.toLowerCase();
		var category_containers = jQuery( "[" + Globals.ATTRIBUTE_PREFIX + "filter-category-container]" );

		if ( category_containers.length > 0 ) {

			if ( value == "" ) {

				/**
				*
				* Reset the displayed options
				*/
				category_containers.each( function() {
					jQuery( this ).show();
				});

			} else {

				/**
				*
				* Hide all not checked options
				* Hide all not matching options
				*/
				category_containers.each( function() {
					var checkbox = jQuery( this ).find( "[" + Globals.ATTRIBUTE_PREFIX + "filter-category]" );
					var checkbox_value = checkbox.attr( ""+ Globals.ATTRIBUTE_PREFIX +"filter-category" ).toLowerCase();

					if ( !checkbox.is( ":checked" ) && checkbox_value.search( value ) == -1 ) {
						jQuery( this ).hide();
					}
				});

			}

		}
	}

	public buildURL ():string{
		var url:string = "";

		for (var i = 0; i < this.selectedCategories.length; i++) {
			url += "&category1=" + encodeURIComponent( this.selectedCategories[i] );
		}

		return url;
	}

}