import { Option } from "./Option";
import { Globals } from "../../classes/Globals";
import { ControllerSelectionFilter } from "./ControllerSelectionFilter";

export class Category {

    public static OPTION_SELECTOR: string = "data-ez-option-input";

    private readonly controller: ControllerSelectionFilter;

    private readonly key: string;
    private readonly name: string;

    private readonly options: Array<Option>;

    private buttonExecute: JQuery<HTMLInputElement>;
    private buttonReset: JQuery<HTMLInputElement>;

    public constructor(key: string, name: string, options: Array<Option>, controller: ControllerSelectionFilter) {
        this.key = key;
        this.name = name;

        this.options = options;

        this.controller = controller;
    }

    public build(): void {
        var html = "";

        /**
         * 
         * Elements for the user interaction
         */
        var buttonExecute: JQuery<HTMLInputElement> = jQuery(this.controller.getModule().getComponent("category_content.button_execute", false));
        var buttonReset: JQuery<HTMLInputElement> = jQuery(this.controller.getModule().getComponent("category_content.button_reset", false));

        if (buttonExecute.length && buttonReset.length) {
            var content = "";

            for (let j = 0; j < this.options.length; j++) {
                content += this.options[j].build(this.controller, this.key);
            }

            var modelID = this.controller.getModel().new();

            this.controller.getModel().add(modelID, "key", this.key);
            this.controller.getModel().add(modelID, "name", this.getName());
            this.controller.getModel().add(modelID, "icon", this.controller.getModule().getComponent( "icons." + this.getKey() ) );

            this.controller.getModel().add(modelID, "button_execute", "<div " + Globals.DUMMY_ATTRIBUTE_KEY + "='button_execute'></div>");
            this.controller.getModel().add(modelID, "button_reset", "<div " + Globals.DUMMY_ATTRIBUTE_KEY + "='button_reset'></div>");

            this.controller.getModel().add(modelID, "content", content);

            html += this.controller.process(modelID, "category_container");
        }

        var outputElement = this.controller.findOutputElement( this.key );
        if (outputElement != null) {
            outputElement.append(html);

            outputElement.find("[" + Globals.DUMMY_ATTRIBUTE_KEY + "='button_execute']").replaceWith(buttonExecute);
            outputElement.find("[" + Globals.DUMMY_ATTRIBUTE_KEY + "='button_reset']").replaceWith(buttonReset);

            this.buttonExecute = buttonExecute;
            this.buttonReset = buttonReset;
        }
    }

    public assignEvents(): void {
        this.buttonExecute.bind("click", this.execute.bind(this));
        this.buttonReset.bind("click", this.reset.bind(this));
    }

    public execute(): void {
        this.controller.processAllCategories();
    }

    public getSelectedIDs ():Array<string>{
        var ids: Array<string> = new Array();

        var selectedElements = jQuery("[" + Category.OPTION_SELECTOR + "^='" + this.key + "']:checked");
        
        /**
         * 
         * Wenn keine Checkbox angehakt wurde, werden alle optionen aus dieser Kategorie berücksichtigt
         */
        if ( selectedElements.length <= 0 ){
            selectedElements = jQuery("[" + Category.OPTION_SELECTOR + "^='" + this.key + "']");
        }

        for (let i = 0; i < selectedElements.length; i++) {
            var optionKey = jQuery(selectedElements[i]).attr(Category.OPTION_SELECTOR);
                optionKey = optionKey.replace(this.key + "_", "");

            if (typeof optionKey !== "undefined") {
                ids.push(optionKey);
            }
        }

        return ids;
    }

    public reset(): void {
        var selectedElements = jQuery("[" + Category.OPTION_SELECTOR + "^='" + this.key + "']:checked");
        for (let i = 0; i < selectedElements.length; i++) {
            selectedElements.prop( "checked", false );
        }
        this.controller.showAll();
    }


    public getKey(): string { return this.key; }
    public getName(): string { return this.name; }
    public getOptions(): Array<Option> { return this.options; }
}