import { ControllerFilter } from "./ControllerFilter";
import { ModelFilter } from "./ModelFilter";
import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";

export class ModuleMenu extends HTMLModule<ModelFilter>{

    public constructor ( configuration:Object ){
        super( "Filter", configuration );

        this.initAll( ControllerFilter.prototype );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
    }

}