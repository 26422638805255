import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import { ControllerNumberInput } from "./ControllerNumberInput";
import { ModelNumberInput } from "./ModelNumberInput";

export class ModuleNumberInput extends HTMLModule<ModelNumberInput> {

    public constructor ( configuration:Object ){
        super( "NumberInput", configuration );

        this.initAll( ControllerNumberInput.prototype );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

}