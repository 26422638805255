import { Pair } from "../../libs/Pair";
import { Globals } from "../../classes/Globals";
import { Strings } from "../../libs/Strings";
import { Json } from "../../libs/Json";

export class FilterItem {

    /**
     * 
     * First: the category key
     * Second: the value
     */
    private combination: any;
    private options: Array<Pair<string, string>>;

    private selectable:boolean;

    public constructor ( combination: any, options: Array<Pair<string, string>>, selectable:boolean = true ){
        this.combination = combination;
        this.options = options;
        this.selectable = selectable;
    }

    public static initWithObject ( object:any, formatPositions:Array<string> ): FilterItem{
        var options = Json.getSubobject( object, "options" );
        var categoriesJSON = Json.getSubobject( object, "categories" );
        var selectable = Json.getSubobject( object, "selectable" ) == true;

        var combination:any = {};

        for (let i = 0; i < formatPositions.length; i++) {
            var value = Json.getSubobject( categoriesJSON, formatPositions[i] )
            if ( value != null ){
                combination[ formatPositions[i] ] = value;
            }
        }

        return new FilterItem( combination, options, selectable );
    }

    public static initWithArticleNumber(splittedParts: Array<string>, element: JQuery<HTMLElement>): FilterItem {
        var result:FilterItem = new FilterItem( {}, new Array() );

        result.combination = {};
        result.options = new Array();

        for (let i = 0; i < splittedParts.length; i++) {
            result.combination[ String( i ) ] = splittedParts[i];
        }

        var optionsString = element.attr(Globals.ATTRIBUTE_PREFIX + "options");
        if (typeof optionsString !== "undefined") {
            var options: Array<Pair<string, any>> = Strings.toArrayPair(optionsString);

            for (let i = 0; i < options.length; i++) {
                var pair: Pair<string, any> = options[i];

                var optionKey:string = String(pair.getKey());
                var optionValue:string = String(pair.getValue());

                result.options.push( new Pair( optionKey, optionValue ) );
            }
        }

        var formatReplacements = element.attr(Globals.ATTRIBUTE_PREFIX + "format-replace");
        if (typeof formatReplacements !== "undefined") {
            var replacements: Array<Pair<string, any>> = Strings.toArrayPair(formatReplacements);
            for (let i = 0; i < replacements.length; i++) {
                var pair: Pair<string, any> = replacements[i];

                var replacementIndex = Number(pair.getKey());
                var replacementValue = String(pair.getValue());

                result.combination[replacementIndex] = jQuery.trim( replacementValue );
            }
        }

        return result;
    }

    public getOptions ():Array<Pair<string, string>>{
        return this.options;
    }

    public getCombinationValue ( key:string ):any{
        return Json.getSubobject( this.combination, key );
    }

    public isSelectable ():boolean {
        return this.selectable;
    }
}