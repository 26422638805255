import { Module } from "./../../classes/mvc/Module";
import { ControllerCookieDirective } from "./ControllerCookieDirective";
import { ModelCookieDirective } from "./ModelCookieDirective";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";

@ModuleConfig({
    moduleName: "CookieDirective",
    style: "cookie-directive.scss"
})
export class ModuleCookieDirective extends Module<ModelCookieDirective> {

    public constructor ( configuration:Object ){
        super( "CookieDirective", configuration );

        this.addController( new ControllerCookieDirective( this.getName(), this.getAccessID() ) );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
       
    }

}