import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import { ControllerNumberInput } from "./../NumberInput/ControllerNumberInput";
import { ControllerSearch } from "./ControllerSearch";
import { ModelSearch } from "./ModelSearch";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";

@ModuleConfig({
    moduleName: "Search",
    style: "search.scss"
})
export class ModuleSearch extends HTMLModule<ModelSearch> {
    
    public constructor ( configuration:Object ){
        super( "Search", configuration );
        this.registerDependentModule( "NumberInput", ControllerNumberInput.prototype );
        this.registerEvent( "tab_change" );
        this.registerEvent( "content_change" );
        this.initOne( ControllerSearch.prototype );
    }

    public run ():void{
        this.runAllControllers();
    }

    public onControllersInitialized ():void {
    }

}