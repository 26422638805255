import { ControllerSearch } from "../ControllerSearch";
import { IGlobals } from "./../../../classes/IGlobals";

export abstract class Filter implements IGlobals{

    private controller:ControllerSearch;

    private name:string;
    private headline:string;
    private icon:string;

    private active:boolean;

    public flagGlobalsInitialized:boolean;

    public constructor ( controller:ControllerSearch, name:string){
        this.controller = controller;

        this.name = name;
        this.headline = "";
        this.icon = "";

        this.active = false;

        this.runWithPreCheck();
    }

    public abstract create ( value:Object ):string;
    public abstract execute ():void;
    public abstract reset ():void;
    public abstract assignEvents ():void;
    public abstract initGlobals ():void;
    public abstract buildURL ():string;

    public runWithPreCheck ():void{
        if ( !this.flagGlobalsInitialized ){
            this.initGlobals();
            this.flagGlobalsInitialized = true;
        }
    }

    public getName ():string{
        return this.name;
    }

    public getHeadline ():string{
        return this.headline;
    }

    public getIcon ():string{
        return this.icon;
    }

    public setHeadline (headline:string):void{
        this.headline = headline;
    }

    public setIcon (icon:string):void{
        this.icon = icon;
    }

    public isActive ():boolean{
        return this.active;
    }

    public activate ():void{
        this.active = true;
    }

    public deactivate (): void {
        this.active = false;
    }

    public getController ():ControllerSearch{
        return this.controller;
    }
}