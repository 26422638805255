import { HTMLModule } from "./../../classes/mvc/HTML/HTMLModule";
import { ControllerCallNumber } from "./ControllerCallNumber";
import { UserAgents } from "./../../libs/UserAgents";
import { ModelCallNumber } from "./ModelCallNumber";

export class ModuleCallNumber extends HTMLModule<ModelCallNumber> {

    public constructor ( configuration:object ){
        super( "CallNumber", configuration );

        if ( UserAgents.isMobile() ){
            this.initAll( ControllerCallNumber.prototype );
        } 
    }

    public run ():void{
        if ( UserAgents.isMobile() ){
            this.runAllControllers();
        }
    }

    public onControllersInitialized ():void {
       
    }

}